import React, { Component, lazy, Suspense, useEffect, useState } from 'react';
import { Redirect, Switch, Route, withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import * as actions from './store/actions';
import WithErrorHandling from './components_v3/utils/ErrorHandling/WithErrorHandling';
import PrivateRoute from './components_v3/utils/PrivateRoute';
import PublicRoute from './components_v3/utils/PublicRoute';
import PublicCmsNewPageRoute from './components_v3/utils/PublicCmsNewPageRoute';
import packageJson from "/package.json"
import { selectTrackingDataLayerBase } from '@model/TrackingClass';

const PublicHomepage = lazy(() => import('./pages_v3/PublicHomepage'));
const LoggedHomepage = lazy(() => import('./pages_v3/Homepage'));
const Wishlist = lazy(() => import('./pages_v3/Wishlist'));
const CourseCatalogue = lazy(() => import('./pages_v3/CourseCatalogue'));
const LoginPage = lazy(() => import('./pages_v3/LoginPage'));
const RegisterPage = lazy(() => import('./pages_v3/RegisterPage'));
const SuccessPage = lazy(() => import('./pages_v3/SuccessPage'));
const ForgotPassword = lazy(() => import('./pages_v3/ForgotPassword'));
const RecoveryPasswordSuccessPage = lazy(() => import('./pages_v3/ForgotPasswordSuccess'));
const ResetPassword = lazy(() => import('./pages_v3/ResetPassword'));
const ResetPasswordSuccessPage = lazy(() => import('./pages_v3/ResetPasswordSuccess'));
const MyLeonardoPage = lazy(() => import('./pages_v3/MyLeonardoPage'));
const ProfileSettings = lazy(() => import('./pages_v3/ProfileSettings'));
const PlatformSettings = lazy(() => import('./pages_v3/ProfileSettings/PlatformSettings'));
const ContentPersonalization = lazy(() =>
  import('./pages_v3/ProfileSettings/ContentPersonalization')
);
const BillingAddressSettings = lazy(() => import('./pages_v3/ProfileSettings/BillingAddress'));
const PaymentMethodsSettings = lazy(() => import('./pages_v3/ProfileSettings/PaymentMethods'));
const ProfileInfoSettings = lazy(() => import('./pages_v3/ProfileInfoSettings'));
const PublicCatalogue = lazy(() => import('./pages_v3/PublicCatalogue'));
const EditorialPage = lazy(() => import('./pages_v3/EditorialPage'));
const EditorialPlanPage = lazy(() => import('./pages_v3/EditorialPlanPage'));
const LtPage = lazy(() => import('./pages_v3/LtPage'));
const PromoLandingPage = lazy(() => import('./pages_v3/PromoLandingPage'));
import ReactGA from 'react-ga';
import { getParentCourse, getParameterByName, isMobile } from './utility/Api';
import { COOKIE, MODAL_TYPES, PREVIEW_LABEL_COOKIE_KEYS, queryParams } from './utility/const';
const Personalization = lazy(() => import('./components_v3/Personalization/Personalization'));
import { getBrowserAndVersion, isMac, setDocumentTitle, setFavicon } from '@utility/browserUtility';
import {
  initializeGA,
  addPageViewGA,
  initializeFacebookPixel,
  initializeTealium,
  triggerPageView,
} from '@utility/analytics-utils';
const MyTeamPage = lazy(() => import('./pages_v3/MyTeam'));
const TeamMemberPage = lazy(() => import('./pages_v3/TeamMemberPage'));
const ProgressPage = lazy(() => import('./pages_v3/ProgressPage'));
const MyCertificates = lazy(() => import('./pages_v3/MyCertificates'));
const LegalPage = lazy(() => import('./pages_v3/LegalPage'));
const NotFound = lazy(() => import('./pages_v3/NotFound'));
const EmptyPage = lazy(() => import('./pages_v3/EmptyPage'));

const ManageCookies = lazy(() => import('./pages_v3/ManageCookiesPage'));
const ContactUs = lazy(() => import('./pages_v3/ContactUs'));
const BrandPage = lazy(() => import('./pages_v3/BrandPage'));
const Search = lazy(() => import('@components/Search/Search'));
const MyChannels = lazy(() => import('./pages_v3/MyChannels'));
const ProfileTodo = lazy(() => import('./pages_v3/ProfileTodo'));
const ProfileUpcoming = lazy(() => import('./pages_v3/ProfileUpcoming'));
const LabelPreviewBanner = lazy(() => import('@components/UI/LabelPreviewBanner'));
const CMSPreviewBanner = lazy(() => import('@components/UI/CMSPreviewBanner'));
const CookieBanner = lazy(() => import('@components/UI/CookieBanner'));
const DeleteProfilePage = lazy(() => import('./pages_v3/DeleteProfilePage'));
const DeleteUserSuccessPage = lazy(() => import('./pages_v3/DeleteUserSuccessPage'));

import { withTranslation } from 'react-i18next';

import {
  isBrandCarouselPage,
  isBrandL2Page,
  isBrandPage,
  isBrandSubcataloguePage,
  isBrandTPSubcataloguePage,
  PUBLIC_URLS,
  USER_URLS,
  UtagViews,
} from './components_v3/link-utils';
import Menu from '@components/Menu2';
import { TealiumEvents, UserTypes } from '@model/TrackingClass';
import { getCookie, setCookie } from '@utility/cookie';
import { isUserEnabledForTrainingPills } from '@utility/TrainingPillsUtility';
import { isEcommerceActive } from '@utility/ecommerceUtility';
import ModalCollection from '@components/ModalCollection';
import { useSelector } from '@hooks/redux';
import MenuModal from '@components/MenuModal';
import { isSupportLessonInOnboarding } from '@utility/onBoardingCourseUtility';
import { isCollectionActive } from '@utility/CollectionsUtility';
import CollectionPreviewBanner from '@components/UI/CollectionPreviewBanner';
import UserGroupSimulationDialog from './pages_v3/UserGroupSimulation/UserGroupSimulationDialog';
import { isPersonificationOn } from './pages_v3/UserGroupSimulation/UserGroupSimulation_PersonificateLogic';

const ProfileActivateLicense = lazy(() => import('./pages_v3/ProfileActivateLicense'));
const CartPage = lazy(() => import('./pages_v3/CartPage'));
const CheckoutPage = lazy(() => import('./pages_v3/CheckoutPage/CheckoutPage'));
const OrderConfirmationPage = lazy(() => import('./pages_v3/CheckoutPage/OrderConfirmationPage'));
const PlanPage = lazy(() => import('./pages_v3/PlanPage'));
const CommercialOfferPage = lazy(() => import('./pages_v3/CommercialOfferPage'));
const ProfileOrderHistory = lazy(() => import('./pages_v3/ProfileOrderHistory'));
const ProfileOrderHistoryDetails = lazy(() => import('./pages_v3/ProfileOrderHistoryDetails'));
const ProfileSendToken = lazy(() => import('./pages_v3/ProfileSendToken'));

const NotificationsPanel = lazy(() => import('./pages_v3/NotificationsPanel'));
// const NotificationsPage = lazy(() => import('./pages_v3/NotificationsPage'));
const TPCatalogue = lazy(() => import('./pages_v3/TPCatalogue'));
const ServicePage = lazy(() => import('./pages_v3/ServicePage'));
const PreviewModal = lazy(() => import('@components/UI/PreviewModal'));
const HowTo = lazy(() => import('./pages_v3/HowTo'));
const OnBoardingPage = lazy(() => import('./pages_v3/OnBoardingPage'));
const EducationalPathsPage = lazy(() => import('./pages_v3/EducationalPathsPage'));
const BuildYourCareerPage = lazy(() => import('./pages_v3/BuildYourCareer/BuildYourCareerPage'));
const RoleSpecificProgramPage = lazy(() => import('./pages_v3/BuildYourCareer/RoleSpecificProgramPage'));
const SubjectMatterProgramPage = lazy(() => import('./pages_v3/BuildYourCareer/SubjectMatterProgramPage'));
const LatestCollectionsPage = lazy(() => import('./pages_v3/collections/LatestCollectionsPage'));
const HighlightsCollectionsPage = lazy(() =>
  import('./pages_v3/collections/HighlightsCollectionsPage')
);
const ToBeContinuedPage = lazy(() => import('./pages_v3/Catalogue/ToBeContinuedPage'));
const MorePremiumContentPage = lazy(() => import('./pages_v3/Catalogue/MorePremiumContentPage'));
const RecommendedInToDoPage = lazy(() => import('./pages_v3/Catalogue/RecommendedInToDoPage'));
const CollectionPage = lazy(() => import('./pages_v3/collections/CollectionPage'));
const NoveltiesStoryCatalogue = lazy(() => import('./pages_v3/collections/NoveltiesStoryCatalogue'));
const ProductOverview = lazy(() => import('./pages_v3/ProductOverview'));

const NotificationPreview = lazy(() => import('@components/UI/NotificationPreview'));
const AddToHomescreenPrompt = lazy(() => import('@components/AddToHomescreenPrompt'));
const UserGroupSimulation = lazy(()=> import('./pages_v3/UserGroupSimulation/UserGroupSimulation'));
const LabelPersonificationBanner = lazy(()=> import('./pages_v3/UserGroupSimulation/LabelPersonificationBanner'));
const CMSNewPage = lazy(() => import('./pages_v3/CMSNewPages/CMSNewPage.tsx'));
const CMSNewPagePlaybook = lazy(() => import('./components_v3/CMSNewPages/CMSNewPagePlaybook.tsx'));

/**
 * 
 * @returns CmsNewPage specifically related to the Landing 
 * (with the dynamic header implemented)
 */
const Landing = () => {
  return <CMSNewPage isLanding hasDynamicHeader />
}

/**
 * LEON-6790
 * @returns CmsNewPage with the dynamic header implemented
 */
const CmsNewPageWithDynamicHeader = () => {
  return <CMSNewPage hasDynamicHeader />
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      browser: '',
      isMac: false,
      isKeyboardUser: false,
      isFirstLandingAnalytics: true,
      isLoggedOut: false,
      version: packageJson.version
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.setKeyboardUser);
    window.addEventListener('mousedown', this.unsetKeyboardUser);

    const setVh = () => {
      const vh = window.innerHeight;
      document.documentElement.style.setProperty('--vh-nobars', `${vh}px`);
    };

    setVh();
    window.addEventListener('load', setVh);
    window.addEventListener('resize', setVh);

    if (this.props.isAuthenticated) {
      this.makeInitialCalls();
      this.redirectAfterLogin();
    }

    /* if ('serviceWorker' in navigator) { 
      navigator.serviceWorker.register('./service-worker.js').then(registration => {
        console.log("service registered", registration)
      }).catch(err => {
        console.log("registration failed", err)
      });   
    } */

    setFavicon(this.props.notifications, this.props.courseMap, this.props.userProfile);
    setDocumentTitle(
      this.props.location,
      this.props.notifications,
      this.props.coursesMap,
      this.props.userProfile,
      this.props.lang,
      this.props.searchQuery,
      this.props.catalogueDocTitle,
      this.props.t
    );

    this.setState({
      browser: getBrowserAndVersion(),
      isMac: isMac(),
    });
    initializeTealium();

    // CMS PREVIEW
    const cmsPreview = getCookie(COOKIE.CMS_PREVIEW);

    if(!cmsPreview) {
      const params = new URLSearchParams(window?.location?.search);
      const cmsPreviewQueryParam = queryParams.CMS_PREVIEW && params?.get(queryParams.CMS_PREVIEW) === 'true';
      if(cmsPreviewQueryParam) {
        // Set 6 hours of expiration date
        const expirationDate = new Date();
        let hours = 6
        expirationDate?.setTime(expirationDate?.getTime() + hours * 60 * 60 * 1000);
        setCookie(
          COOKIE.CMS_PREVIEW, // name
          'true', // value
          `${expirationDate?.toUTCString()}`, // expiration
          '', // domain
          true, // secure
          "None" // samesite
        );
      }
    }

    // Saving version of Leonardo into window and log it
    console.debug("Version: ",this.state.version);
    window.version = this.state.version
    /* __________ */
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isAuthenticated !== this.props.isAuthenticated && this.props.isAuthenticated) {
      this.makeInitialCalls();
    }

    if(!this?.props?.windowLocationSearchQuery?.includes(queryParams.COURSE_DETAIL)) {
      this.props.setAnalyticsCourseId('')
    }

    //elaborate fast courses
    if (
      (prevProps.isUserCompleted !== this.props.isUserCompleted ||
        prevProps.isLoadingCoursesFast !== this.props.isLoadingCoursesFast) &&
      this.props.isUserCompleted &&
      !this.props.isLoadingCoursesFast &&
      !this.props.isGetCourseCompleted
    ) {
      this.props.saveCourses(true);
    }

    //elaborate courses
    if (
      (prevProps.isUserCompleted !== this.props.isUserCompleted ||
        prevProps.isGetCourseCompleted !== this.props.isGetCourseCompleted) &&
      this.props.isUserCompleted &&
      this.props.isGetCourseCompleted
    ) {
      this.props.saveCourses();
    }

    //elaborate courses after change language
    if (
      prevProps.isUserUpdatedCompleted !== this.props.isUserUpdatedCompleted &&
      this.props.isUserUpdatedCompleted &&
      this.props.isOnlyUser
    ) {
      this.props.saveCourses();
    }

    //handle favicon
    if (
      prevProps.notifications !== this.props.notifications ||
      prevProps.coursesMap !== this.props.coursesMap ||
      prevProps.userProfile?.timezone !== this.props.userProfile?.timezone
    ) {
      setFavicon(this.props.notifications, this.props.coursesMap, this.props.userProfile);
    }

    //handle browser tab title
    if (
      prevProps.notifications !== this.props.notifications ||
      prevProps.coursesMap !== this.props.coursesMap ||
      prevProps.userProfile?.timezone !== this.props.userProfile?.timezone ||
      prevProps.location !== this.props.location ||
      prevProps.catalogueDocTitle !== this.props.catalogueDocTitle ||
      prevProps.searchQuery !== this.props.searchQuery ||
      prevProps.currentCourse !== this.props.currentCourse ||
      prevProps.showCourseModal !== this.props.showCourseModal
    ) {
      setDocumentTitle(
        this.props.location,
        this.props.notifications,
        this.props.coursesMap,
        this.props.userProfile,
        this.props.lang,
        this.props.searchQuery,
        this.props.catalogueDocTitle,
        this.props.t,
        this.props.currentCourse,
        this.props.showCourseModal
      );
    }

    if (
      prevProps.userProfile !== this.props.userProfile ||
      prevProps.coursesMap !== this.props.coursesMap ||
      prevProps.location?.pathname !== this.props.location?.pathname ||
      prevProps.catalogueDocTitle !== this.props.catalogueDocTitle ||
      (this.state.isFirstLandingAnalytics && !this.props.isAuthenticated)
    ) {
      console.log(`hasChanged AppJs`, {
        userProfile: prevProps.userProfile !== this.props.userProfile,
        coursesMap: prevProps.coursesMap !== this.props.coursesMap,
        location: prevProps.location !== this.props.location,
        pathname: prevProps.location?.pathname !== this.props.location?.pathname,
        catalogueDocTitle: prevProps.catalogueDocTitle !== this.props.catalogueDocTitle,
      });

      if(prevProps.location?.pathname.includes('/program/') &&
        !this.props.location?.pathname.includes('/program/')
      ) {
        window?.sessionStorage.removeItem("Products_ContextId_Array")
      }
      // let sendPageAnalytics = true;
      // if (
      //   window.location?.search.includes(queryParams.COURSE_DETAIL)
      // ) {
      //   //if a lesson detail is opened --> just send lesson or TP detail analytics
      //   sendPageAnalytics = false;
      // }


      // const isNotNewPage =
      //   prevProps.location?.pathname === this.props.location?.pathname &&
      //   prevProps.catalogueDocTitle !== this.props.catalogueDocTitle;

      //if (sendPageAnalytics && !isNotNewPage && UtagViews[this.props.location.pathname]) {
        // if (
        //   !this.props.isAuthenticated //||
        //   // (this.props.isAuthenticated &&
        //   //   this.props.userProfile &&
        //   //   this.props.isTimeOnTrainingLoaded &&
        //   //   this.props.coursesMap &&
        //   //   !this.props.currentCourse?.courseDetails)
        // ) {
        //   // const userData = this.props.isAuthenticated
        //   //   ? {
        //   //     Page_Language: this.props.userProfile.preferredLang,
        //   //     Page_Country: this.props.userProfile.country,
        //   //     User_LoginStatus: this.props.userProfile.senderSystem,
        //   //     User_Id: this.props.userProfile.username,
        //   //     user_timeontraining: this.props.timeOnTraining,
        //   //     Page_DeviceType : isMobile.any()? 'app' : 'web',
        //   //     //User_MoodleID: this.props.userProfile?.moodleId
        //   //   }
        //   //   : {
        //   //     User_LoginStatus: UserTypes.guest,
        //   //     Page_Language: this.props.i18n.language,
        //   //     Page_DeviceType : isMobile.any()? 'app' : 'web',
        //   //   };

      const isNotNewPage =
        prevProps.location?.pathname === this.props.location?.pathname &&
        prevProps.catalogueDocTitle !== this.props.catalogueDocTitle;
        
      if(localStorage.getItem('arrivedFromOnboarding') === 'true' && !isNotNewPage && !window?.location?.pathname.includes("program")){
          localStorage.removeItem('arrivedFromOnboarding');
        }


          // const utagViewData = {
          //   ...UtagViews[this.props.location.pathname],
          //   ...userData,
          //   Page_DeviceType: 'web',
          //   Order_Currency: this.props.userProfile?.ecommerceInfo?.currency,
          // }

          // console.log(
          //   `%c ${UtagViews[this.props.location.pathname]?.Page_Section2} - App.js ${this.props.location.pathname
          //   }`,
          //   'color: #bada55; font-size: 20px',
          //   new Date().toISOString().slice(11, 23),
          //   utagViewData
          // );

          // const action = () => {

          //   triggerPageView(utagViewData)
          //   // window?.utag?.view({
          //   //   ...UtagViews[this.props.location.pathname],
          //   //   ...userData,
          //   //   Page_DeviceType: 'web',
          //   //   Order_Currency: this.props.userProfile?.ecommerceInfo?.currency,
          //   // });
          // };

        //   // let pageName = UtagViews[this.props.location.pathname]?.Page_Section2
        //   // if(!pageName) {
        //   //   pageName = UtagViews[this.props.location.pathname]?.Page_Section1
        //   // }

        //   // console.log(
        //   //   `%c ${pageName} - App.js ${this.props.location.pathname
        //   //   }`,
        //   //   'color: #bada55; font-size: 20px',
        //   //   new Date().toISOString().slice(11, 23),
        //   //   {
        //   //     ...UtagViews[this.props.location.pathname],
        //   //     ...userData,
        //   //   }
        //   // );

        //   // const action = () => {
        //   //   window?.utag?.view({
        //   //     ...UtagViews[this.props.location.pathname],
        //   //     ...userData,
        //   //   });
        //   // };

        //   // if (!window?.utag) {
        //   //   // !window.utag means that the app doesn't already downloaded the utag library
        //   //   // wait some seconds and retry
        //   //   // not the best solution --> it's to be improved
        //   //   setTimeout(() => {
        //   //     action();
        //   //   }, 4000);
        //   // } else {
        //   //   action();
        //   // }

        //   this.setState({
        //     isFirstLandingAnalytics: false,
        //   });
        // }
      //}
    }

    // close menu if page is changed
    if (
      prevProps.location !== this.props.location &&
      prevProps.location?.pathname !== this.props.location?.pathname
    ) {
      if (this.props.openMenu) {
        this.props.setOpenMenu(false);
      }
    }

    if (prevProps.isAuthenticated !== this.props.isAuthenticated && this.props.isAuthenticated) {
      this.redirectAfterLogin();
    }

    //elaborate fast courses
    if (
      (prevProps.userProfile !== this.props.userProfile ||
        prevProps.location.pathname !== this.props.location.pathname) &&
      this.props.userProfile?.preferencesSetDate === '0' &&
      this.props.location.pathname !== USER_URLS.LEGAL.URL
    ) {
      this.props.showPersonalization(true);
    }

    //check if courseDetail param is present and coursesMap is populated and is not loading details
    const courseDetailId = getParameterByName(queryParams.COURSE_DETAIL);
    // && !this.props.isCourseDetailOpenedByUrl
    if (
      courseDetailId &&
      // && (prevProps.coursesMap !== this.props.coursesMap || prevProps.isLoadingDetails !== this.props.isLoadingDetails)
      this.props.coursesMap &&
      !this.props.isLoadingDetails[courseDetailId] &&
      this.props.currentCourse?.courseId !== courseDetailId
    ) {
      const courseDetail = getParentCourse(courseDetailId, this.props.coursesMap);

      if (courseDetail) {
        if (isSupportLessonInOnboarding(courseDetail)) {
          console.log('Lesson ' + courseDetailId + ' cannot be opened');
        } else {
          console.log('open course detail ' + courseDetailId);
          this.props.openCourseDetailByUrl(courseDetail);
        }
      } else {
        console.log('Course ' + courseDetailId + ' requested in url param was not found');
      }
    }

    //close course modal if the param courseDetail is not present in the url
    if (!courseDetailId && this.props.currentCourse && this.props.isAuthenticated) {
      // console.log('Closing currentCourse', this.props.currentCourse)
      this.props.toggleModal(false, null, true);
    }

    //get training pills regardless of pathname to see if user can see tps
    if (
      prevProps.userProfile !== this.props.userProfile &&
      this.props.userProfile &&
      isUserEnabledForTrainingPills(this.props.userProfile)
    ) {
      this.props.getTrainingPillsHP();
    }

    if (prevProps.color !== this.props.color && this.props.color) {
      document.documentElement.style.setProperty('--theme-color', this.props.color);
    }

    //save link intranet
    if (
      prevProps.userProfile !== this.props.userProfile &&
      prevProps.userProfile?.linkIntranet &&
      this.props.isAuthenticated
    ) {
      this.props.userProfile.linkIntranet = prevProps.userProfile?.linkIntranet;
    }
  }

  makeInitialCalls = () => {
    if (!this.props.userProfile) {
      this.props.getUserProfileFast();
      this.props.getUserProfile();
      this.props.getCourses();
    }

    //get sort by relevance
    //RELEVANCE IS NOW DIFFERENT - CONFIGURATION IS NO MORE USED
    // if (!this.props.isLoadedSortByRelevance && !this.props.isLoadingSortByRelevance) {
    // 	this.props.getSortByRelevance();
    // }

    if (!this.props.lang.HOME_PAGE_NAME_LABEL && this.props.userProfile) {
      //see if the first label is empty
      this.props.getLabels(this.props.userProfile.preferredLang);
      this.props.getLabels(this.props.userProfile.preferredLang, 'ecommerce');
    }

    if (!this.props.langNotif.NOTIF_NEW_COURSE_TITLE && this.props.userProfile) {
      //see if the first label is empty
      this.props.getLabelsNotif(this.props.userProfile.preferredLang);
    }

    if (!this.props.lang.TP_CAROUSEL_TITLE && this.props.userProfile) {
      //see if the first label is empty
      this.props.getLabels(this.props.userProfile.preferredLang, 'trainingpills');
    }

    if (
      (!this.props.labelL0Map || Object.values(this.props.labelL0Map)?.length === 0) &&
      this.props.userProfile
    ) {
      //see if the labels are present
      this.props.getLabelsLevel(this.props.userProfile.preferredLang);
    }

    this.props.getLanguages();

    //start firebase connection and get notifications
    this.props.signInFirebase();

    if (!this.props.brandsList || !this.props.brandsList.map) {
      this.props.getBrands();
    }
  };

  // for accessibility
  setKeyboardUser = e => {
    if (e.code === 'Tab') {
      //avoid useless refresh (it happens for TP detail advertising videos)
      if (!this.state.isKeyboardUser) {
        this.setState({ isKeyboardUser: true });
      }
    }
  };

  unsetKeyboardUser = () => {
    //avoid useless refresh (it happens for TP detail advertising videos)
    if (this.state.isKeyboardUser) {
      this.setState({ isKeyboardUser: false });
    }
  };

  redirectAfterLogin = () => {
    if (this.props.urlRedirectAfterLogin) {
      const url = !this.state.isLoggedOut ? this.props.urlRedirectAfterLogin : '';
      this.props.setUrlRedirectAfterLogin(null);
      console.log('redirectAfterLogin', url);
      setTimeout(() => {
        this.props.history.push(url);
      }, 0);
    }
  };

  handleLogout = () => {
    window?.utag?.link({
      id: TealiumEvents.user_logout,
      Page_DeviceType: 'web',
    });
    this.props.logout(true);
    this.setState({
      isLoggedOut: true,
    });
  };

  render() {
    let {
      history,
      lang,
      userProfile,
      isPersonalizationVisible,
      coursesMap,
      toggleModal,
      openTrainingPillDetailModal,
      isAuthenticated,
      logout,
      openMenu,
      previewVideoUrl,
      setPreviewVideoUrl,
      isGAJustInizialized,
      setGACheck,
      showNotifPanel,
    } = this.props;
    let location = this.props.location;
    return (
      <WithErrorHandling
        showError={this.props.showError}
        closeAlert={this.props.closeAlert}
        lang={lang}
        userProfile={this.props.userProfile}
        timeOnTraining={this.props.timeOnTraining}
        isTimeOnTrainingLoaded={this.props.isTimeOnTrainingLoaded}
        error={this.props.error}
      >
        <div
          className={
            this.state.browser +
            (this.state.isKeyboardUser ? ' keyboard-active' : '') +
            (this.state.isMac ? ' is-mac' : '')
          }
        >
          <Suspense fallback={null}>
            <LabelPersonificationBanner />
            <LabelPreviewBanner />
            <CMSPreviewBanner />
            <UserGroupSimulationDialog />
          </Suspense>
          <Suspense fallback={null}>
            <CollectionPreviewBanner fallback={null} />
          </Suspense>
          <Suspense fallback={null}>
            <CookieBanner />
          </Suspense>
          <Suspense fallback={null}>
            <AddToHomescreenPrompt
              showAddToHomescreenPromptLogin={true}
              showAddToHomescreenPromptFooter={false}
            />
          </Suspense>
          <Suspense fallback={null}>
            <NotificationPreview />
          </Suspense>
          <CheckOnPageChange
            location={location}
            toggleModal={toggleModal}
            openTrainingPillDetailModal={openTrainingPillDetailModal}
            history={history}
            isGAJustInizialized={isGAJustInizialized}
            setGACheck={setGACheck}
          />
          <Suspense fallback={null}>
            <Switch location={location}>
              {/* BRAND IDS NEED TO BE IN UPPERCASE */}
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.BRAND_PAGE.URL_ROUTER}
                component={BrandPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.BRAND_SUBCATALOGUE_PAGE.URL_ROUTER}
                component={BrandPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.BRAND_TP_SUBCATALOGUE_PAGE.URL_ROUTER}
                component={BrandPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.BRAND_CAROUSEL_PAGE.URL_ROUTER}
                component={BrandPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.BRAND_L2_PAGE.URL_ROUTER}
                component={BrandPage}
              />
              {/* BYC OFFICE COMPETENCIES */}
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.BRAND_PAGE_BYC_OFFICE.URL_ROUTER}
                component={BrandPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.COLLECTION.URL}
                component={CollectionPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.SUBCATALOGUE_NOVELTIES_STORY.URL}
                component={NoveltiesStoryCatalogue}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.DETAILS_TP.URL}
                component={ProductOverview}
              />
              <Route
                sensitive
                path="/:slug1*:slug2([a-z/]*[A-Z]+[a-z/]*):slug3*/"
                render={props => {
                  // redirect to lowercase url
                  const path = props.location.pathname;
                  return <Redirect to={`${path.toLowerCase()}`} />;
                }}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.HOMEPAGE.URL}
                component={LoggedHomepage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.SUBCATALOGUE.URL}
                component={CourseCatalogue}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.EDITORIAL_CAROUSEL.URL}
                component={EditorialPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.EDITORIAL_PLAN_CAROUSEL.URL}
                component={EditorialPlanPage}
              />
              <PrivateRoute sensitive exact path={USER_URLS.LT_PAGE.URL} component={LtPage} />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.SUBCATALOGUE_TP.URL}
                component={TPCatalogue}
                isTpPage
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.SUBCATALOGUE_SEARCH.URL}
                component={Search}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.PROFILE.URL}
                component={MyLeonardoPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.PROFILE_SETTINGS.URL}
                component={ProfileSettings}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.PROFILE_SETTINGS_PLATFORM.URL}
                component={PlatformSettings}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.PROFILE_SETTINGS_CONTENT.URL}
                component={ContentPersonalization}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.PROFILE_SETTINGS_BILLING.URL}
                component={BillingAddressSettings}
                isEcommercePage
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.PROFILE_SETTINGS_PAYMENT.URL}
                component={PaymentMethodsSettings}
                isEcommercePage
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.PROFILE_PERSONAL_SETTINGS.URL}
                component={ProfileInfoSettings}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.PROFILE_PERSONAL_SETTINGS_SUCCESS.URL}
                component={ProfileInfoSettings}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.PROFILE_ORDER_HISTORY.URL}
                component={ProfileOrderHistory}
                isEcommercePage
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.PROFILE_ORDER_HISTORY_DETAILS.URL}
                component={ProfileOrderHistoryDetails}
                isEcommercePage
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.PROFILE_ORDER_HISTORY_DETAILS_OLD.URL}
                component={ProfileOrderHistory}
                isEcommercePage
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.PROFILE_SEND_TOKEN.URL}
                component={ProfileSendToken}
                isEcommercePage
                isProfileSendTokenPage
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.CART.URL}
                component={CartPage}
                isEcommercePage
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.CHECKOUT.URL}
                component={CheckoutPage}
                isEcommercePage
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.ORDER_CONFIRMATION.URL}
                component={OrderConfirmationPage}
                isEcommercePage
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.PLAN.URL}
                component={PlanPage}
                isEcommercePage
                isPlanPage
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.COMMERCIAL_OFFER.URL}
                component={CommercialOfferPage}
                isEcommercePage
                isCommercialOfferPage
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.PROFILE_ACTIVATE_LICENSE.URL}
                component={ProfileActivateLicense}
                isEcommercePage
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.PROFILE_MY_TEAM.URL}
                component={MyTeamPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.PROFILE_MY_TEAM_MEMBER.URL}
                component={TeamMemberPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.NOTIFICATIONS.URL}
                component={NotificationsPanel}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.PROFILE_MY_CHANNELS.URL}
                component={MyChannels}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.PROFILE_TO_DO.URL}
                component={ProfileTodo}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.RECOMMENDED_IN_TO_DO.URL}
                component={RecommendedInToDoPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.PROFILE_UPCOMING_EVENTS.URL}
                component={ProfileUpcoming}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.PROFILE_MY_PROGRESS.URL}
                component={ProgressPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.PROFILE_MY_CERTIFICATES.URL}
                component={MyCertificates}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.MANAGE_COOKIES.URL}
                component={ManageCookies}
              />
              <PrivateRoute sensitive exact path={USER_URLS.WISHLIST.URL} component={Wishlist} />
              <PrivateRoute sensitive exact path={USER_URLS.CONTACT_US.URL} component={ContactUs} />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.LEGAL.URL}
                component={LegalPage}
                isAuthenticated={isAuthenticated}
              />
              <Redirect
                from={`/catalogue`}
                to={{
                  pathname: '/',
                  search: `?${queryParams.COURSE_DETAIL}=${getParameterByName(
                    queryParams.COURSE_DETAIL
                  )}`,
                }}
              />
              <PrivateRoute sensitive exact path="/search" component={Search} />
              <PrivateRoute sensitive exact path={USER_URLS.HOWTO.URL} component={HowTo} />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.DELETE_PROFILE.URL}
                component={DeleteProfilePage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.ONBOARDING_COURSE.URL}
                component={OnBoardingPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.ONBOARDING_COURSE_ID.URL}
                component={OnBoardingPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.EDUCATIONAL_PATHS.URL}
                component={EducationalPathsPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.BUILD_YOUR_CAREER.URL}
                component={BuildYourCareerPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.SUBJECT_MATTER_PROGRAM.URL}
                component={SubjectMatterProgramPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.SUBJECT_MATTER_PROGRAM_PROGRAM.URL}
                component={OnBoardingPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.MANAGEMENT_DEVELOPMENT_PROGRAM_PROGRAM.URL}
                component={OnBoardingPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.ROLE_SPECIFIC_PROGRAM.URL}
                component={RoleSpecificProgramPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.ROLE_SPECIFIC_PROGRAM_PROGRAM.URL}
                component={OnBoardingPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.LATEST_COLLECTIONS.URL}
                component={LatestCollectionsPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.HIGHLIGHTS_COLLECTIONS.URL}
                component={HighlightsCollectionsPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.TO_BE_CONTINUED_SUBCATALOGUE.URL}
                component={ToBeContinuedPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.MORE_PREMIUM_CONTENT_PAGE.URL}
                component={MorePremiumContentPage}
              />
              <PrivateRoute
                sensitive
                exact
                path={USER_URLS.USER_GROUP_SIMULATION.URL}
                component={UserGroupSimulation}
              />
              <Route sensitive exact path={PUBLIC_URLS.LOGIN.URL} component={LoginPage} />
              <PublicRoute
                sensitive
                exact
                path={PUBLIC_URLS.REGISTER.URL}
                component={RegisterPage}
              />
              <PublicRoute
                sensitive
                exact
                path={PUBLIC_URLS.REGISTER_SUCCESS.URL}
                component={SuccessPage}
              />
              <PublicRoute
                sensitive
                exact
                path={PUBLIC_URLS.FORGOT_PASSWORD.URL}
                component={ForgotPassword}
              />
              <PublicRoute
                sensitive
                exact
                path={PUBLIC_URLS.EXPIRED_PASSWORD.URL}
                component={ResetPassword}
              />
              <PublicRoute
                sensitive
                exact
                path={PUBLIC_URLS.FORGOT_PASSWORD_SUCCESS.URL}
                component={RecoveryPasswordSuccessPage}
              />
              <PublicRoute
                sensitive
                exact
                path={PUBLIC_URLS.HOMEPAGE.URL}
                // component={PublicHomepage}
                component={Landing}
              />
              <PublicRoute
                sensitive
                exact
                path={PUBLIC_URLS.RESET_PASSWORD.URL}
                component={ResetPassword}
              />
              <PublicRoute
                sensitive
                exact
                path={PUBLIC_URLS.RESET_PASSWORD_SUCCESS.URL}
                component={ResetPasswordSuccessPage}
              />
              <PublicRoute
                sensitive
                exact
                path={PUBLIC_URLS.CATALOGUE.URL}
                component={PublicCatalogue}
              />
              <PublicRoute
                sensitive
                exact
                path={PUBLIC_URLS.CONTACT_US.URL}
                component={ContactUs}
              />
              <PublicRoute
                sensitive
                exact
                path={PUBLIC_URLS.MANAGE_COOKIES.URL}
                component={ManageCookies}
              />
              <PublicRoute sensitive exact path={PUBLIC_URLS.LEGAL.URL} component={LegalPage} />
              <PublicRoute
                sensitive
                exact
                path={PUBLIC_URLS.SERVICE_PAGE.URL}
                component={ServicePage}
              />
              <PublicRoute
                sensitive
                exact
                path={PUBLIC_URLS.DELETE_USER_SUCCESS.URL}
                component={DeleteUserSuccessPage}
              />
              <PublicRoute
                sensitive
                exact
                path={PUBLIC_URLS.WELCOME.URL}
                component={PromoLandingPage}
              />
              <PublicCmsNewPageRoute
                sensitive
                // exact
                path={`${PUBLIC_URLS.CMS_NEW_PAGE_PUBLIC.URL}`}
                component={CmsNewPageWithDynamicHeader}
              />
              <PrivateRoute
                  sensitive
                  // exact
                  path={`${USER_URLS.CMS_NEW_PAGE.URL}`}
                  component={CMSNewPage}
              />
              {/* <PublicRoute
                  sensitive
                  exact
                  path={USER_URLS.CMS_NEW_PAGE_PLAYBOOK.URL}
                  component={CMSNewPagePlaybook}
              /> */}
              <PrivateRoute sensitive exact path={USER_URLS.EMPTY_PAGE.URL} component={EmptyPage} />
              <Route sensitive exact path={USER_URLS.NOT_FOUND.URL} component={NotFound} />
              <Route sensitive exact path="/temp.html" render={props => <Redirect to="/" />} />
              <Redirect to={USER_URLS.NOT_FOUND.URL} />
            </Switch>
          </Suspense>
          {isPersonalizationVisible && (
            <Suspense fallback={null}>
              <Personalization />
            </Suspense>
          )}
          <ModalCollection />
          <MenuModal
            show={openMenu}
            closeMenu={() => this.props.setShowModalByType(null)}
            isAuthenticated={isAuthenticated}
            logout={this.handleLogout}
            isManager={userProfile?.role && userProfile.role.includes('manager')}
            isTeacher={userProfile?.role && userProfile.role.includes('teacher')}
            userProfile={userProfile}
          />
          {previewVideoUrl && (
            <Suspense fallback={null}>
              <PreviewModal
                lang={lang}
                t={this.props.t}
                show={previewVideoUrl ? true : false}
                src={previewVideoUrl}
                onClose={() => setPreviewVideoUrl(null)}
              />
            </Suspense>
          )}
          {/* {showNotifPanel &&
						<Suspense fallback={null}>
							<NotificationsPage
								lang={lang}
								history={history}
								userProfile={userProfile}
							/>
						</Suspense>
					} */}
        </div>
      </WithErrorHandling>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.authentication.isAuthenticated,
    isGAJustInizialized: state.authentication.isGAJustInizialized,
    showError: state.utils.showError,
    error: state.utils.error,
    courses: state.course.courses,
    coursesMap: state.course.coursesMap,
    userProfile: state.user.userProfile,
    lang: state.utils.lang,
    langNotif: state.utils.langNotif,
    searchInput: state.course.searchInput,
    searchQuery: state.search.searchQuery,
    isGetCourseCompleted: state.course.isGetCourseCompleted,
    isLoadingCoursesFast: state.course.isLoadingCoursesFast,
    isUserCompleted: state.user.isUserCompleted,
    isUserUpdatedCompleted: state.user.isUserUpdatedCompleted,
    isOnlyUser: state.user.isOnlyUser,
    joiningCourses: state.course.joiningCourses,
    isClosingEventSource: state.course.isClosingEventSource,
    isPersonalizationVisible: state.utils.isPersonalizationVisible,
    isGetCourseCompletionCompleted: state.course.isGetCourseCompletionCompleted,
    isLoadedSortByRelevance: state.utils.isLoadedSortByRelevance,
    isLoadingSortByRelevance: state.utils.isLoadingSortByRelevance,
    isCourseDetailOpenedByUrl: state.course.isCourseDetailOpenedByUrl,
    isLoadingDetails: state.course.isLoadingDetails,
    urlRedirectAfterLogin: state.utils.urlRedirectAfterLogin,
    notifications: state.firebase.notifications,
    catalogueDocTitle: state.utils.catalogueDocTitle,
    timeOnTraining: state.course.timeOnTraining,
    isTimeOnTrainingLoaded: state.course.isTimeOnTrainingLoaded,
    currentCourse: state.course.currentCourse,
    showCourseModal: state.course.showCourseModal,
    openMenu: state.utils.typeModal === MODAL_TYPES.menuRightModal,
    previewVideoUrl: state.course.previewVideoUrl,
    isFullUserCompleted: state.user.isFullUserCompleted,
    showNotifPanel: state.firebase.showNotifPanel,
    brandsList: state.utils.brandsList,
    color: state.utils.gradientStyle?.mainColor?.color,
    cart: state.ecommerce.cart,
    plan: state.ecommerce.plan,
    labelL0Map: state.utils.labelL0Map,
    labelL1Map: state.utils.labelL1Map,
    triggerAppJsUpdate: state.course.triggerAppJsUpdate,
    windowLocationSearchQuery: state.utils.windowLocationSearchQuery,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeAlert: () => dispatch(actions.closeAlert()),
    getCourses: () => dispatch(actions.getCourses()),
    getUserProfile: () => dispatch(actions.getUserProfile()),
    getUserProfileFast: () => dispatch(actions.getUserProfileFast()),
    getLabels: () => dispatch(actions.getLabels()),
    getLabelsNotif: () => dispatch(actions.getLabelsNotif()),
    getLabelsLevel: () => dispatch(actions.getLabelsLevel()),
    setGACheck: value => dispatch(actions.setGACheck(value)),
    getLanguages: () => dispatch(actions.getLanguages()),
    saveCourses: isFast => dispatch(actions.saveCourses(isFast)),
    logout: removeCredentialsCookie => dispatch(actions.logout(removeCredentialsCookie)),
    getSortByRelevance: () => dispatch(actions.getSortByRelevance()),
    toggleModal: (value, course, closeAll) =>
      dispatch(actions.toggleModal(value, course, closeAll)),
    openCourseDetailByUrl: course => dispatch(actions.openCourseDetailByUrl(course)),
    setIsCourseDetailOpenedByUrl: value => dispatch(actions.setIsCourseDetailOpenedByUrl(value)),
    setUrlRedirectAfterLogin: url => dispatch(actions.setUrlRedirectAfterLogin(url)),
    handleRedirectAfterLogin: () => dispatch(actions.handleRedirectAfterLogin()),
    signInFirebase: () => dispatch(actions.signInFirebase()),
    setOpenMenu: open => dispatch(actions.setOpenMenu(open)),
    setShowModalByType: type => dispatch(actions.setShowModalByType(type)),
    showPersonalization: val => dispatch(actions.showPersonalization(val)),
    setPreviewVideoUrl: url => dispatch(actions.setPreviewVideoUrl(url)),
    getBrands: () => dispatch(actions.getBrands()),
    getTrainingPillsHP: () => dispatch(actions.getTrainingPillsHP()),
    setAnalyticsCourseId: (courseId) => dispatch(actions.setAnalyticsCourseId(courseId)),
    setWindowLocationSearchQuery: (query) => dispatch(actions.setWindowLocationSearchQuery(query))
  };
};

const CheckOnPageChange = ({
  location,
  toggleModal,
  openTrainingPillDetailModal,
  history,
  isGAJustInizialized,
  setGACheck,
}) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
  //const [firstTime, setFirstTime] = React.useState(true);
  const [scriptsLoaded, setScriptsLoaded] = useState(false)

  const inUpcoming = location.pathname.includes('/profile/upcoming')
  const urlHasCourseDetail = window.location?.search?.includes(queryParams.COURSE_DETAIL) && window.location?.search?.includes(queryParams.COURSE_DETAIL_TITLE);
  const coursesMap = useSelector(state => state.course.coursesMap);

  useEffect(() => {
    if (!inUpcoming && urlHasCourseDetail) {
      const courseIdMaster = getParameterByName(queryParams.COURSE_DETAIL);
      const course = coursesMap?.[courseIdMaster];
      toggleModal(urlHasCourseDetail, course, !inUpcoming);
    } else {
      toggleModal(inUpcoming, null, !inUpcoming);
    }
    dispatch(actions.setShowModalByType(null));
    /* if (!firstTime) {
      toggleModal(false, null, true);
    } else {
      setFirstTime(false);
    } */

    //scroll to top at page change
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const [isGAInitialized, setIsGAInitialized] = useState(false);
  useEffect(() => {
    let cookiePolicy = getCookie(COOKIE.COOKIE_POLICY);
    cookiePolicy = cookiePolicy ? JSON.parse(cookiePolicy) : {};

    //initialize GA and Facebook Pixel just once
    if (!isGAInitialized && cookiePolicy?.analytics) {
      //enable GA and Facebook Pixel only if user has accepted analytics cookie

      //initialize Google Analytics (GA)
      initializeGA(ReactGA, setGACheck, history);
      //initialize Facebook Pixel (called also Meta Pixel)
      initializeFacebookPixel();
      //initialize Taelium
      //initializeTealium();

      //initialize GA and Facebook Pixel just once
      setIsGAInitialized(true);
    }

    if (window.fbq && !cookiePolicy?.analytics) {
      //if Facebook Pixel is enabled but user has disabled analytics
      //refresh page to clean Facebook Pixel listeners. It's not possible to remove them by JS
      history.go();
    }

    //update redux according to cookies
    //cart icon modal
    let noShowModalCartCookie = getCookie(COOKIE.NO_SHOW_MODAL_CART);
    dispatch(actions.setNoShowModalCart(!!noShowModalCartCookie));

    //expiration label modal
    // let noShowModalExpirationCookie = getCookie(COOKIE.NO_SHOW_MODAL_EXPIRATION);
    // dispatch(actions.setNoShowModalExpiration(!!noShowModalExpirationCookie));

    //check if the user is enabled to ecommerce
    let ecommerceCookie = getCookie(COOKIE.ECOMMERCE);

    if (isEcommerceActive(ecommerceCookie)) {
      const cookie = getCookie(COOKIE.AUTORENEWAL_ACTIVE);
      try {
        if (cookie) {
          const autorenewal = JSON.parse(cookie);
          dispatch(actions.setAutorenewalActive(autorenewal?.active, autorenewal?.plan));
        }
      } catch (error) { }
    }
    //check if the label preview is enabled

    const shouldShowPreviewBanner = PREVIEW_LABEL_COOKIE_KEYS.some(
      cookieKey => !!getCookie(cookieKey)
    );
    dispatch(actions.setShowLabelPreviewBanner(shouldShowPreviewBanner));


    //check if the collection preview is enabled
    const shouldShowCollectionPreviewBanner = getCookie(COOKIE.COLLECTION_PREVIEW)
    dispatch(actions.setShowCollectionPreviewBanner(shouldShowCollectionPreviewBanner));

  }, [document.cookie]);


  useEffect(() => {
    // CMS PREVIEW
    const cmsPreview = getCookie(COOKIE.CMS_PREVIEW);
    if (cmsPreview) {
      dispatch(actions.setShowCMSPreviewBanner(true))
      if(scriptsLoaded === false) {
        const jqueryScript = document.createElement('script');
        jqueryScript.src = '/preview/jquery-3.3.1.min.js';
        jqueryScript.type = 'text/javascript';
        jqueryScript.async = true;
        jqueryScript.onload = () => {
          if (window.jQuery) {
            if (!window.coremedia) {
              const coremediaScript = document.createElement('script');
              coremediaScript.src = '/preview/coremedia.preview.js';
              coremediaScript.type = 'text/javascript';
              coremediaScript.async = true;
              const placementScript = document.createElement('script');
              placementScript.src = '/preview/placementHighlighting.js';
              placementScript.type = 'text/javascript';
              placementScript.async = true;
              document.body.appendChild(placementScript);
              document.body.appendChild(coremediaScript);
            }
          }
        };
        document.body.appendChild(jqueryScript);
        setScriptsLoaded(true);
      }

    }
  }, [document.cookie, scriptsLoaded]);


  useEffect(() => {
    //check if the user is enabled to ecommerce
    let ecommerceCookie = getCookie(COOKIE.ECOMMERCE);
    dispatch(actions.setEcommerceEnable(isEcommerceActive(ecommerceCookie)));
  }, [isAuthenticated]);

  useEffect(() => {
    let cookiePolicy = getCookie(COOKIE.COOKIE_POLICY);
    cookiePolicy = cookiePolicy ? JSON.parse(cookiePolicy) : {};

    //enable GA only if user has accepted analytics cookie
    if (cookiePolicy?.analytics && ReactGA) {
      const booleanIsGAJustInizialized =
        isGAJustInizialized === null || isGAJustInizialized === undefined
          ? true
          : isGAJustInizialized;
      addPageViewGA(booleanIsGAJustInizialized, ReactGA);
    }
  }, [location]);

  useEffect(() => {
    dispatch(actions.setWindowLocationSearchQuery(window?.location?.search));
  }, [window.location.search])

  return <></>;
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App)));
