import React, {useEffect, useState} from 'react';
import { USER_URLS } from '@components/link-utils';
import OnboardingIconsList from '@components/onboarding/OnboardingIconsList';
import RibbonCourseEvolution from '@components/UI/RibbonCourseEvolution';
import useGetOnboardingLabels from '@hooks/onboarding/useGetOnboardingLabels';
import CardCover from './CardCover';
import { CardDisplayProps } from './CardDisplayUtils';
import { useHistory } from 'react-router-dom';
import {isCompletedChildCourse, isCompletedCourse, isLearningPath, printHTML} from '@utility/Api';
import EducationalPathsProgramButton from '@components/UI/EducationalPathsProgramButton';
import EducationalPathsPrice from '@components/EducationalPathsPrice';
import EducationalPathsComplexity from '@components/EducationalPathsComplexity';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';
import { useDispatch } from 'react-redux';
import {getCourseDetails, toggleModal} from '@redux-actions/';
import DetailsSingleActivityButton from "@components/DetailsSingleActivityButton";
import {useSelector} from "@hooks/redux";
import { formatProgramForCorrectUrl } from '@utility/buildYourCareerUtility';
import {Course, LevelEnrichedMap} from "@model/CoursesClass";
import TextSkeleton from '@components/skeletons/TextSkeleton';
import { getBlockingPrograms, isProgramBlocked } from '@utility/EducationalPathsUtility';
import clsx from '@utility/clsx';
import LockMasterBanner from '@images/svg-icons/lock-master-banner.svg';
import ButtonV2 from '@components/UI/ButtonV2';


const CustomDetailsSingleActivityButton = ({courseWithDetails, lang, showTickOnCompleteLP})=>{
	return <DetailsSingleActivityButton
		course={courseWithDetails}
		subtitle={null}
		lang={lang}
		isLoading={false}
		delayGetCourseDetails
		useCustomButton={
			{
				typeElement: EducationalPathsProgramButton,
				props:{
					course: courseWithDetails,
					showTickOnCompleteLP: showTickOnCompleteLP
				},
				useActionCustom: true
			}
		}
	/>
}

const CardEducationalPathsProgram = (props: CardDisplayProps) => {
	const { course, badgeLabel, badgeColor, badgePriority, image, courseFullName, fallbackImage, ctypeName, courseIdMaster, priceLabel , dataElementId, cardRef } = props;
	const { courseSummary } = course;
	const history = useHistory();
	const onboardingLabels = useGetOnboardingLabels(course, true, false, true);
	const breakpoint = useBreakpointBoolean();
	const lang = useSelector(state=>state.utils.lang)
	const coursesMap = useSelector(state=>state.course.coursesMap)
	const isLoadingCourseSummary = useSelector(state => state.course.isLoadingCourseSummary)

	const [courseWithDetails, setCourseWithDetails] = useState<Course>(course)

	const labelL1Map: LevelEnrichedMap = useSelector((state) => state.utils.labelL1Map);
	
	const goToCourse = () => {
		// Handle for BYC
		if(props.isUpskillProgram && labelL1Map) {
			const l1 = Object.values(labelL1Map)?.find(l1 => l1?.key === course?.catalogTypes[0])?.label
			const l1LabelFormatted = l1?.toLowerCase()?.replace(/\s+/g, '-');
			history.push(USER_URLS.SUBJECT_MATTER_PROGRAM_PROGRAM.URL
				.replace(':upskillProgram', l1LabelFormatted)
				.replace(':id', courseIdMaster));
			return
		}
		// Handle standard behaviour
		history.push(USER_URLS.ONBOARDING_COURSE_ID.URL.replace(':id', courseIdMaster));
	};


	return (
		<a
		className={'card-educational-paths-program'}
		data-element-id={`${dataElementId}`}
		ref={cardRef}
	>
			<div className='content' onClick={goToCourse}>
				<div className='img-badge-container'>
					<div className='image-container'>
						<div className='image-wrapper'>
							<CardCover image={image} courseFullName={courseFullName} variant='small' className='image' fallbackImage={fallbackImage} hideShadow typeHarvard={props?.typeHarvard} />
						</div>
						<div className='status--on-img'>
							{course &&
								<CustomDetailsSingleActivityButton courseWithDetails={course} lang={lang} showTickOnCompleteLP={props.showTickOnCompleteLP} />
							}
						</div>
						{!breakpoint.isPhone && (
							<div className='badge-container'>
								{badgeLabel && (
									<RibbonCourseEvolution
										className='badge'
										course={course}
										useBadgeLabelLive={false}
										ribbonInfo={{
											label: badgeLabel,
											color: badgeColor,
											priority: badgePriority,
										}}
									/>
								)}
								{priceLabel && <EducationalPathsPrice label={priceLabel} className='price' />}
							</div>
						)}
					</div>
				</div>
				<div className='content-container'>
					<EducationalPathsComplexity label={ctypeName} />
					<div className={clsx('info-container', {
						'show-blocked-program-status' : breakpoint.isPhone && isProgramBlocked(course, coursesMap)
					})}>
						<div className='information'>
							<div className='title'>
								{courseFullName}
								{breakpoint.isPhone && isProgramBlocked(course, coursesMap) && !isCompletedCourse(course) && (
									<ButtonV2 variant="icon-btn">
										<LockMasterBanner />
									</ButtonV2>
								)}
							</div>
							{breakpoint.isPhone ? (
								onboardingLabels?.icons?.length > 0 &&
								<OnboardingIconsList className='badges' onboardingIcons={onboardingLabels.icons} isTagBadge={true} />
							) : (
								<OnboardingIconsList className='badges' onboardingIcons={onboardingLabels.icons} isTagBadge={true} />
							)}
							<div className='description'>
								{isLoadingCourseSummary?.[course?.courseId] || courseSummary == undefined
								? 
								<>
									<TextSkeleton className='phone-and-tablet-portrait' width={250} height={15} borderRadius={12}/>
									<TextSkeleton className='phone-and-tablet-portrait' width={250} height={15} borderRadius={12}/>
									<TextSkeleton className='phone-and-tablet-portrait' width={100} height={15} borderRadius={12}/>
									<TextSkeleton className='tablet-landscape' width={400} height={15} borderRadius={12}/>
									<TextSkeleton className='tablet-landscape' width={400} height={15} borderRadius={12}/>
									<TextSkeleton className='tablet-landscape' width={300} height={15} borderRadius={12}/>
									<TextSkeleton className='desktop' width={400} height={15} borderRadius={12}/>
									<TextSkeleton className='desktop' width={580} height={15} borderRadius={12}/>
									<TextSkeleton className='desktop' width={200} height={15} borderRadius={12}/>
								</>
								:<div dangerouslySetInnerHTML={printHTML(courseSummary)}></div>
								}
							</div>
							{isProgramBlocked(course, coursesMap) && breakpoint.isTabletPortrait &&
								<p className='blocked-program-tp'>{lang?.BLOCKED_PROGRAM_INFO}</p>
							}
						</div>
						<div className='status'>
							{!isProgramBlocked(course, coursesMap) || isCompletedCourse(course) ? (
								course &&
								<CustomDetailsSingleActivityButton courseWithDetails={course} lang={lang} showTickOnCompleteLP={props.showTickOnCompleteLP} />
							) : (
								(!isCompletedCourse(course) &&
									<div className='blocked-program'>
										{!breakpoint.isTabletPortrait &&
											<p>{lang?.BLOCKED_PROGRAM_INFO}</p>
										}
										{!breakpoint.isPhone && (
											<ButtonV2 variant="icon-btn">
												<LockMasterBanner />
											</ButtonV2>
										)}
									</div>
								)
							)}
						</div>
					</div>
				</div>
			</div>
			{breakpoint.isPhone && !isProgramBlocked(course, coursesMap) &&(
				<div className='badge-container'>
					{badgeLabel && (
						<RibbonCourseEvolution
							className='badge'
							course={course}
							useBadgeLabelLive={false}
							ribbonInfo={{
								label: badgeLabel,
								color: badgeColor,
								priority: badgePriority,
							}}
						/>
					)}
					{priceLabel && <EducationalPathsPrice label={priceLabel} className='price' />}
				</div>
			)}
		</a>
	);
};

export default CardEducationalPathsProgram;
