import useLangAuth from '@hooks/useLangAuth';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { NextArrow, PrevArrow } from './UI/Carousel';
import { analyticsPageType, purifyItemDataElementId } from '@utility/analytics-utils';

type ScrollInfo = {
  scrollWidth: number;
  clientWidth: number;
  scrollLeft: number;
};

type Props = {
  ContentCarousel: JSX.Element[];
  SkeletonCarousel: JSX.Element[];
  ShowMoreButton: JSX.Element;
  variant:
    | 'card-big'
    | 'card-medium'
    | 'pills'
    | 'progress'
    | 'training-pills'
    | 'image'
    | 'educational-paths-section'
    | 'educational-path';
  isLoading: boolean;
  className?: string;
  titleComponent?: JSX.Element;
  titleInCarousel?: boolean;
  arrowsStyle?: 'latest-collections' | 'tp-image';
  onCarouselScroll?;
  elementPosition?: number;
  carouselPosition?: number;
  carouselName?: string;
};

const CarouselVariableWidth = (props: Props): ReactElement => {
  const [showArrows, setShowArrows] = useState(false);
  const [showArrowLeft, setShowArrowLeft] = useState(false);
  const [showArrowRight, setShowArrowRight] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const langKeys = ['NEXT', 'PREVIOUS'] as const;

  const labels = useLangAuth(langKeys);

  const pageType = analyticsPageType();
  const dataElementId = `${pageType}_${purifyItemDataElementId(props.carouselName)}${props.carouselPosition ? 'N' + props.carouselPosition : ''}`;

  useEffect(() => {
    if (scrollRef) {
      //handle scroll for first time
      handleScroll(scrollRef?.current);
    }
  }, [scrollRef]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    handleScroll(scrollRef?.current);
  };

  const handleScroll = (element: HTMLDivElement) => {
    if (element) {
      handleScrollUpdate(getScrollInfo(element));
      if (props.onCarouselScroll) {
        props.onCarouselScroll();
      }
    }
  };

  const getScrollInfo = (element: HTMLDivElement): ScrollInfo => {
    return {
      scrollWidth: element.scrollWidth,
      clientWidth: element.clientWidth,
      scrollLeft: element.scrollLeft,
    };
  };

  const handleScrollUpdate = (scrollValues: ScrollInfo) => {
    const showCarousel = scrollValues.scrollWidth - 1 > scrollValues.clientWidth;
    setShowArrows(showCarousel);

    if (showCarousel) {
      //if scrollLeft is greater than 0, the div has scrolled
      setShowArrowLeft(scrollValues.scrollLeft > 0);

      //get the percentage of remaining scrolling space
      //if the percentage is 100 --> scrolled on maximum rightside
      const showArrowRightTemp = Math.round(
        (100 * scrollValues.scrollLeft) / (scrollValues.scrollWidth - scrollValues.clientWidth)
      );
      setShowArrowRight(showArrowRightTemp < 100);
    } else {
      setShowArrowLeft(false);
      setShowArrowRight(false);
    }
  };

  const goPrevNext = (e, isPrev: boolean) => {
    e.stopPropagation();

    if (scrollRef && scrollRef.current) {
      const scrollState: ScrollInfo = getScrollInfo(scrollRef.current);
      let scrollLeft: number = scrollState.scrollLeft - 400;
      const offsetScroll: number = scrollState.clientWidth * (!props.titleInCarousel ? 0.8 : 0.95);
      if (isPrev) {
        scrollLeft -= offsetScroll;
      } else {
        scrollLeft += offsetScroll;
      }
      scrollRef.current.scrollTo({ behavior: 'smooth', left: scrollLeft });
    }
  };

  const PrevArrowElement = ({ isVisible, goPrevNext, labels, tabIndex = -1, arrowsStyle = '' }) => (
    <PrevArrow
      onClick={isVisible ? e => goPrevNext(e, true) : undefined}
      className={
        'carousel-variablewidth-arrow carousel-variablewidth-arrow-prev ' +
        arrowsStyle +
        ' ' +
        (isVisible ? 'enabled' : 'disabled')
      }
      nextDisabled={false}
      round={false}
      ariaLabel={labels?.PREVIOUS}
      tabIndex={tabIndex}
      arrowsStyle={arrowsStyle}
      dataElementId={dataElementId}
    />
  );

  const NextArrowElement = ({ isVisible, goPrevNext, labels, tabIndex = -1, arrowsStyle = '' }) => (
    <NextArrow
      onClick={isVisible ? e => goPrevNext(e, false) : undefined}
      className={
        'carousel-variablewidth-arrow carousel-variablewidth-arrow-next ' +
        arrowsStyle +
        ' ' +
        (isVisible ? 'enabled' : 'disabled')
      }
      setDisable={() => {}}
      round={false}
      ariaLabel={labels?.NEXT}
      tabIndex={tabIndex}
      arrowsStyle={arrowsStyle}
      dataElementId={dataElementId}
    />
  );

  return (
    <>
      {props.titleComponent && !props.titleInCarousel && (
        <div className="carousel-variablewidth-title-container">
          {props.titleComponent}
          {props.arrowsStyle && (
            <div className="carousel-variablewidth-arrows-title">
              <PrevArrowElement
                isVisible={showArrowLeft}
                goPrevNext={goPrevNext}
                labels={labels}
                tabIndex={0}
                arrowsStyle={props.arrowsStyle}
              />
              <NextArrowElement
                isVisible={showArrowRight}
                goPrevNext={goPrevNext}
                labels={labels}
                tabIndex={0}
                arrowsStyle={props.arrowsStyle}
              />
            </div>
          )}
        </div>
      )}
      <div
        className={`carousel-variablewidth ${props.variant} ${
          props.className ? props.className : ''
        } ${props.titleInCarousel ? 'title-in-carousel' : ''}`}
        onMouseEnter={handleResize}
      >
        {!props.isLoading ? (
          <>
            {props.titleInCarousel && props.titleComponent}
            <div
              className="carousel-scroller slick-slider"
              ref={scrollRef}
              onScroll={e => handleScroll(e?.target)}
            >
              <div className="carousel-variablewidth-alternate-color" role='region' aria-label='carousel-section' >
                <div className="space-left" />
                {props.ContentCarousel}
                {props.ShowMoreButton}
                <div className="space" />
              </div>
            </div>

            {showArrowLeft && (
              <PrevArrowElement
                isVisible={true}
                goPrevNext={goPrevNext}
                labels={labels}
                arrowsStyle={props.arrowsStyle}
              />
            )}
            {showArrowRight && (
              <NextArrowElement
                isVisible={true}
                goPrevNext={goPrevNext}
                labels={labels}
                arrowsStyle={props.arrowsStyle}
              />
            )}
          </>
        ) : (
          <div className="carousel-scroller skeleton">
            <div className="space-left" />
            {props.SkeletonCarousel}
            <div className="space" />
          </div>
        )}
      </div>
    </>
  );
};

export default CarouselVariableWidth;
