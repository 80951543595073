import React, { useEffect } from 'react';
import CardController from '@components/CardV3/CardController';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';
import { useInView } from 'react-intersection-observer';
import Spinner from '@components/UI/spinner_50px.svg';
import WishlistIcon from '@images/svg-icons/wishlist.svg';
import CompletedCourseCard from './CompletedCourseCard';
import CardBigEvolutionSkeleton from '@components/skeletons/CardBigEvolutionSkeleton';
import CardMediumCompletedSkeleton from './skeletons/CardMediumCompletedSkeleton';
import CardUpcomingEventsSkeleton from './skeletons/CardUpcomingEventsSkeleton';
import { USER_URLS } from './link-utils';
import { Course, LangMap } from '@model/CoursesClass';
import CardControllerProduct from './CardV3/CardControllerProduct';
import CardMediumPillSkeleton from './skeletons/CardMediumPillSkeleton';
import { TrainingPill } from '@model/ProductClass';
import { UpcomingEventsTabEnum } from '../pages_v3/ProfileUpcoming';
import clsx from '@utility/clsx';
import { isToday, isUpcomingCourse, printDate, printDayOfWeek } from '@utility/Api';
import HotAirBaloon from '../pages_v3/HotAirBaloon';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { CarouselTitle } from './CarouselSection';
import CarouselVariableWidth from './CarouselVariableWidth';
import {
  analyticsPageType,
  purifyItemDataElementIdandApplyCamelCasing,
  purifyItemDataElementIdandApplyCamelCasingBrandCase,
} from '@utility/analytics-utils';
import {isCourseComingSoon, isCourseGuru} from "@utility/CourseUtility";
import { useSelector } from '@hooks/redux';
import { getLevelLabel } from '@utility/levelUtility';

type CoursesResultsProps = {
  displayedCourses: Course[] | TrainingPill[];
  pagination: number;
  filteredCourses: Course[];
  setLimit: () => void;
  lang: LangMap;
  isLoading?: boolean;
  isTP?: boolean;
  upcomingEventsTab?: UpcomingEventsTabEnum;
  hideResultsNumber?: boolean;
  noResults?: boolean;
  hideTitle?: boolean;
  title?: keyof LangMap;
  viewAllTo?: string;
  isPlusIconVisible?: boolean;
  className?: string;
  cardVariant?: string;
  showTPAsMobile?: boolean;
  asCarouselInDesktop?: boolean;
  isSearch?: boolean;
  sortBy?: string;
};

const CoursesResults = ({
  displayedCourses,
  pagination,
  filteredCourses,
  setLimit,
  lang,
  isTP,
  isLoading,
  upcomingEventsTab,
  hideResultsNumber = false,
  noResults = false,
  hideTitle = true,
  title,
  viewAllTo,
  isPlusIconVisible = false,
  className = '',
  cardVariant = 'card-evolution',
  showTPAsMobile = false,
  asCarouselInDesktop = false,
  isSearch,
  sortBy = '',
}: CoursesResultsProps) => {
  const isCompletedCourse =
    location.pathname === USER_URLS.PROFILE_MY_PROGRESS.URL ||
    location.pathname === USER_URLS.PROFILE_MY_CERTIFICATES.URL;
  const isWishlist = location.pathname === '/wishlist';
  const breakpoint = useBreakpointBoolean();
  const [ref, inView] = useInView({
    threshold: 0,
  });

  const history = useHistory();
  const labelComplexityMap = useSelector(state => state.utils.labelComplexityMap);

  const sortByGetString = (sortedMethod: String) => {
    switch (sortedMethod) {
      case 'Relevance':
        return 'SortByRelevance';
        break;
      case 'New':
        return 'SortByNewest';
        break;
      case 'Like':
        return 'SortByMostLiked';
        break;
      case 'Shortest':
        return 'SortByShortest';
        break;
      case 'Title_AZ':
        return 'SortByTitleAZ';
        break;
      case 'Title_ZA':
        return 'SortByTitleZA';
        break;
      case 'Upcoming':
        return 'SortByUpcoming';
        break;
      default:
      return'';
    }
  };

  const nToLoad = () => {
    if (displayedCourses.length < pagination) {
      return 0;
    } else {
      return Math.min(filteredCourses.length - displayedCourses.length, pagination);
    }
  };

  useEffect(() => {
    if (inView) {
      setLimit();
    }
  }, [inView]);

  const isEmptyWishlist = displayedCourses.length === 0 && !isLoading && isWishlist;

  const isEmpty =
    ((displayedCourses && displayedCourses.length === 0) ||
      (isTP && displayedCourses.length === 0)) &&
    !isLoading &&
    !isWishlist &&
    noResults;
  let position = 0;
  let sortByEngString = sortByGetString(sortBy);
  const printCourse = course => {
    const pageType = analyticsPageType();

    let dataelementid = '';
    if(course && course.catalogTypes && course.catalogTypes[0]) {
      let cat: string = course.catalogTypes[0].includes('brand')
        ? 'Catalogue_'  //brand page PLP
        : 'Catalogue_Channel' //channel l1 page PLP
      dataelementid = `${cat}${purifyItemDataElementIdandApplyCamelCasing(
        course?.catalogTypes[0]
      )}_${sortByEngString}_${course?.courseId}N${position++}`;
    }

    
    if (isCompletedCourse) {
      return (
        <CompletedCourseCard
          key={course.courseIdMaster}
          lang={lang}
          course={course}
          isGetCertificate={location.pathname === USER_URLS.PROFILE_MY_CERTIFICATES.URL}
          data-element-id={dataelementid}
        />
      );
    }

    if (upcomingEventsTab) {
      return (
        <CardController
          variant="upcoming-events"
          course={course}
          tabIndex={0}
          key={course.courseIdMaster + '_' + course.associatedSession?.id}
          data-element-id={dataelementid}
        />
      );
    }

    if (isTP) {
      return (
        <CardControllerProduct
          product={course}
          key={course?.model}
          tabIndex={0}
          showAsMobile={showTPAsMobile}
        />
      );
    }

    if (cardVariant === 'search') {
      return (
        <CardController
          variant={!breakpoint.isPhone ? 'card-evolution' : 'search'} // evo drop 4
          noHoverEffect={false}
          course={course}
          tabIndex={0}
          breakpoint={breakpoint}
          data-element-id={dataelementid}
        />
      );
    }

    if (cardVariant) {
      return (
        <CardController
          variant={cardVariant}
          course={course}
          tabIndex={0}
          noHoverEffect
          breakpoint={breakpoint}
          data-element-id={dataelementid}
        />
      );
    }

    return (
      <CardController
        variant="card-evolution"
        noHoverEffect
        course={course}
        tabIndex={0}
        breakpoint={breakpoint}
        data-element-id={dataelementid}
      />
    );
  };

  const printSkeleton = () => {
    if (isCompletedCourse) {
      return <CardMediumCompletedSkeleton />;
    }

    if (upcomingEventsTab) {
      return <CardUpcomingEventsSkeleton />;
    }

    if (isTP) {
      return <CardMediumPillSkeleton showAsMobile={showTPAsMobile} />;
    }

    if (cardVariant === 'search') {
      // return <CardUpcomingEventsSkeleton />
      return !breakpoint.isPhone ? ( // evo drop 4
        <CardBigEvolutionSkeleton />
      ) : (
        //card search skeleton is the same of card upcoming events
        <CardUpcomingEventsSkeleton />
      );
    }

    return <CardBigEvolutionSkeleton />;
  };

  const printUpcomingEventsList = (useSkeleton = false, showAsCarouselInDesktop = false) => {
    const upcomingEventsByDate = {};
    const useCarousel = showAsCarouselInDesktop && (breakpoint.isDesktop || breakpoint.isTablet);

    if (useSkeleton && !useCarousel) {
      return [...Array(4)].map(a => (
        <div className="date-label-content lastUpcoming upcomingGrid hide-border">
          {printSkeleton()}
        </div>
      ));
    }

    for (let course of displayedCourses as Course[]) {
      const sessionDateTime = course.associatedSession?.timeStart;
      const sessionDate = sessionDateTime?.slice(0, 10);

      if (!upcomingEventsByDate[sessionDate]) {
        let dayWeek = '';
        let isDayWeekToday = false;
        if (isToday(sessionDateTime)) {
          dayWeek = lang.TODAY;
          isDayWeekToday = true;
        } else {
          dayWeek = printDayOfWeek(new Date(sessionDateTime).getDay(), lang);
        }

        upcomingEventsByDate[sessionDate] = {
          isToday: isDayWeekToday,
          dayWeek: dayWeek,
          courses: [],
        };
      }
      upcomingEventsByDate[sessionDate].courses.push(course);
    }

    const ContentCarousel = Object.keys(upcomingEventsByDate)
      .sort((a, b) => a.localeCompare(b)) //most recent date at first
      .map((date, i) => (
        <React.Fragment key={date}>
          {upcomingEventsByDate[date].courses.map((course, index) => (
            <div
              className={
                'date-label-content ' +
                (index + 1 === upcomingEventsByDate[date].courses.length ? 'lastUpcoming' : '') +
                ' upcomingGrid'
              }
              key={course.courseIdMaster}
            >
              <section className="date-label">
                {index === 0 && (
                  <>
                    <span
                      className={
                        'date-label-dayweek ' +
                        (upcomingEventsByDate[date].isToday ? 'is-today' : '')
                      }
                    >
                      {upcomingEventsByDate[date].dayWeek}
                    </span>
                    {' - ' + printDate(date, lang)}
                  </>
                )}
              </section>
              {printCourse(course)}
            </div>
          ))}
        </React.Fragment>
      ));

    if (useCarousel) {
      return (
        <div className="carousel-section carousel-wrapper section variable-width">
          <CarouselVariableWidth
            ContentCarousel={ContentCarousel}
            SkeletonCarousel={[...Array(5)].map(a => printSkeleton())}
            ShowMoreButton={null}
            variant={'card-evolution'}
            isLoading={useSkeleton}
          />
        </div>
      );
    } else {
      return ContentCarousel;
    }
  };

  const printPastEventsList = () => {
    // console.log("pastEventsByDate", displayedCourses);
    const pastEventsByDate = {};

    const displayedCoursesCopy = [...displayedCourses];

    const displayedCoursesSorted = _.orderBy(
      displayedCoursesCopy,
      ['associatedSession.timeStart'],
      ['desc']
    );

    // console.log("DISPLAY", displayedCoursesSorted);

    for (let course of displayedCoursesSorted as Course[]) {
      const sessionDateTime = course.associatedSession?.timeStart; //course?.startDate;
      const sessionDate = sessionDateTime?.slice(0, 10);

      if (!pastEventsByDate[sessionDate]) {
        let dayWeek = '';
        let isDayWeekToday = false;
        if (isToday(sessionDateTime)) {
          dayWeek = lang.TODAY;
          isDayWeekToday = true;
        } else {
          dayWeek = printDayOfWeek(new Date(sessionDateTime).getDay(), lang);
        }

        pastEventsByDate[sessionDate] = {
          isToday: isDayWeekToday,
          dayWeek: dayWeek,
          courses: [],
        };
      }
      pastEventsByDate[sessionDate].courses.push(course);
    }
    // console.log("pastEventsByDate", pastEventsByDate);

    return Object.keys(pastEventsByDate).map(date => (
      <React.Fragment key={date}>
        {pastEventsByDate[date].courses.map((course, index) => (
          <div
            className={
              'date-label-content ' +
              (index + 1 === pastEventsByDate[date].courses.length ? 'lastPast' : '' + ' pastGrid')
            }
            key={course.courseIdMaster}
          >
            <section className="date-label">
              {index === 0 && (
                <>
                  <span
                    className={
                      'date-label-dayweek ' + (pastEventsByDate[date].isToday ? 'is-today' : '')
                    }
                  >
                    {pastEventsByDate[date].dayWeek}
                  </span>
                  {' - ' + printDate(date, lang)}
                </>
              )}
            </section>
            {printCourse(course)}
          </div>
        ))}
      </React.Fragment>
    ));
  };

  const handleViewAll = () => {
    history.push(viewAllTo);
  };

  const hasMoreCards = !!(displayedCourses?.length > pagination);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={'courses-results-v3 ' + className} id="main">
      {!hideTitle && (
        <div className="carousel-section carousel-wrapper section variable-width carousel-max-width">
          <CarouselTitle
            className="upcoming-events-carousel"
            hideTitle={hideTitle}
            title={title}
            showPlusButton={hasMoreCards || isPlusIconVisible}
            handleViewAll={handleViewAll}
          />
        </div>
      )}
      <div className="courses-results-v3__list-wrapper" role='region' aria-label='courses-results-list'>
        <div
          className={clsx('courses-results-v3__list', {
            'courses-results-v3__list--completed max-width-620': isCompletedCourse,
            medium: breakpoint.isPhone,
            big: !breakpoint.isPhone,
            'courses-results-v3__list-empty': isEmpty || isEmptyWishlist,
            'training-pills': isTP && !isEmptyWishlist,
            loading: isLoading,
            ['courses-results-v3__list--upcoming-events tab-' + upcomingEventsTab]:
              upcomingEventsTab,
            ['courses-results-v3__list--card-variant-' + cardVariant]: cardVariant,
            'as-carousel-desktop': asCarouselInDesktop,
            isSearchPage: !!isSearch,
          })}
        >
          {isLoading &&
            (!!upcomingEventsTab
              ? printUpcomingEventsList(true, asCarouselInDesktop)
              : [...Array(12)].map((_, i) => (
                  <div className="item" key={i}>
                    {printSkeleton()}
                  </div>
                )))}

          {!isLoading && (
            <>
              {
                displayedCourses?.length > 0 &&
                  (upcomingEventsTab === UpcomingEventsTabEnum.upcoming
                    ? printUpcomingEventsList(false, asCarouselInDesktop)
                    : upcomingEventsTab === UpcomingEventsTabEnum.past
                    ? printPastEventsList()
                    : displayedCourses.map((course, i) => (
                        <div className={clsx("item",{
                          'course-results-v3--disabled': isCourseComingSoon(course) && isCourseGuru(course)
                          })} key={`card.courseId_${i}`}>
                          {printCourse(course)}
                        </div>
                      )))
                /* displayedCourses.map((card, i) => (
                    <div className="item" key={`card.courseId_${i}`}>
                      {printCourse(card)}
                    </div>
                  )) 
                  printPastEventsList()
                  :
                  printUpcomingEventsList()*/
              }
              {isEmptyWishlist && (
                <div className="courses-results-v3__empty-wishlist">
                  <div className="courses-results-v3__empty-wishlist__text">
                    {lang.EMPTY_WISHLIST_MESSAGE}
                  </div>
                  <span>
                    <WishlistIcon />
                  </span>
                </div>
              )}
              {isEmpty && (
                <div className="courses-results-v3__empty-wishlist">
                  <HotAirBaloon lang={lang} history={history as any} showCta={false} />
                  <div className="courses-results-v3__empty-wishlist__text">{lang.NO_RESULTS}</div>
                </div>
              )}
            </>
          )}
        </div>
        <section
          className="results__pagination flex column justify-center align-center"
          ref={ref}
          aria-label={
            filteredCourses?.length > 0
              ? lang.RESULTS_LABEL?.replace('{0}', displayedCourses.length.toFixed())?.replace(
                  '{1}',
                  filteredCourses.length.toFixed()
                )
              : ''
          }
          aria-hidden={!filteredCourses?.length}
        >
          {!isEmptyWishlist && filteredCourses && filteredCourses.length > 0 && (
            <>
              {nToLoad() > 0 && <Spinner className="loading-spinner" />}
              {!hideResultsNumber && (
                <div>
                  <p className="load-more-label text-center">
                    {lang.RESULTS_LABEL.replace('{0}', displayedCourses.length.toFixed()).replace(
                      '{1}',
                      filteredCourses.length.toFixed()
                    )}
                  </p>
                </div>
              )}
            </>
          )}
        </section>
      </div>
    </div>
  );
};

export default CoursesResults;
