import React from 'react';
import { printLink, printPublicLink } from './link-utils';
import { useLocation } from 'react-router-dom';
import { isExternalUser, isMYLUser, isQualityEnv } from '@utility/Api';
import { useSelector } from '@hooks/redux';
import { USER_URLS } from './link-utils';
import ButtonV2 from '@components/UI/ButtonV2';
import { UserProfile } from '@model/User';
import ModalRight from '@components/UI/ModalRight';
import CustomScrollbarV3 from './UI/CustomScrollbarV3';
import { selectIsUserEnabledForTrainingPills } from '@utility/TrainingPillsUtility';
import TagBadge from './UI/TagBadge';
import { hasUserActivePlan, isUserEl360 } from '@utility/userUtility';

type Props = {
  closeMenu: () => void;
  isAuthenticated: boolean;
  show: boolean;
  logout: () => void;
  isManager: boolean;
  isTeacher: boolean;
  userProfile: UserProfile;
};

const Menu = ({
  closeMenu,
  isAuthenticated,
  logout,
  isManager = false,
  isTeacher = false,
  show,
}: Props) => {
  const lang = useSelector(state => state.utils.lang);
  const location = useLocation();
  const isUserEnabledForTrainingPills = useSelector(selectIsUserEnabledForTrainingPills);
  const ecommerceEnable = useSelector(state => state.user.ecommerceEnable);
  const userProfile = useSelector(state => state.user.userProfile);
  const userEl360 = isUserEl360(userProfile);
  const isUserPlanActive = hasUserActivePlan(userProfile);

  const handleShowCurrentPage = (event, page: keyof typeof USER_URLS) => {
    if (location.pathname === USER_URLS[page].URL && show) {
      closeMenu();
    }
  };
  return (
    <ModalRight
      show={show}
      close={closeMenu}
      eventCloseOnClick={closeMenu}
      closeAriaLabel={lang?.CLOSE_NAVIGATION_MENU}
      blur="desktop"
      contentClassName="modal__content"
      className="menu-modal-right"
      animateSlide
      ignoreBlock={true}
    >
      <div className="header-v3__menu" onClick={e => e.stopPropagation()}>
        {(userEl360 || isUserPlanActive) &&
          <div className={"header-v3" + (userEl360 ? "__el360" : "__active-plan")}>
            <span>{userEl360 ? lang.EL360_LABEL : lang.ECOMM16_LABEL}</span>
            <TagBadge variant='white-border' label={userEl360? lang.EL360_ACTIVE : lang.ECOMM16_ACTIVE} />
          </div>}
        <nav className="header-v3__links" aria-label={lang?.MENU}>
          <ul className="header-v3__navigation" role="list" aria-label={lang?.MENU}>
            {isAuthenticated ? (
              <>
                <li
                  className="header-v3__item-head first"
                  onClick={event => handleShowCurrentPage(event, 'PROFILE')}
                >
                  {printLink('PROFILE', lang, '', true)}
                </li>
                {userProfile && !isExternalUser(userProfile) && !isMYLUser(userProfile) && (
                  <li
                    className="header-v3__item-head"
                    onClick={event => handleShowCurrentPage(event, 'PROFILE_TO_DO')}
                  >
                    {printLink('PROFILE_TO_DO', lang, '', true)}
                  </li>
                )}
                <li
                  className="header-v3__item-head"
                  onClick={event => handleShowCurrentPage(event, 'PROFILE_MY_PROGRESS')}
                >
                  {printLink('PROFILE_MY_PROGRESS', lang, '', true)}
                </li>
                <li
                  className="header-v3__item-head"
                  onClick={event => handleShowCurrentPage(event, 'PROFILE_MY_CERTIFICATES')}
                >
                  {printLink('PROFILE_MY_CERTIFICATES', lang, '', true)}
                </li>

                {isUserEnabledForTrainingPills && (
                  <li
                    className="header-v3__item-head"
                    onClick={event => handleShowCurrentPage(event, 'LATEST_COLLECTIONS')}
                  >
                    {printLink('SUBCATALOGUE_TP', lang, '', true)}
                  </li>
                )}

                {isManager && (
                  <li
                    className="header-v3__item-head"
                    onClick={event => handleShowCurrentPage(event, 'PROFILE_MY_TEAM')}
                  >
                    {printLink('PROFILE_MY_TEAM', lang, '', true)}
                  </li>
                )}

                {isTeacher && (
                  <li
                    className="header-v3__item-head"
                    onClick={event => handleShowCurrentPage(event, 'CATALOGUE')}
                  >
                    <a
                      target="_blank"
                      href={
                        isQualityEnv()
                          ? 'https://qu-backend.leonardo.essilorluxottica.com'
                          : 'https://backend.leonardo.essilorluxottica.com'
                      }
                    >
                      {lang.TEACHER_BO}
                    </a>
                  </li>
                )}

                <hr className="header-v3__items-hr" />
                {ecommerceEnable && (
                  <li
                    className="header-v3__item"
                    onClick={event => handleShowCurrentPage(event, 'PROFILE_ORDER_HISTORY')}
                  >
                    {printLink('PROFILE_ORDER_HISTORY', lang, '', true)}
                  </li>
                )}

                {ecommerceEnable && (
                  <li
                    className="header-v3__item"
                    onClick={event => handleShowCurrentPage(event, 'PROFILE_ACTIVATE_LICENSE')}
                  >
                    {printLink('PROFILE_ACTIVATE_LICENSE', lang, '', true)}
                  </li>
                )}

                {/* <li
                  className="header-v3__item"
                  onClick={event => handleShowCurrentPage(event, 'HOWTO')}
                >
                  {printLink('HOWTO', lang, '', true)}
                </li> */}

                <li
                  className="header-v3__item"
                  onClick={event => handleShowCurrentPage(event, 'CONTACT_US')}
                >
                  {printLink('CONTACT_US', lang, '', true)}
                </li>

                <li
                  className="header-v3__item"
                  onClick={event => handleShowCurrentPage(event, 'PROFILE_SETTINGS')}
                >
                  {printLink('PROFILE_SETTINGS', lang, '', true)}
                </li>

                <li className="header-v3__item logout">
                  <ButtonV2 handleClick={logout} variant="text-btn" small>
                    {lang.LOG_OUT}
                  </ButtonV2>
                </li>
                {/* {userProfile?.linkIntranet &&
                                    <div className="link-intranet">
                                        <a onClick={goToIntranet} onKeyDown={(event) => goToIntranet()} tabIndex={0}> {lang.BUTTON_INTRANET_MENU} <strong>{lang.BUTTON_INTRANET_MENU2}</strong></a>
                                    </div>
                                } */}
                <br />
              </>
            ) : (
              <>
                <li
                  className="header-v3__item"
                  onClick={event => handleShowCurrentPage(event, 'HOMEPAGE')}
                >
                  {printPublicLink('HOMEPAGE')}
                </li>
                <li
                  className="header-v3__item"
                  onClick={event => handleShowCurrentPage(event, 'CATALOGUE')}
                >
                  {printPublicLink('CATALOGUE')}
                </li>
                <li
                  className="header-v3__item"
                  onClick={event => handleShowCurrentPage(event, 'LOGIN')}
                >
                  {printPublicLink('LOGIN')}
                </li>
                <li
                  className="header-v3__item"
                  onClick={event => handleShowCurrentPage(event, 'CONTACT_US')}
                >
                  {printPublicLink('CONTACT_US')}
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
    </ModalRight>
  );
};

export default Menu;
