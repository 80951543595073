import React, { useEffect, useState } from 'react';
import { useSelector } from '@hooks/redux';
import useL1List from '@hooks/useL1List';
import ButtonV2 from '@components/UI/ButtonV2';
import LazySVG from '@components/LazySvg';
import ChevronLeft from '@images/svg-icons/chevron-left-header.svg';
import useModalTypes from '@hooks/useModalByType';
import { motion } from 'framer-motion';
import { getBrandPageUrl, isCourseVisible, isHighlighted } from '@utility/Api';
import CardController from '@components/CardV3';
import { Course } from '@model/CoursesClass';
import { HighlightsThirdColumn } from '@components/collections/CollectionsMenu';
import { multipleSortCourses } from '@utility/sortUtility';
import { SORTBY } from '@utility/const';

const variants = {
  initial: {
    x: '90vw',
  },
  enter: {
    x: '0',
  },
  exit: {
    x: '100vw',
  },
};

const MenuAreaOfExpertiseL1Mobile = ({ show }) => {
  const lang = useSelector(state => state.utils.lang);
  const modalTypes = useModalTypes();
  const [l1List, l0list, _, __, loadingL1List] = useL1List('');
  const [coursesToShow, setCoursesToShow] = useState([]);
  const areaOfExpertiseL1MenuL0Id = useSelector(state => state.utils.areaOfExpertiseL1MenuL0Id);
  const coursesMap = useSelector(state => state.course.coursesMap);

  const [l0, setL0] = useState(null);

  useEffect(() => {
    let coursesToShowTemp = [];

    if (coursesMap) {
      coursesToShowTemp = Object.values(coursesMap).filter(
        a => isCourseVisible(a) && isHighlighted(a)
      );

      if (l0) {
        //filter by L0 if selected
        coursesToShowTemp = coursesToShowTemp.filter(a => a.level0 === l0.code);
      } else {
        //exclude l0 with order > 100
        let listOfCode = l0list?.map(a => a?.code);
        coursesToShowTemp = coursesToShowTemp.filter(a => listOfCode?.includes(a?.level0));
      }

      //sort by start date, if same start date --> sort by title
      coursesToShowTemp = multipleSortCourses(coursesToShowTemp, [
        SORTBY.TITLE_AZ,
        SORTBY.START_DATE,
      ]).slice(0, 3);
    }

    setCoursesToShow(coursesToShowTemp);
  }, [l0, coursesMap]);

  useEffect(() => {
    if (areaOfExpertiseL1MenuL0Id) {
      const l0Temp = l0list?.find(a => a.code === areaOfExpertiseL1MenuL0Id);
      setL0(l0Temp);
    }
  }, [l0list, areaOfExpertiseL1MenuL0Id]);

  const handleClickBackButton = () => {
    if (l0.order < 100) {
      modalTypes.areaOfExpertiseMenu.open();
    } else {
      modalTypes.collectionsMenu.open();
    }
  };

  const closeModal = () => {
    modalTypes.close();
  };

  return (
    show && (
      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        transition={{ ease: 'easeInOut', duration: 0.3 }}
        variants={variants}
        className="menu-aoe-mobile menu-aoe-mobile__l1-modal"
      >
        <div className={'menu-aoe-mobile__content absolute'}>
          <header onClick={handleClickBackButton}>
            <button
              className="icon-button back-button header-v3__back-button focus-outline mobile"
              title={lang?.GO_BACK}
              aria-label={lang?.GO_BACK}
              onClick={handleClickBackButton}
              //autoFocus
            >
              <ChevronLeft />
            </button>
            {l0?.label}
          </header>
          <section className="menu-aoe-mobile__container" aria-label={lang.AREAS_OF_EXPERTISE}>
            <div className="menu-aoe-mobile__l0-list">
              {l1List?.[l0?.code]?.map(l1 => (
                <ButtonV2
                  className={'menu-aoe-mobile__l1-list'}
                  to={getBrandPageUrl(l1.type, l1.value)}
                  handleClick={closeModal}
                  key={l1.value}
                >
                  {l1.label}
                </ButtonV2>
              ))}
            </div>
          </section>
          {coursesToShow?.length === 3 && (
            <div className="menu-aoe-mobile__bottom-section__threeCards">
              <ThirdColumn courses={coursesToShow} isDesktop={true} closeModal={closeModal} />
            </div>
          )}
          {coursesToShow?.length === 2 && (
            <div className="menu-aoe-mobile__bottom-section__twoCards">
              <ThirdColumn courses={coursesToShow} isDesktop={true} closeModal={closeModal} />
            </div>
          )}
          {coursesToShow.length !== 3 && coursesToShow.length !== 2 && (
            <div className="menu-aoe-mobile__bottom-section">
              <ThirdColumn courses={coursesToShow} isDesktop={true} closeModal={closeModal} />
            </div>
          )}
        </div>
      </motion.div>
    )
  );
};

const ThirdColumn = ({
  courses,
  isDesktop,
  closeModal,
}: {
  courses: Course[];
  isDesktop: boolean;
  closeModal: () => void;
}) =>
  isDesktop ? (
    <>
      {courses?.map(course => (
        <CardController
          course={course}
          variant={'card-areaofexpertise'}
          key={course.courseIdMaster}
          elementPosition={0}
        />
      ))}
    </>
  ) : (
    <HighlightsThirdColumn courses={courses} closeModal={closeModal} />
  );

export default MenuAreaOfExpertiseL1Mobile;
