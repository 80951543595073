import React from 'react';
import { useTranslation } from 'react-i18next';
import Select, { Props as SelectProps } from '@components/UI/Select';
import { useSelector } from '@hooks/redux';
import useLanguageSelection from '@hooks/useLanguageSelection';

export type Props = Omit<SelectProps, 'isLoading' | 'options' | 'onChange' | 'value'>;

const LanguageSelect = (props: Props) => {
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
  const { i18n, t } = useTranslation();

  const [languages, selectedLanguage, setLanguage] = useLanguageSelection(); //LOGGED LANGUAGES
  const NotLoggedLanguages = {
    //NOT LOGGED LANGUAGES
    en: { value: 'en', label: 'English' },
    it: { value: 'it', label: 'Italiano' },
    es: { value: 'es', label: 'Español' },
    pt_br: { value: 'pt_br', label: 'Português' },
    fr: { value: 'fr', label: 'Français' },
    //TICKET LEON-6710 REMOVING LANGUAGE FROM PRELOGIN PAGE
    //zh_cn: { value: 'zh_cn', label: '中文（简体)' },
  };

  const changeLanguage = language => {
    isAuthenticated ? setLanguage(language) : i18n.changeLanguage(language.value);
  };

  return (
    <Select
      labelFixedTop={true}
      options={isAuthenticated ? languages : Object.values(NotLoggedLanguages)}
      onChange={option => changeLanguage(option)}
      value={
        isAuthenticated
          ? selectedLanguage
          : NotLoggedLanguages[i18n.language] || NotLoggedLanguages['en']
      }
      removeLeftPadding
      isSearchable
      aria-label={props.ariaLabel && props.ariaLabel}
      // placeholder={props.ariaLabel && props.ariaLabel}
      fillSpace
      {...props}
    />
  );
};

LanguageSelect.displayName = 'LanguageSelect';

export default LanguageSelect;
