import React from 'react';
import LanguageSelect from './UI/LanguageSelect';
import { useTranslation } from 'react-i18next';
import { printLink, printPublicLinkTranslParam } from './link-utils';
import { useSelector } from '@hooks/redux';
import { useDispatch } from 'react-redux';
import * as actions from '@redux-actions/';
import AddToHomescreenPrompt from './AddToHomescreenPrompt';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';
import ButtonV2 from './UI/ButtonV2';
import Accordion from './UI/Accordion';
import { selectIsUserEnabledForTrainingPills } from '@utility/TrainingPillsUtility';
import { LangMap, LangMapWcag } from '@model/CoursesClass';
import { useAddToHomescreenPrompt } from '@hooks/useAddToHomescreenPrompt';
import VSLogoFooter from '@images/logo-app/Logo_Leonardo.svg';
import useLangAuth from '@hooks/useLangAuth';
import LazySVG from './LazySvg';
import { userRoleIsVS } from '@utility/Api';
import LinkedInTag from 'react-linkedin-insight';
import KEPLRLogoFooter from '@images/logo-app/Logo_Leonardo.svg';

import i18n from 'i18next';

const PARTITA_IVA = '10182640150';
const socialNetworksData = [
  {
    name: 'Instagram',
    acronym: 'IG',
    link: 'https://www.instagram.com/leonardoessilorluxottica/',
    iconSrc: '/images/svg-icons/instagram-white.svg',
  },
  {
    name: 'Facebook',
    acronym: 'FB',
    link: 'https://www.facebook.com/LeonardoEssilorLuxottica',
    iconSrc: '/images/svg-icons/facebook-white.svg',
  },
  {
    name: 'Linkedin',
    acronym: 'IN',
    link: ' https://www.linkedin.com/showcase/leonardoessilorluxottica',
    iconSrc: '/images/svg-icons/linkedin.svg',
  },
];

type Props = {
  showBottomMessage: boolean;
  isModal?: boolean;
};

const langKeys = ['COPYRIGHT', 'PIVA'];

const Footer = ({ showBottomMessage, isModal }: Props) => {
  const lang: LangMap & LangMapWcag = useSelector(state => state.utils.lang);
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
  const isUserEnabledForTrainingPills = useSelector(selectIsUserEnabledForTrainingPills);
  const ecommerceEnable = useSelector(state => state.user.ecommerceEnable);
  const userProfile = useSelector(state => state.user.userProfile);
  const year = new Date();
  const { t } = useTranslation();
  const labels = useLangAuth(langKeys);
  const dispatch = useDispatch();
  const breakpoint = useBreakpointBoolean();
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const goToIntranet = () => window.open(userProfile.linkIntranet);

  const partnerId = '5951521';
  let conversionId: any;
  // const disabled = !userProfile.allowsThirdPartyCookies();

  LinkedInTag.init(partnerId, 'dc', //disabled
  );
  LinkedInTag.track(conversionId);

  return (
    <footer className={'footer-v3' + (isModal ? ' is-modal' : '')} role="contentinfo">
      <div className="footer-v3__box">
        {/* LINKS - DESKTOP */}
        {breakpoint.isDesktop ? (
          <>
            {/* <div className='footer-section'> //DISABILITATO FINO AD APERTURA CR
							<div className='title'>{lang.FOOTER_ABOUT_US}</div>
							<div className='link-container'>
								{isAuthenticated ? (
									<>
										<div className='footer-item'>{printLink('CATALOGUE_L1', lang)}</div>
										<div className='footer-item'>{printLink('CATALOGUE_L1', lang)}</div>
										<div className='footer-item'>{printLink('CATALOGUE_L1', lang)}</div>
									</>
								) : (
									<>
										<div className='footer-item'>{printPublicLinkTranslParam('CONTACT_US', t)}</div>
										<div className='footer-item'>{printPublicLinkTranslParam('CONTACT_US', t)}</div>
										<div className='footer-item'>{printPublicLinkTranslParam('CONTACT_US', t)}</div>
									</>
								)}
							</div>
						</div> */}

            <div className="footer-section">
              <div className="title">{isAuthenticated ? lang?.FOOTER_HELP_INFO : (t('FOOTER_HELP_INFO'))}</div>
              <div className="link-container">
                {isAuthenticated ? (
                  <>
                    <div className="footer-item">{printLink('CONTACT_US', lang)}</div>
                    <div className="footer-item">{printLink('LEGAL', lang)}</div>
                    {/* <div className="footer-item">{printLink('HOWTO', lang)}</div> */}
                    <div className="footer-item">{printLink('MANAGE_COOKIES', lang)}</div>
                    <div className="footer-item">
                      <a href="https://media.leonardo.essilorluxottica.com/doc/ecommerce/US%20Luxottica%20Privacy%20Policy%20%20%E2%80%93%2011.2021%20Final.pdf#page=11&&zoom=100,0,600">
                        {lang.CALIFORNIA_NOTICE}
                      </a>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="footer-item">
                      {printPublicLinkTranslParam(
                        'CONTACT_US',
                        t,
                        false,
                        'footer_menuLink_contactus'
                      )}
                    </div>
                    <div className="footer-item">
                      {printPublicLinkTranslParam('LEGAL', t, false, 'footer_menuLink_legal')}
                    </div>
                    <div className="footer-item">
                      {printPublicLinkTranslParam(
                        'MANAGE_COOKIES',
                        t,
                        false,
                        'footer_menuLink_contactus'
                      )}
                    </div>
                    <div className="footer-item">
                      <a href="https://media.leonardo.essilorluxottica.com/doc/ecommerce/US%20Luxottica%20Privacy%20Policy%20%20%E2%80%93%2011.2021%20Final.pdf#page=11&&zoom=100,0,600">
                        {t('CALIFORNIA_NOTICE')}
                      </a>
                    </div>
                  </>
                )}
              </div>
            </div>

            {isAuthenticated && (isUserEnabledForTrainingPills || ecommerceEnable) && (
              <div className="footer-section">
                <div className="title">{lang.FOOTER_USEFUL_LINKS}</div>
                <div className="link-container">
                  {isUserEnabledForTrainingPills && (
                    <div className="footer-item">{printLink('SUBCATALOGUE_TP', lang)}</div>
                  )}
                  {ecommerceEnable && (
                    <div className="footer-item">{printLink('PROFILE_ACTIVATE_LICENSE', lang)}</div>
                  )}
                  {ecommerceEnable && (
                    <div className="footer-item">{printLink('PROFILE_ORDER_HISTORY', lang)}</div>
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {/* LINKS - MOBILE */}
            {/* <div className='footer-section'> //DISABILITATO FINO AD APERTURA CR
							<Accordion
								title={lang.FOOTER_ABOUT_US}
								className='footer-item'
								hideTopBorder
								variant='gradient'
								toggleDefault={false}
								loadChildrenAfterExpansion
								titleClasses='title'
							>
								<div className='link-container'>
									{isAuthenticated ? (
										<>
											<div className='description'>{printLink('CATALOGUE_L1', lang, '', false, true)}</div>
											<div className='description'>{printLink('CATALOGUE_L1', lang, '', false, true)}</div>
											<div className='description'>{printLink('CATALOGUE_L1', lang, '', false, true)}</div>
										</>
									) : (
										<>
											<div className='description'>{printPublicLinkTranslParam('CONTACT_US', t, true)}</div>
											<div className='description'>{printPublicLinkTranslParam('CONTACT_US', t, true)}</div>
											<div className='description'>{printPublicLinkTranslParam('CONTACT_US', t, true)}</div>
										</>
									)}
								</div>
							</Accordion>
						</div> */}

            <div className="footer-section">
              <Accordion
                title={isAuthenticated ? lang?.FOOTER_HELP_INFO : t("FOOTER_HELP_INFO")}
                className="footer-item"
                hideTopBorder
                variant="gradient"
                toggleDefault={false}
                loadChildrenAfterExpansion
                titleClasses="title"
              >
                <div className="link-container">
                  {isAuthenticated ? (
                    <>
                      <div className="description">
                        {printLink('CONTACT_US', lang, '', false, true)}
                      </div>
                      <div className="description">{printLink('LEGAL', lang, '', false, true)}</div>
                      {/* <div className="description">{printLink('HOWTO', lang, '', false, true)}</div> */}
                      <div className="description"> {printLink('MANAGE_COOKIES', lang)}</div>
                      <div className="description">
                        <a href="https://media.leonardo.essilorluxottica.com/doc/ecommerce/US%20Luxottica%20Privacy%20Policy%20%20%E2%80%93%2011.2021%20Final.pdf#page11">
                          {lang.CALIFORNIA_NOTICE}
                        </a>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="description">
                        {printPublicLinkTranslParam(
                          'CONTACT_US',
                          t,
                          false,
                          'footer_menuLink_contactus'
                        )}
                      </div>
                      <div className="description">
                        {printPublicLinkTranslParam('LEGAL', t, false, 'footer_menuLink_legal')}
                      </div>
                      <div className="description">
                        {printPublicLinkTranslParam(
                          'MANAGE_COOKIES',
                          t,
                          false,
                          'footer_menuLink_contactus'
                        )}
                      </div>
                      <div className="description">
                        <a href="https://media.leonardo.essilorluxottica.com/doc/ecommerce/US%20Luxottica%20Privacy%20Policy%20%20%E2%80%93%2011.2021%20Final.pdf#page11">
                          {t('CALIFORNIA_NOTICE')}
                        </a>
                      </div>
                    </>
                  )}
                </div>
              </Accordion>
            </div>

            {isAuthenticated && (isUserEnabledForTrainingPills || ecommerceEnable) && (
              <div className="footer-section">
                <Accordion
                  title={lang.FOOTER_USEFUL_LINKS}
                  className="footer-item"
                  hideTopBorder
                  variant="gradient"
                  toggleDefault={false}
                  loadChildrenAfterExpansion
                  titleClasses="title"
                >
                  <div className="link-container">
                    {isUserEnabledForTrainingPills && (
                      <div className="description">
                        {printLink('SUBCATALOGUE_TP', lang, '', false, true)}
                      </div>
                    )}
                    {ecommerceEnable && (
                      <div className="description">
                        {printLink('PROFILE_ACTIVATE_LICENSE', lang, '', false, true)}
                      </div>
                    )}
                    {ecommerceEnable && (
                      <div className="description">
                        {printLink('PROFILE_ORDER_HISTORY', lang, '', false, true)}
                      </div>
                    )}
                  </div>
                </Accordion>
              </div>
            )}
          </>
        )}

        {/* INTRANET */}
        {isAuthenticated && userProfile?.linkIntranet && (
          <div
            className={`footer-section ${!isAuthenticated || !userProfile?.linkIntranet ? 'is-not-authenticated' : ''
              }`}
          >
            {breakpoint.isDesktop ? (
              <>
                <div className="title">{lang.FOOTER_INTRANET}</div>
                <div className="intranet-description">{lang.FOOTER_INTRANET_DESCR}</div>
                <ButtonV2 handleClick={goToIntranet} variant="text-btn" small tabIndex={0}>
                  {lang.FOOTER_INTRANET_CTA}
                </ButtonV2>
              </>
            ) : (
              <div className="footer-section">
                <Accordion
                  title={lang.FOOTER_INTRANET}
                  className="footer-item"
                  hideTopBorder
                  variant="gradient"
                  toggleDefault={false}
                  loadChildrenAfterExpansion
                  titleClasses="title"
                >
                  <div className="link-container">
                    <span className="intranet-description">{lang.FOOTER_INTRANET_DESCR}</span>
                    <ButtonV2 handleClick={goToIntranet} variant="secondary" small tabIndex={0}>
                      {lang.FOOTER_INTRANET_CTA}
                    </ButtonV2>
                  </div>
                </Accordion>
              </div>
            )}
          </div>
        )}

        {!breakpoint.isDesktop && (
          <div className="footer-section">
            <Accordion
              title={lang.LEGAL_DISCLAIMER_SECTION_TITLE}
              className="footer-item"
              hideTopBorder
              variant="gradient"
              toggleDefault={false}
              loadChildrenAfterExpansion
              titleClasses="title"
            >
              <div className="link-container">
                <span className="legal-disclaimer-text">{lang?.LEGAL_DISCLAIMER}</span>
              </div>
            </Accordion>
          </div>
        )}
        {/* LEONARDO DESCRIPTION */}
        {!isAuthenticated && <div className="leonardo-description">{t('FOOTER_MESSAGE')}</div>}

        {/* SOCIAL NETWORKS & LANGUAGES */}
        <div className='social-languages-bg'>
          <div className="social-languages-container">
            {breakpoint.isDesktop && <div className="title">{lang.FOOTER_SOCIAL}</div>}
            <div className="social-networks">
              {socialNetworksData.map(social => (
                <ButtonV2
                  className="social"
                  variant="icon-btn"
                  small
                  handleClick={() => window.open(social.link, '_blank')}
                  ariaLabel={social.name}
                  key={social.acronym}
                  data-element-id={'footer_share'}
                  data-description={social.name}
                >
                  <LazySVG src={social.iconSrc} alt={social.acronym} />
                </ButtonV2>
              ))}
            </div>
            <LanguageSelect
              className="languages"
              iconSvg={{
                src: '/images/svg-icons/planet-earth.svg',
                alt: 'earth',
              }}
              ariaLabel={t('SELECT_LANGUAGE_SELECT')}
              id="select-language-select"
            />
          </div>
        </div>
      </div>

      {!isAuthenticated && (breakpoint.isDesktop || breakpoint.isTablet) && prompt && (
        <div className="banner-bg-container">
          <div className="banner-bg" />
        </div>
      )}

      {/*LEGAL DISCLAIMER*/}
      {breakpoint.isDesktop && (
        <div className="legal-disclaimer">
          <span className="legal-disclaimer-text">{lang?.LEGAL_DISCLAIMER}</span>
        </div>
      )}


      {/* COPYRIGHT & BANNER */}
      <div className="footer-v3__legal">
        {!userRoleIsVS(userProfile?.extRole) && (
          <div
            className={`banner ${!isAuthenticated ? 'is-not-authenticated' : ''} ${!prompt ? 'hide-element' : ''
              }`}
          >
            <AddToHomescreenPrompt
              showAddToHomescreenPromptLogin={false}
              showAddToHomescreenPromptFooter={true}
            />
          </div>
        )}
        <div className={`copyright ${!isAuthenticated ? 'is-not-authenticated' : ''}`}>
          <span>{labels?.COPYRIGHT?.replace('{{year}}', year.getFullYear())} </span>
          <span>
            {labels?.PIVA} {PARTITA_IVA}
          </span>
        </div>
        {isAuthenticated && userRoleIsVS(userProfile?.extRole) && (
          <div className="logo-VS">
            <span className="poweredBy">{lang?.POWERED_BY.toUpperCase()}</span> <VSLogoFooter />
          </div>
        )}
        {isAuthenticated && !userRoleIsVS(userProfile?.extRole) && (
          <div className="logo-VS">
            <span className="poweredBy">{lang?.POWERED_BY.toUpperCase()}</span> <KEPLRLogoFooter />
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
