import LazySVG from '@components/LazySvg';
import { useSelector } from '@hooks/redux';
import { Course } from '@model/CoursesClass';
import { insertProductInCart, toggleModal } from '@redux-actions/';
import {getNextLessonWithinLP, isCompletedCourse, isCourseCertificateCtype} from '@utility/Api';
import { canCourseBeAccessed } from '@utility/ecommerceUtility';
import { getOnboardingStatistics, isCourseOnBoarding, isCourseSoldout, ValuesChart } from '@utility/onBoardingCourseUtility';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ButtonV2 from './ButtonV2';
import { useHistory } from 'react-router-dom';
import { USER_URLS } from '@components/link-utils';
import { ProductCart } from '@model/CartModel';
import { ADD_TO_CART_STEPS, courseStatus, queryParams } from '@utility/const';
import OnBoardingChart from '@components/onboarding/OnBoardingChart';
import { isCourseAssessment, isCourseComingSoon } from '@utility/CourseUtility';
import TooltipV2 from '@components/CardV3/TooltipV2';
import useDownloadCertificate from '@hooks/useDownloadCertificate';

class ButtonInfoClass {
  icon?: string = "";
  altIcon: string = "";
  typeIcon?: "play" | "bag" | "download";
  cta?: () => void;
  showChart?: boolean;
  isAssessment?: boolean;
  showDownloadCertificate?: boolean;
}

interface Props {
  className?: string;
  course: Course;
  isMandatoryCompletedCourse?: boolean;
  showTickOnCompleteLP?: boolean
  handleClickFromDetailsSingleActivityButton?: ()=>void
}

export const EducationalPathsProgramButton = ({ className = "", course, isMandatoryCompletedCourse, showTickOnCompleteLP, handleClickFromDetailsSingleActivityButton }: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const lang = useSelector((state) => state.utils.lang);
  const coursesMap = useSelector((state) => state.course.coursesMap);
  const userProfile = useSelector((state) => state.user.userProfile);
  const [productToAddCart, setProductToAddCart] = useState(null);
  const [productLoadingCart, setProductLoadingCart] = useState(false);
  const isLoadingCart = useSelector((state) => state.ecommerce.isLoadingCart);


  const [buttonInfo, setButtonInfo] = useState(new ButtonInfoClass());
  const [showTooltip, setShowTooltip] = useState(false);

  const [handleDownloadClick, shouldShowDownloadButton] = useDownloadCertificate(
    course,
    isCourseCertificateCtype(course)
  );

  useEffect(() => {
    let buttonInfoTemp = new ButtonInfoClass();

    if (course) {
      if (!canCourseBeAccessed(course)) {
        //course must be buyed to access it --> show bag icon
        buttonInfoTemp = {
          icon: '/images/svg-icons/cart-empty.svg',
          altIcon: lang.BUY_COURSE_LABEL,
          typeIcon: "bag",
          cta: () => {
            //add product to cart
            setProductToAddCart(course.courseIdMaster);
            dispatch(insertProductInCart(new ProductCart(course.courseIdMaster, 1), ADD_TO_CART_STEPS.NO_MODAL));
          },
        }

        setButtonInfo(buttonInfoTemp);
        return;
      }


      if (isCourseCertificateCtype(course)) {
        let inProgressCertificate = false;
        if(course?.certificateChartInfo) { //should always be present
          let chart = course?.certificateChartInfo;
          chart.totalCourses === chart.doneCourses 
          if(chart.totalCourses === chart.doneCourses) {
            inProgressCertificate = false
          }else {
            inProgressCertificate = true;
          } 
        }

        if (inProgressCertificate === true || (inProgressCertificate === false && isMandatoryCompletedCourse === false)) {
          buttonInfoTemp = {
            altIcon: lang.IN_PROGRESS_LABEL,
            showChart: true,
          }
          setButtonInfo(buttonInfoTemp);
          return;
        }

        //if it has been completed and mandatory is true --> show download icon
        if (inProgressCertificate === false && isMandatoryCompletedCourse === true) {
          buttonInfoTemp = {
            typeIcon: "download",
            altIcon: lang.DOWNLOAD_PDF,
            icon: '/images/svg-icons/download-certificate.svg',
            showDownloadCertificate: true,
          }
          setButtonInfo(buttonInfoTemp);
        }
        return;
      }

      const totalValues = course.onboardingChartsInfo?.total;
      if (totalValues?.inProgressCourses > 0 || totalValues?.doneCourses > 0) {
        //if course has chart info from bff --> show chart info
        buttonInfoTemp = {
          altIcon: lang.GO_TO_COURSE,
          cta: goToOnboardingNextLessonTopic,
          showChart: true,
        }

        setButtonInfo(buttonInfoTemp);
        return;
      }

      buttonInfoTemp = {
        icon: '/images/svg-icons/play-2.svg',
        altIcon: lang.GO_TO_COURSE,
        typeIcon: "play",
        isAssessment: isCourseAssessment(course),
        cta: () => {
          //open course detail modal
          isCourseAssessment(course) ?
              openAssessmentCourse()
          :
          dispatch(toggleModal(true, course));
        },
      }

      if (isCourseOnBoarding(course)) {
        //go to onboarding page
        buttonInfoTemp.cta = goToOnboardingNextLessonTopic;
      }
    }

    setButtonInfo(buttonInfoTemp);
  }, [course, lang, coursesMap]);

  const handleClick = (e) => {
    e?.stopPropagation();
    if(buttonInfo.cta) buttonInfo.cta();
  }

  useEffect(() => {

    if (isLoadingCart) {
      // debugger
      setProductLoadingCart(!!productToAddCart);
      setProductToAddCart(null);
    }
    else{
      setProductLoadingCart(false);
      setProductToAddCart(null);
    }
  }, [isLoadingCart]);


  const goToOnboardingNextLessonTopic = () => {
    //get first not completed lesson within the course
    const nextLesson = getNextLessonWithinLP(course, coursesMap, userProfile);
    let topic2 = "";
    if (nextLesson) {
      const lessonWithinLP = course.learningPath.find(a => a.parentId === nextLesson.courseIdMaster);
      //get the topic2 associatied to the first not completed lesson within the course
      topic2 = lessonWithinLP.topicKey2;
    }
    history.push(USER_URLS.ONBOARDING_COURSE_ID.URL.replace(':id', course.courseIdMaster) + '?' + queryParams.ONBOARDING_SCROLL_TO_TOPIC + '=' + topic2);
  }

  const openAssessmentCourse = () => {
    if(handleClickFromDetailsSingleActivityButton)
      handleClickFromDetailsSingleActivityButton()
  }

  return (
    <div className={"educational-paths__button " + className}>
      {((buttonInfo.isAssessment && course.userCourseStatus !== courseStatus.AVAILABLE)) ?
        <OnBoardingChart
          course={course}
          variant="assessment"
          className='role-specific-program educational-paths__chart'
          showRightSection={false}
          onClick={handleClick}
        />
        : buttonInfo.isAssessment && course.userCourseStatus === courseStatus.AVAILABLE ?
        <ButtonV2
                variant="icon-btn"
                handleClick={handleClick}
                loading={buttonInfo.typeIcon === "bag" ? productLoadingCart : false}
                ariaLabel={buttonInfo.typeIcon}
              >
                {buttonInfo.icon &&
                  <LazySVG
                    className={buttonInfo.typeIcon}
                    src={buttonInfo.icon}
                    alt={buttonInfo.altIcon}
                  />
                }
        </ButtonV2>
        :
        buttonInfo.showChart
          ?
          <OnBoardingChart
            course={course}
            variant="total"
            className='role-specific-program educational-paths__chart'
            showRightSection={false}
            onClick={handleClick}
            showTick={!!showTickOnCompleteLP}
          />
          :
          buttonInfo?.showDownloadCertificate ?
            <>
              <TooltipV2 label={lang.CTA_DOWNLOAD_CERTIFICATE} show={showTooltip} />
              <div className="educational-paths__button-wrapper"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                onFocus={() => setShowTooltip(true)}
                onBlur={() => setShowTooltip(false)}
                onMouseDown={() => setShowTooltip(prev => !prev)}
              >
                <ButtonV2
                  variant="icon-btn"
                  handleClick={handleDownloadClick}
                  loading={buttonInfo.typeIcon === "download" ? productLoadingCart : false} 
                  ariaLabel={lang.CTA_DOWNLOAD_CERTIFICATE}
                >
                  {buttonInfo.icon &&
                    <LazySVG
                      className={buttonInfo.typeIcon}
                      src={buttonInfo.icon}
                      alt={lang.CTA_DOWNLOAD_CERTIFICATE}
                    />
                  }
                </ButtonV2>
              </div>
            </>
            : !isCourseSoldout(course) ?
              <ButtonV2
                variant="icon-btn"
                handleClick={handleClick}
                loading={buttonInfo.typeIcon === "bag" ? productLoadingCart : false}
                ariaLabel={buttonInfo.typeIcon}
              >
                {buttonInfo.icon &&
                  <LazySVG
                    className={buttonInfo.typeIcon}
                    src={buttonInfo.icon}
                    alt={buttonInfo.altIcon}
                  />
                }
              </ButtonV2> : React.Fragment
      }
    </div>
  );
}

export default EducationalPathsProgramButton;