import { Course } from '@model/CoursesClass';
import { getCourseCompletionById } from '@redux-actions/';
import { isCompletedCourse, isLearningPath } from '@utility/Api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from './redux';
import { completeCertificateActivity, toggleDownloadLinkModal } from '../store/actions/coursesActions';

const getCertificateLink = (
  course: Course,
  completedCoursesById: { [key: string]: Course }
): string => {
  return (
    course?.certificateLinks?.[0] ||
    completedCoursesById?.[course?.courseIdMaster]?.certificateLinks?.[0]
  );
};

const useDownloadCertificate = (
  course: Course,
  makeCompletionsRequest: boolean = false,
  btnIdentifier?: string
): [handleDownloadClick: (e: any) => void, shouldShowDownloadButton: boolean] => {

  const [shouldShowDownloadButton, setShouldShowDownloadButton] = useState(false);
  const coursesMap = useSelector(state => state.course.coursesMap);
  const {isGettingCourseCompletionById, completedCoursesById} = useSelector(state => ({
    isGettingCourseCompletionById: state.course.isGettingCourseCompletionById,
    completedCoursesById: state.course.completedCoursesById,
  }));
  const userPreferredLang = useSelector(state => state?.user?.userProfile?.preferredLang)
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
  const [localCompletedCoursesById, setLocalCompletedCoursesById] = useState<{ [key: string]: Course }>(completedCoursesById)

  const dispatch = useDispatch();

  const handleDownloadClick = e => {  
    dispatch(toggleDownloadLinkModal());

    setLocalCompletedCoursesById(prevState => {
      const link = getCertificateLink(course, prevState);
      e?.stopPropagation();
      dispatch(toggleDownloadLinkModal(true, link));
      return prevState;
    });
  };

  useEffect(() => {
    setLocalCompletedCoursesById(completedCoursesById)
    const link = getCertificateLink(course, completedCoursesById);
    setShouldShowDownloadButton(!!link);
  }, [course, completedCoursesById]);

  useEffect(() => {
    if (
      isAuthenticated &&
      makeCompletionsRequest &&
      isCompletedCourse(course) &&
      !isGettingCourseCompletionById &&
      (userPreferredLang == course?.language || 
        (course?.language === 'en' && !course?.childCourses?.find( child => child?.language === userPreferredLang))
      ) &&
      (btnIdentifier == "onBoardingPage" || btnIdentifier == "DisplayTopDesk" )
    ) {
      if (isLearningPath(course)) {
        const certificate = course?.learningPath?.find(a => a.certificate);
        if (certificate) {
          const certificateLesson = coursesMap?.[certificate?.parentId];
          if (isCompletedCourse(certificateLesson)) {
            completeCertificateActivity(certificateLesson?.courseId);
            dispatch(getCourseCompletionById(course?.courseIdMaster));
          }
        }
      } else {
        completeCertificateActivity(course?.courseId);
        dispatch(getCourseCompletionById(course?.courseIdMaster));
      }
    }
  }, [makeCompletionsRequest, isCompletedCourse(course), course, userPreferredLang , isAuthenticated]);
    

  

  return [handleDownloadClick, shouldShowDownloadButton];
};

export default useDownloadCertificate;
