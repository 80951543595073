import React, { useState, useEffect } from 'react';
import Search from '@svg-icons/header/search.svg';
import useToggle from '@hooks/useToggle';
import clsx from '@utility/clsx';
import LazySVG from '@components/LazySvg';
import TrashIcon from '@svg-icons/trash.svg';
import AlertIcon from '@svg-icons/alert.svg';
import { DropDown } from '@components/UI/DropdownFilter';
import ArrowUp from '@svg-icons/arrowhead-up.svg';
import ArrowDown from '@svg-icons/arrowhead-down.svg';
import useOnClickOutside from '@hooks/useOnClickOutside';
import { ResponseGetProductsFilter } from '@model/ProductClass';
import { handleOnEnterKeyPress } from '@utility/Api';

type InputElement = HTMLInputElement | HTMLTextAreaElement;
type InputChangeEvent = React.ChangeEvent<InputElement>;

type TextFieldProps = {
  onChange?: (value: string) => void;
  onFocus?: React.InputHTMLAttributes<InputElement>['onFocus'];
  type?: 'email' | 'password' | 'text' | 'search' | 'number' | 'tel';
  textarea?: boolean;
  labelFixedTop?: boolean;
  maxLength?: number;
  variant?: 'standard' | 'border';
  onClickSearchButton?: () => void;
  disabled?: boolean;
  pattern?: string;
  inputMode?: string;
  disableName?: boolean;
  htmlFor?: string;
  mandatory?: boolean;
  id?: string;
  helper?: string;
  isError?: boolean;
  isValidAndDirty?: boolean;
  isDirty?: boolean;
  label?: string;
  isLicensePage?: boolean;
  isCertificateInput?: boolean;
  showBothPlaceholderAndLabel?: boolean;
} & Omit<React.InputHTMLAttributes<InputElement>, 'onChange'>;

type TextFieldSelectableProps = {
  options: ResponseGetProductsFilter[];
  placeholder: string;
  label?: string;
  helper?: string;
  onSelect?: () => void;
  onDeselect?: () => void;
  id?: string;
  htmlFor?: string;
  isError?: boolean;
  isMultiselect?: boolean;
  optionsSelected?: Array<string>;
  pushContent?: boolean;
  isQueryble?: boolean;
  placeholderSearchBox?: string;
  showPlaceholderAlways?: boolean;
  onSelectionCallback?: (options: ResponseGetProductsFilter[]) => void;
  isDisabled?: boolean;
  dropdownRelative?: boolean;
  allSelection?: string;
  isValidAndDirty?: boolean;
  isDirty?: boolean;
  fullWidthDropdown?: boolean;
  labelFixedTop?: boolean;
  showTextInput?: boolean;
  alwaysShown?: boolean;
  showPlusSymbolBeforeNumFiltersApplied?: boolean;
  isCollectionPage?: boolean;
  sortOptionsAlphabetically?: boolean
  useId?: boolean;
  isUsergroup?: boolean;
  setResetDDMValueShown?: React.Dispatch<React.SetStateAction<boolean>>;
	resetDDMValueShown?: boolean;
} & React.InputHTMLAttributes<InputElement>;

enum passwordStates {
  'text',
  'password',
}

const TextField = React.forwardRef<InputElement, TextFieldProps>(
  (
    {
      onChange,
      onFocus,
      autoComplete = 'new-password',
      textarea = false,
      type,
      name,
      placeholder,
      className,
      labelFixedTop = true,
      onClickSearchButton,
      maxLength,
      variant = 'standard',
      disabled,
      pattern,
      inputMode,
      disableName,
      htmlFor,
      mandatory = false,
      id = '',
      helper,
      isError = false,
      isValidAndDirty,
      isDirty = false,
      label = null,
      isLicensePage = false,
      isCertificateInput = false,
      showBothPlaceholderAndLabel = false,
      ...rest
    },
    ref
  ) => {
    const InputElement = textarea ? 'textarea' : 'input';
    const [passwordHidden, togglePasswordVisiblity] = useToggle(true);
    const [hasValue, setHasValue] = useState<boolean>(false);
    const [isSearch, setIsSearch] = useState<boolean>(type == 'search' ? true : false);

    const handleClearInput = () => {
      onChange('');
    };

    useEffect(() => {
      // debugger
      const el = window.document.querySelector(`[name='${name}']`);
      if (el && el.value?.length > 0) {
        setHasValue(true);
      } else setHasValue(false);
    }, []);

    return (
      <>
        <div className="form__wrapper">
          <div
            className={clsx(`form__group${variant === 'border' ? '-border' : ''} field`, {
              'text-area': textarea,
              [className]: className,
              disabled,
              mandatory: mandatory,
            })}
          >
            {isSearch && (
              <div className="wrapper-icon">
                <span onClick={() => onClickSearchButton()}>
                  <Search className="svg-icon search-icon" />
                </span>
              </div>
            )}
            <InputElement
              ref={ref as any}
              className={clsx('form__field', {
                letMeHide: className == 'letMeHide' && passwordHidden,
                'text-area': textarea,
                'fixed-top': labelFixedTop,
                labeled: placeholder !== undefined,
                'input--error': isError,
                'input--dirty-and-valid': isValidAndDirty,
                'input--dirty': isDirty
              })}
              onChange={({ target: { value } }: InputChangeEvent) => {
                if (value == '') setHasValue(false);
                else setHasValue(true);
                onChange && onChange(value);
              }}
              type={type === 'password' ? passwordStates[Number(passwordHidden)] : type}
              name={!disableName ? name : undefined}
              disabled={disabled}
              {...rest}
              autoComplete={autoComplete}
              onFocus={onFocus}
              maxLength={maxLength > 0 ? maxLength : null}
              pattern={pattern}
              inputMode={inputMode}
              id={id}
              placeholder={isSearch || !!isLicensePage || !!showBothPlaceholderAndLabel ? placeholder : ''}
            />

            {/* {isSearch && <div className='form__field_search_outline'/>} */}

            {!textarea && placeholder !== undefined && !isSearch && !isLicensePage && !showBothPlaceholderAndLabel && (
              <label
                htmlFor={htmlFor || name}
                className={clsx('form__label', {
                  'form__label--fixedTop': hasValue || labelFixedTop,
                })}
              >
                {placeholder}
              </label>
            )}
            {(isLicensePage || showBothPlaceholderAndLabel) && placeholder !== undefined &&
              <label
                htmlFor={htmlFor || name}
                className={clsx('form__label', {
                  'form__label--fixedTop': hasValue || labelFixedTop,
                })}
              >
                {label}
              </label>
            }
            {type === 'search' && <TrashIcon id="trash-icon" onClick={handleClearInput} />}
            <div className="wrapper-icon with-icon-suffix">
              {isError && <AlertIcon id="alert-icon" />}
              {className?.includes('letMeHide') && (
                <span onClick={togglePasswordVisiblity}>
                  {passwordHidden ? (
                    <LazySVG
                      src="/images/svg-icons/close-eye.svg"
                      className="svg-icon closed"
                      alt=""
                    />
                  ) : (
                    <LazySVG src="/images/svg-icons/open-eye.svg" className="svg-icon open" alt="" />
                  )}
                </span>
              )}
            </div>
          </div>
          {helper && <span className="form__helper">{helper}</span>}
        </div>
      </>
    );
  }
);

type MaskedTextFieldProps = {
  inputRef?: React.RefObject<InputElement>;
  maskChar?: string;
} & Omit<TextFieldProps, 'textarea'>;

export const MaskedTextField = React.memo((props: MaskedTextFieldProps) => {
  const { inputRef, maskChar, ...inputProps } = props;
  return <TextField ref={inputRef} {...inputProps} textarea={false} />;
});

export const TextFieldSelectable = React.forwardRef<InputElement, TextFieldSelectableProps>(
  (
    {
      options = [],
      type,
      name,
      placeholder,
      className,
      maxLength,
      disabled,
      pattern,
      inputMode,
      htmlFor,
      id,
      helper,
      isMultiselect = true,
      isError = false,
      label = null,
      pushContent = false,
      isQueryble = true,
      placeholderSearchBox,
      showPlaceholderAlways = false,
      onSelectionCallback,
      isDisabled,
      dropdownRelative = false,
      allSelection = "",
      // LEON-3690
      fullWidthDropdown = false,
      labelFixedTop = false,
      showPlusSymbolBeforeNumFiltersApplied = false,
      isCollectionPage = false,
      sortOptionsAlphabetically = false,
      useId,
      isUsergroup = false,
      showTextInput = true,
      alwaysShown = false,
      setResetDDMValueShown,
      resetDDMValueShown,
      ...rest
    },
    ref
  ) => {
    const [valueShowned, setValueShowned] = useState<string>('');
    const [optionsSelected, setOptionsSelected] = useState<ResponseGetProductsFilter[]>([]);
    const [optionsSelectedInOrder, setOptionsSelectedInOrder] = useState([]);
    const [showDropdown, setShowDropdown] = useState<boolean>(alwaysShown);
    const { clickOutsideRef } = useOnClickOutside(() => {
      setShowDropdown(false);
    });

    useEffect(() => {
      const optionsSelectedTemp = options?.filter(a => a.checked) || [];
      setOptionsSelected(optionsSelectedTemp);
    }, [JSON.stringify(options || {})]);

    useEffect(function forceRenderValueShowned() {
      if(isUsergroup && optionsSelectedInOrder) {
        setValueShowned(optionsSelectedInOrder[0]);
        if(resetDDMValueShown) {
          setValueShowned('');
        }
      }
    }, [optionsSelectedInOrder, resetDDMValueShown]);

    useEffect(() => {
      if(resetDDMValueShown) {
        setResetDDMValueShown(false)
        setOptionsSelectedInOrder([])
      }
    }, [resetDDMValueShown])

    const onSelection = (opts: Array<string>) => {
      let optionsExtended = opts.map(opt => options.find(a => a?.id === opt));
      let valueShownedTemp = (optionsExtended?.length > 0 && !showPlaceholderAlways) ? useId ? optionsExtended[0].id : optionsExtended[0].title : '';

      onSelectionCallback(optionsExtended);
      setOptionsSelected(optionsExtended);
      setValueShowned(valueShownedTemp);
    };

    const onToggleShowOptions = (e) => {
      e?.preventDefault();
      e?.stopPropagation();
      if (!alwaysShown) setShowDropdown(!showDropdown);
    };

    return (
      <>
        <div className={clsx(`form__wrapper form__wrapper--selectable`, {
          // LEON-3690
          'full-width': fullWidthDropdown
        })}
          ref={clickOutsideRef}>
          <div className={clsx(`form__group field form__field--selectable`, {
            [className]: className,
            disabled,
            'form__field--selectable--pushcontent': pushContent,
          })}>
            {showTextInput && (
              <>
                {labelFixedTop &&
                  <label htmlFor={htmlFor || name}
                    className={clsx('form__label', labelFixedTop && 'form__label--fixedTop')}>
                    {placeholder}
                  </label>
                }
                <input
                  ref={ref as any}
                  className={clsx('form__field', showDropdown && 'input--active', {
                    labeled: !!label,
                  })}
                  type={'text'}
                  name={name}
                  readOnly={true}
                  placeholder={labelFixedTop ? null : placeholder}
                  maxLength={maxLength > 0 ? maxLength : null}
                  pattern={pattern}
                  inputMode={inputMode}
                  id={id}
                  value={valueShowned}
                  {...rest}
                  onMouseDown={onToggleShowOptions} //onMouseDown instead of onClick to prevent focus
                  onKeyDown={event => { handleOnEnterKeyPress(event, onToggleShowOptions, true, true); }}
                />
              </>
            )}

            <div className="wrapper-icon with-icon-suffix" onMouseDown={onToggleShowOptions}>
              {optionsSelected.length > (showPlaceholderAlways ? 0 : 1) && !isCollectionPage
                ? (
                  <span className="filtersV3__filters-num form__field__counter">
                    {showPlusSymbolBeforeNumFiltersApplied
                      ? `+${optionsSelected.length - (showPlaceholderAlways ? 0 : 1)}`
                      : `${optionsSelected.length - (showPlaceholderAlways ? 0 : 1)}`
                    }
                  </span>
                )
                : isCollectionPage && optionsSelected.length > 1 ?
                  (
                    <span className="filtersV3__filters-num form__field__counter">
                      {`+${optionsSelected.length - 1}`}
                    </span>
                  ) : null
              }
              {isError && <AlertIcon id="alert-icon" className="svg-icon" />}

              <div className='arrow-container'>
                {showDropdown
                  ? <ArrowUp className="arrow" />
                  : <ArrowDown className="arrow" />
                }
              </div>
            </div>
            {label && (
              <label htmlFor={htmlFor || name} className="form__label">
                {label}
              </label>
            )}
          </div>
          <DropDown
            show={showDropdown || alwaysShown}
            className={dropdownRelative ? "relative" : ""}
            options={options.map(a => a.id)}
            optionsSelected={options.filter(a => a.checked).map(a => a.id)}
            optionsMap={options.reduce((accumulator, currentValue) => ({ ...accumulator, [currentValue.id]: currentValue }), {})}
            onSelection={onSelection}
            isQueryble={isQueryble}
            setOptionsSelectedInOrder={setOptionsSelectedInOrder}
            placeholderSearchBox={placeholderSearchBox}
            isMultiselect={!!isMultiselect ?? true}
            isDisabled={isDisabled}
            allSelection={allSelection}
            sortOptionsAlphabetically={sortOptionsAlphabetically}
            useId={useId}
          />
          {helper && <span className="form__helper">{helper}</span>}
        </div>
      </>
    );
  }
);

export default TextField;
