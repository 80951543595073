import { styledLogUtagView, USER_URLS, UtagViewPages, UtagViewsNotifications } from '@components/link-utils';
import {DataLayerTracking, UserTypes} from '@model/TrackingClass';
import { Course, LevelEnrichedMap } from '@model/CoursesClass';
import { ErrorTracking } from '@model/TrackingClass';
import { UserProfile } from '@model/User';
import { History } from 'history';
import { firstLetterToUpperCase, isProdEnv, isQualityEnv } from './Api';
import { isCartItemExpired, isPurchasable, toFixedNumString } from './ecommerceUtility';
import { matchPath } from 'react-router';
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {setSendingAnalytics} from "@redux-actions/";
import { cloneDeep } from 'lodash';
import { titles } from './pageTitles';
import i18n from 'i18next';
import { isPersonificationOn } from '../pages_v3/UserGroupSimulation/UserGroupSimulation_PersonificateLogic';
import { queryParams } from './const';

export const initializeGA = (
  ReactGA: any,
  setGACheck: (boolean) => void,
  history: History
): void => {
  if (!ReactGA || !history) {
    return;
  }

  if (isQualityEnv()) {
    ReactGA.initialize('UA-9995164-21');
    ReactGA.pageview(window.location.pathname + window.location.search);
    setGACheck(true);

    history.listen(location => {
      setGACheck(false);
    });
  } else if (isProdEnv()) {
    ReactGA.initialize('UA-9995164-19');
    ReactGA.pageview(window.location.pathname + window.location.search);
    setGACheck(true);

    history.listen(location => {
      setGACheck(false);
    });
  }
};

export const addPageViewGA = (isGAJustInizialized: boolean, ReactGA: any): void => {
  if (!isGAJustInizialized && ReactGA) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
};

export const sendCourseOverviewUtagData = (
  course: Course,
  userProfile: UserProfile,
  Page_Section2?: string,
  labelL1Map?: LevelEnrichedMap
) => {
  const productPurchasable = {
    Products_Units_Array: ['1'],
    Products_PriceFull_Array: isPurchasable(course) ? [toFixedNumString(course?.price || 0)] : ['0.00'],
    Products_RevenuePerUnit_Array: isPurchasable(course) ? [toFixedNumString(course?.price || 0)] : ['0.00'],
  }

  const currentCourseAnalytics = cloneDeep(course);
  let courseContextIdFromSessionStorage = window?.sessionStorage.getItem("Products_ContextId_Array") ?? "";
  if(courseContextIdFromSessionStorage === "undefined") courseContextIdFromSessionStorage = ""

  /*if (courseContextIdFromSessionStorage === "") {
    courseContextIdFromSessionStorage = ""
  }*/

  if(!!matchPath(window.location.pathname, {path: [
    USER_URLS.SUBJECT_MATTER_PROGRAM_PROGRAM.URL,
    USER_URLS.ROLE_SPECIFIC_PROGRAM_PROGRAM.URL,
    USER_URLS.ONBOARDING_COURSE.URL
    ]}) && window.location.search.includes(queryParams.COURSE_DETAIL) && 
    Page_Section2 === titles.PAGE
  ) {
    courseContextIdFromSessionStorage = ""
    return
  }

  let utagViewData: DataLayerTracking = {
    ...UtagViewPages.PRODUCT,
    Products_Category_Array: labelL1Map ? currentCourseAnalytics?.catalogTypes.map(catalogType => (labelL1Map?.[catalogType]?.label)?.toLowerCase()) : currentCourseAnalytics.catalogTypes.map(catalogType => catalogType?.toLowerCase()),
    // product_subcategory
    Products_Id_Array: [currentCourseAnalytics?.courseIdMaster],
    Products_ModelName_Array: [currentCourseAnalytics?.courseFullName?.toLowerCase()],
    //product_format: currentCourseAnalytics.ctype,
    Products_Mandatory_Array: currentCourseAnalytics?.mandatory ? ['1'] : ['0'],
    Products_Language_Array: [currentCourseAnalytics?.language],
    //product_status: isCartItemExpired(currentCourseAnalytics) ? ['expired'] : ['available'],
    //Products_Domain_Array,
    Products_ContextId_Array: [courseContextIdFromSessionStorage],
    Page_Language: userProfile?.preferredLang?.toUpperCase(),
    Page_Country: userProfile?.country?.toUpperCase(),
    User_LoginStatus: isPersonificationOn() ? UserTypes.personification : UserTypes.registered,
    User_Id: userProfile?.moodleId,
    Order_Currency: userProfile?.ecommerceInfo?.currency,
    Page_DeviceType: 'all',
    //user_timeontraining: timeOnTraining,
    ...productPurchasable,
  };

  if (Page_Section2) {
    utagViewData.Page_Section2 = Page_Section2;
  }

  styledLogUtagView(utagViewData?.Page_Section2, utagViewData);
  triggerPageView(utagViewData);
};

export const sendRoleSpecificProgramUtagData = (courses: Course[], baseTealiumTracking: any, userProfile: UserProfile, role: string, labelL1Map: LevelEnrichedMap) => {

  let productCategories: string[] = [];
  let productIds: string[] = [];
  let productNames: string[] = [];
  let productFormats: string[] = [];
  let productMandatory: string[] = [];
  let productLanguages: string[] = [];

  courses && courses.map((course: Course) => {
    productCategories.push(...course?.catalogTypes.map(catalogType => labelL1Map?.[catalogType]?.label?.toLowerCase()))
    productIds.push(course.courseIdMaster)
    productNames.push(course.courseFullName?.toLowerCase())
    productFormats.push(...course?.ctype)
    //productMandatory.push(course.mandatory ? '1' : '0')
    productLanguages.push(course?.language)
  })

  let utagViewData: DataLayerTracking = {
    ...baseTealiumTracking,
    ...UtagViewPages.ROLE_SPECIFIC_PROGRAM,
    Products_Category_Array: productCategories,
    Products_Id_Array: productIds,
    Products_ModelName_Array: productNames,
    //product_format: productFormats,
    //Products_Mandatory_Array: productMandatory,
    Products_Language_Array: productLanguages,
    Page_Section3: firstLetterToUpperCase(role),
    // Page_Language: userProfile.preferredLang,
    // Page_Country: userProfile.country,
    // User_LoginStatus: userProfile.senderSystem,
    // User_Id: userProfile.username,
    // user_timeontraining: timeOnTraining,
    // Page_DeviceType: 'web',
    //Order_Currency: userProfile?.ecommerceInfo?.currency,
    // User_MoodleID: userProfile?.moodleId,
  };

  styledLogUtagView('RoleSpecificProgram', utagViewData);
  triggerPageView(utagViewData);
};

export const facebookPixelId = '1016689112374060';
export const initializeFacebookPixel = () => {
  if (isQualityEnv() || isProdEnv()) {
    //enable Facebook Pixel just in QU and PROD environments
    //localhost analytics would dirty real data

    const script = document.createElement('script');
    const noscript = document.createElement('noscript');

    script.textContent = `
    <!-- tracking pixel of Meta (Facebook) on Leonardo -->
    !function (f, b, e, v, n, t, s) {
      if (f.fbq) return; n = f.fbq = function () {
        n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      };
      if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
      n.queue = []; t = b.createElement(e); t.async = !0;
      t.src = v; s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s)
    }(window, document, 'script',
      'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '${facebookPixelId}');
    fbq('track', 'PageView');
    fbq('track', 'ViewContent');`;

    noscript.textContent = `
    <img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=${facebookPixelId}&ev=PageView&noscript=1" />`;

    document.getElementsByTagName('head')?.[0]?.appendChild(script).appendChild(noscript);
  }
};

export const initializeTealium = () => {
  if (isQualityEnv()) {
    window.tealium_env = 'qa';
  } else if (isProdEnv()) {
    window.tealium_env = 'prod';
  } else {
    window.tealium_env = '';
  }

  const script = document.createElement('script');
  const noscript = document.createElement('noscript');

  script.textContent = `
    <!-- tracking Tealium on Leonardo -->
    (function (a, b, c, d) {
        if (!window.tealium_env) {
          return;
        }
        a = "https://tags.tiqcdn.com/utag/luxottica/university/${window.tealium_env}/utag.js";
        const cacheBuster = new Date().getTime();
        b = document;
        c = "script";
        d = b.createElement(c);
        d.src = a + "?v=" + cacheBuster;
        d.type = "text/java" + c;
        d.async = true;
        a = b.getElementsByTagName(c)[0];
        a.parentNode.insertBefore(d, a);
      })();
      window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
    `;

  noscript.textContent = `<!-- tracking Tealium on Leonardo -->
        (function (a, b, c, d) {
            if (!window.tealium_env) {
              return;
            }
            a = "https://tags.tiqcdn.com/utag/luxottica/university/${window.tealium_env}/utag.js";
            const cacheBuster = new Date().getTime();
            b = document;
            c = "script";
            d = b.createElement(c);
            d.src = a + "?v=" + cacheBuster;
            d.type = "text/java" + c;
            d.async = true;
            a = b.getElementsByTagName(c)[0];
            a.parentNode.insertBefore(d, a);
          })();
          window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
          `;

  document.getElementsByTagName('head')?.[0]?.appendChild(script).appendChild(noscript);
};

export const purifyItemDataElementId = (string: string): string => {
  return string?.replace(/[^a-z0-9]/gi, '');
};
export const purifyItemDataElementIdCamelCase = (string: string): string => {
  return string?.replace(/\s(.)/g, function ($1) { return $1.toUpperCase(); })
    .replace(/\s/g, '')
    .replace(/^(.)/, function ($1) { return $1.toLowerCase(); });
};


function camelize(str) {
  return str?.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}

export const purifyItemDataElementIdandApplyCamelCasing = (string: string): string => {
  const stringPurified = string?.replace(/[^a-z0-9]/gi, ' ');
  const stringWithCC = camelize(stringPurified?.toLowerCase());
  const finalString = stringWithCC?.charAt(0)?.toUpperCase() + stringWithCC?.slice(1);
  if (finalString) {
    return finalString.replace(/[^a-z0-9]/gi, '');
  }
  return;
};

export const purifyItemDataElementIdandApplyCamelCasingBrandCase = (string: string): string => {
  const stringPurified = string?.replace(/[^a-z0-9]/gi, ' ');
  const stringWithCC = camelize(stringPurified?.toLowerCase());
  const finalString = stringWithCC?.charAt(0)?.toLowerCase() + stringWithCC?.slice(1);
  if (finalString) {
    return finalString.replace(/[^a-z0-9]/gi, '');
  }
  return;
};


export const analyticsPageType = () => {
  let pageType = '';
  Object.keys(UtagViewsNotifications).forEach(urlObj => {
    if (matchPath(window.location.pathname, { path: urlObj })) {
      pageType = UtagViewsNotifications[urlObj].Page_Type
    }
  });
  return pageType;
}

interface TealiumDataLayer {
  push(data: object): void;
}

declare const tealium_data2track: TealiumDataLayer;

// Trigger the page view
export const triggerPageView = (dataToPush: Object, errorToPush?: ErrorTracking, retrycounter: number = 0) => {
  //console.log("🚀 ~ triggerPageView ~ dataToPush:", dataToPush)
  if(window?.utag) {
    var obj: Object = {};
    try {
      obj = {
        ...dataToPush
      };
    } catch (err) {
      obj = {
        id: 'Event',
        Error_Source: 'Server',
        Error_Code: errorToPush.Error_Code ?? '$value$',
        Error_Detail: errorToPush.Error_Detail ?? '$value$',
        Events_Error: errorToPush.Events_Error ?? '$value$'
      };
    }
    finally {
      tealium_data2track.push(obj);
    }
  } else {
    if(window.tealium_env) {
      if(retrycounter < 3)
      setTimeout(() => {
        triggerPageView(dataToPush, errorToPush, retrycounter +1)
      }, 2000);
      console.error('Cannot push pageView event, Tealium library not loaded yet')
    }
  }
}

// Trigger the track event
export const triggerTrackEvent = (
  dataToPush: Object,
  errDescription?: string
) => {
  if(window?.utag) {
    var obj: Object = {};
    try {
      obj = {
        id: 'Event',
        ...dataToPush
      };
    } catch (err) {
      obj = {
        id: 'Event',
        Error_Source: 'Server',
        Error_Code: 'utag_data_object',
        Error_Detail: errDescription,
        Events_Error: '$value$'
      };
    }
    finally {
      tealium_data2track.push(obj);
    }
  } else {
    console.error('Cannot push track event, Tealium library not loaded yet')
  }
}

// Trigger the error event
export const triggerErrorEvent = (
  dataToPush: Object,
  errDescription?: string
) => {
  if(window?.utag) {
    var obj: Object = {};
    try {
      obj = {
        id: 'Event',
        Events_Error: '1',
        ...dataToPush
      };
    } catch (err) {
      obj = {
        id: 'Event',
        Error_Source: 'Server',
        Error_Code: 'utag_data_object',
        Error_Detail: errDescription,
        Events_Error: '$value$'
      };
    }
    finally {
      tealium_data2track.push(obj);
    }
  } else {
    console.error('Cannot push track error, Tealium library not loaded yet')
  }
}

// /**
//  * Function to get the CourseContextId
//  * @returns CourseContextId to push for analytics Event
//  */
// export const getCourseContextIdAnalytics = () => {
//   // IF conditions to check if contextId can be retrieved
//   if(window) {
//     // IF in sessionStorage return it from there
//     const courseContextIdFromSessionStorage = window.sessionStorage?.getItem("Products_ContextId_Array")
//     if(courseContextIdFromSessionStorage) return courseContextIdFromSessionStorage
//     // OTHERWISE check if you can return it from URL
//     if(window.location && window.location.pathname) {
//       const url = window.location.pathname;
//       const splittedUrl = url.split('/');
//       // IF standard program
//       if(splittedUrl[1] === 'program') return splittedUrl[2]?.split('?')[0]
//       // IF BYC RoleSpecificProgram
//       if(splittedUrl[1] === 'build-your-career' && splittedUrl.length === 4) return splittedUrl[3]?.split('?')[0]
//       // IF BYC SpecialtyProgram
//       if(splittedUrl[1] === 'build-your-career' && splittedUrl.length === 5) return splittedUrl[4]?.split('?')[0]
//     }
//   }
//   // OTHERWISE return undefined
//   return undefined
// }




