// export const BASE_URL = (process.env.NODE_ENV === "development") ?  'https://dt-university.luxottica.com/newuni/bff' : "/newuni/bff"

import { isProdEnv, isQualityEnv } from "@utility/Api";

export const HOSTNAME = (process.env.NODE_ENV === "development") ? '' : '';

export const BASE_URL = HOSTNAME + "/newuni/bff";
export const BASE_NOTIF_URL = HOSTNAME + "/newuni/notification";
export const BASE_ADMIN_URL = HOSTNAME + "/newuni/admin";

const BASE_PATH = window.location.protocol + '//' + window.location.host;

export const LOCAL_GOOGLE_AUTHENTICATION_URL = BASE_PATH + '/cas/clientredirect?client_name=replyTest&service=' + BASE_PATH + '/newuni/bff/auth/loginFromGoogle';
export const LOCAL_GOOGLE_AUTHENTICATION_ENABLE = false;

// let BASE_URL = 'http://10.30.16.167:40001/newuni/bff'; //pasquale
// export const BASE_URL = 'http://10.30.16.115:40001/newuni/bff'; //pasquale

// export const BASE_URL = "http://10.7.8.141:40001/newuni/bff";

// export const BASE_URL = "/newuni/bff"

const configFirebaseDEV = {
    apiKey: "AIzaSyDF5fX_Vk44KZPokpHDsHMkMdcEqDWZeJc",
    authDomain: "university-dev-a8cb4.firebaseapp.com",
    databaseURL: "https://university-dev-a8cb4.firebaseio.com",
    projectId: "university-dev-a8cb4",
    storageBucket: "university-dev-a8cb4.appspot.com",
    messagingSenderId: "504248374347",
    appId: "1:504248374347:web:ff56dbfb6e9ef1ec8ce9ce",
    measurementId: "G-8V559FHVLX",
};

const configFirebaseQA = {
    apiKey: "AIzaSyCALhHLZBoLOyAUTYSTRJFUbTZ1CReVJbA",
    authDomain: "university-qa.firebaseapp.com",
    databaseURL: "https://university-qa.firebaseio.com",
    projectId: "university-qa",
    storageBucket: "university-qa.appspot.com",
    messagingSenderId: "20493221044",
    appId: "1:20493221044:web:3ecbcf135a767288afabe1",
    measurementId: "G-VV9JCM9ZJ8"
};

const configFirebasePR = {
    apiKey: "AIzaSyAWf8pVNGKdXMSOC3tM3Pmm1t2IPNSWZ98",
    authDomain: "university-d22ae.firebaseapp.com",
    databaseURL: "https://university-d22ae.firebaseio.com",
    projectId: "university-d22ae",
    storageBucket: "university-d22ae.appspot.com",
    messagingSenderId: "510351920292",
    appId: "1:510351920292:web:79b916d2c9b156a9e26a31",
    measurementId: "G-HL08JX0PGZ"
};

// @todo ask for newkeys
export const configReCAPTCHA = {
    public_site_key: "6LfIbiMaAAAAAFjx1ZB9Z9GJvm-2P9lC5ZCnRdel",
};
export const GOOGLE_API_KEY = "AIzaSyBP4L50QtJErjJ9twOEFkUajxGffQgpyq4";

export let CONFIG_FIREBASE = configFirebaseQA;
if (isQualityEnv()) {
    CONFIG_FIREBASE = configFirebaseQA;
} else if (isProdEnv()) {
    CONFIG_FIREBASE = configFirebasePR;
}


export const FORTER_SN_QA = '6db77fc22b76';
export const FORTER_SN_PR = '176ca9db6d62';
