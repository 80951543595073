import * as actionTypes from './actionTypes';
import * as urlList from '../../config';
const axios = require('axios');
import {
  getHeaders,
  formatOptions,
  isPathAbsolute,
  convertArrayToMap,
  removeURLParameter,
  checkCourseState,
} from '@utility/Api';
import { logout, logoutRedux } from '../actions/authenticationActions';
import { getCourses, setIsCourseDetailOpenedByUrl, setJoiningCourses, setTimerJoin } from './coursesActions';
import { BRAND_TYPE, COOKIE, courseStatesConst, PERSONIFICATION_COOKIE_KEYS, queryParams, TAGS } from '@utility/const';
import { getCookie, removeCookie, setCookie } from '@utility/cookie';
import defaultLang from '@utility/lang';
import defaultLangWcag from '@utility/langWcag';
import defaultLangEcomm from '@utility/langEcomm';
import defaultLangNotif from '@utility/langNotif';
import defaultLangTP from '@utility/langTP';
import { GradientStyle } from '@model/GradientClass';
import { SURVEY_TYPE } from '@model/Survey';
import { getUserProfile, updateUser } from './userActions';
import { LEADERBOARD_PRESENCE, NOTIFICATION_CHANNELS } from '@model/User';
import { getL1Images } from './cmsActions';
import {
  GRADIENT_LIGHT_BLUE_POSITION,
  GRADIENT_ORANGE_POSITION,
  GRADIENT_YELLOW_POSITION,
} from '@utility/gradient-utils';
import { getL0ImageEditorial, getL0ImageIcon, getL1ImageChipLarge } from '@utility/levelUtility';
import { handleCloseCelebrationInLocalStorage } from '@utility/localStorage';
import { downloadBase64 } from '@utility/myTeamUtility';
import { isUserEnabledForTrainingPills } from '@utility/TrainingPillsUtility';
import { isUserEnabledForCollections } from '@utility/CollectionsUtility';
import { updateCoursesInCatalog } from '../../store/actions/coursesActions';


export const showError = message => {
  return dispatch => {
    console.error('showError', message);
    let showErrorBanner = true;

    if (message && message.status && message.status === 401) {
      localStorage.removeItem('authentication');

      if (location.hostname === 'localhost' || location.hostname.includes('ngrok')) {
        removeCookie(COOKIE.MOODLE_SESSION);
      } else {
        removeCookie(COOKIE.MOODLE_SESSION, 'luxottica.com');
        removeCookie(COOKIE.MOODLE_SESSION, 'essilorluxottica.com');
      }
      removeCookie(COOKIE.USER_TOKEN);
      removeCookie(COOKIE.FIREBASE_TOKEN);
      removeCookie(COOKIE.ECOMMERCE);
      removeCookie(COOKIE.USER_DETAILS);
      removeCookie(COOKIE.MYL_TOKEN, '.essilorluxottica.com');
      removeCookie(COOKIE.MYL_TOKEN);

      dispatch(logoutRedux());

      showErrorBanner = false;
    }

    if (message && message.response && message.response.status === 401) {
      localStorage.removeItem('authentication');

      if (location.hostname === 'localhost' || location.hostname.includes('ngrok')) {
        removeCookie(COOKIE.MOODLE_SESSION);
      } else {
        removeCookie(COOKIE.MOODLE_SESSION, 'luxottica.com');
        removeCookie(COOKIE.MOODLE_SESSION, 'essilorluxottica.com');
      }
      removeCookie(COOKIE.USER_TOKEN);
      removeCookie(COOKIE.FIREBASE_TOKEN);
      removeCookie(COOKIE.ECOMMERCE);
      removeCookie(COOKIE.USER_DETAILS);
      removeCookie(COOKIE.MYL_TOKEN, '.essilorluxottica.com');
      removeCookie(COOKIE.MYL_TOKEN);

      dispatch(logoutRedux());
      dispatch(logout());

      showErrorBanner = false;
    }

    if (showErrorBanner) {
      dispatch({
        type: actionTypes.SHOW_ERROR_MESSAGE,
        message,
      });
    }
  };
};

export const closeAlert = () => {
  return {
    type: actionTypes.HIDE_ERROR_MESSAGE,
  };
};

export const setSendingAnalytics = value => {
  return {
    type: actionTypes.SET_SENDING_ANDALYTICS,
    sendingAnalytics: value,
  };
};

export const setForceAnalytics = value => {
  return {
    type: actionTypes.SET_FORCE_ANALYTICS,
    forceAnalytics: value,
  };
};

export const setAnalyticsCourseId = value => {
  return {
    type: actionTypes.SET_ANALYTICS_COURSE_ID,
    analyticsCourseId: value,
  };
};

export const setWindowLocationSearchQuery = query => {
  return {
    type: actionTypes.SET_LOCATION_SEARCH_QUERY,
    windowLocationSearchQuery: query,
  };
};

export const getLabels = (labelLang, type) => {
  return (dispatch, getState) => {
    let url = urlList.GET_LABEL_URL;

    if (type === 'ecommerce') {
      if (labelLang === getState().utils.labelLang) {
        return;
      }

      //check if the user is enabled to ecommerce
      const ecommerceEnable = getState().user.ecommerceEnable;
      if (!ecommerceEnable) {
        console.log('getLabels type ecommerce - user not enabled to ecommerce');
        return;
      }

      //get ecommerce labels
      url = urlList.GET_LABEL_ECOMMERCE_URL;
    } else if (type === 'trainingpills') {
      if (labelLang === getState().utils.labelLangTP) {
        return;
      }
      const userProfile = getState().user.userProfile;
      if (
        !isUserEnabledForTrainingPills(userProfile) &&
        !isUserEnabledForCollections(userProfile)
      ) {
        return;
      }

      url = urlList.GET_LABEL_TP_URL;
    } else {
      if (labelLang === getState().utils.labelLang) {
        return;
      }

      url = urlList.GET_LABEL_URL;
    }

    axios
      .get(url + '/' + labelLang, {
        headers: getHeaders(),
      })
      .then(response => {
        //get old lang (otherwise ecommerce labels would overwrite general labels and vice-versa)
        let oldLang = getState().utils.lang;
        oldLang = oldLang ? oldLang : {};

        const newLang = {
          ...defaultLangWcag,
          ...defaultLangTP,
          ...defaultLangEcomm,
          ...defaultLang,
          ...oldLang,
          ...response.data,
        };

        let param = {};
        switch (type) {
          case 'trainingpills':
            param.labelLangTP = labelLang;
            break;
          case 'ecommerce':
          default:
            param.labelLanguage = labelLang;
            break;
        }
        dispatch(success(newLang, labelLang));
      })
      .catch(err => {
        dispatch(showError(err));
      });
  };

  function success(lang, paramLabelLanguage) {
    return {
      type: actionTypes.GET_LABEL_SUCCESS,
      lang,
      labelLang: paramLabelLanguage.labelLanguage,
      labelLangTP: paramLabelLanguage.labelLangTP,
    };
  }
};

export const getLabelsNotif = lang => {
  return (dispatch, getState) => {
    if (lang === getState().utils.labelLangNotif) {
      return;
    }

    let url = urlList.GET_LABEL_NOTIF_URL;

    axios
      .get(url + '/' + lang, {
        headers: getHeaders(),
      })
      .then(response => {
        const newLang = { ...defaultLangNotif, ...response.data };
        dispatch(success(newLang, lang));
      })
      .catch(err => {
        dispatch(showError(err));
      });
  };

  function success(lang, labelLang) {
    return {
      type: actionTypes.GET_LABEL_NOTIF_SUCCESS,
      lang,
      labelLang,
    };
  }
};

const mockGetLabelsLevel = async () => {
  const labelsL1 = await fetch('/data/getLabelsLevel.json');
  const labelsL1Json = await labelsL1.json();
  return { data: labelsL1Json };
};

export const getLabelsLevel = lang => {
  return (dispatch, getState) => {
    const mock = false;

    const labelL0Map = getState().utils.labelL0Map;
    if (labelL0Map) {
      const labelL0List = Object.values(labelL0Map);
      if (labelL0List?.length > 0 && lang === labelL0List[0].language) {
        return;
      }
    }

    let url = urlList.GET_LABEL_LEVEL_URL.replace('{language}', lang);

    (mock ? mockGetLabelsLevel() : axios.get(url, { headers: getHeaders() }))
      .then(response => {
        const labelsLevelMapRes = response.data;

        //L0
        let labelsL0MapRes = labelsLevelMapRes.levelList;
        if (Array.isArray(labelsL0MapRes)) {
          labelsL0MapRes = convertArrayToMap(labelsL0MapRes, 'key');
        }
        for (let l0Id in labelsL0MapRes) {
          labelsL0MapRes[l0Id].imageIcon = getL0ImageIcon(l0Id);
          labelsL0MapRes[l0Id].imageEditorial = getL0ImageEditorial(l0Id);
        }

        //L1
        let labelsL1MapRes = labelsLevelMapRes.channelsList;
        if (Array.isArray(labelsL1MapRes)) {
          labelsL1MapRes = convertArrayToMap(labelsL1MapRes, 'key');
        }
        for (let l1Id in labelsL1MapRes) {
          labelsL1MapRes[l1Id].imageChipLarge = getL1ImageChipLarge(l1Id);
        }

        //L2
        let labelsL2MapRes = labelsLevelMapRes.sectionsList;
        if (Array.isArray(labelsL2MapRes)) {
          //convert structure into common structure
          labelsL2MapRes = labelsL2MapRes.map(l2 => {
            return {
              key: l2.sectionId,
              label: l2.label,
              order: l2.index,
              language: l2.language,
            };
          });

          labelsL2MapRes = convertArrayToMap(labelsL2MapRes, 'key');
        }

        //Lt TAG
        let labelLtTagMapRes = labelsLevelMapRes.levelTagList;
        if (Array.isArray(labelLtTagMapRes)) {
          labelLtTagMapRes = labelLtTagMapRes.map(lt => {
            return {
              key: lt.key,
              label: lt.label,
              language: lt.language,
              description: lt.description,
              title: lt.title,
            };
          });
          labelLtTagMapRes = convertArrayToMap(labelLtTagMapRes, 'key');
        }

        //COMPLEXITY
        let labelsComplexityMapRes = labelsLevelMapRes.complexityList;
        if (Array.isArray(labelsComplexityMapRes)) {
          //convert structure into common structure
          labelsComplexityMapRes = labelsComplexityMapRes.map(complexity => {
            return {
              key: complexity.key || complexity.id,
              ...complexity,
            };
          });

          labelsComplexityMapRes = convertArrayToMap(labelsComplexityMapRes, 'key');
        }

        dispatch(successL0(labelsL0MapRes));
        dispatch(successL1(labelsL1MapRes));
        dispatch(successL2(labelsL2MapRes));
        dispatch(successLtTag(labelLtTagMapRes));
        dispatch(successComplexity(labelsComplexityMapRes));

        dispatch(getL1Map(null, labelsL1MapRes, labelsL0MapRes));
      })
      .catch(err => {
        dispatch(showError(err));
      });
  };

  function successL0(labelL0Map) {
    return {
      type: actionTypes.GET_LABEL_L0_SUCCESS,
      labelL0Map,
    };
  }

  function successL1(labelL1Map) {
    return {
      type: actionTypes.GET_LABEL_L1_SUCCESS,
      labelL1Map,
    };
  }

  function successL2(labelL2Map) {
    return {
      type: actionTypes.GET_LABEL_L2_SUCCESS,
      labelL2Map,
    };
  }

  function successLtTag(labelLtTagMap) {
    return {
      type: actionTypes.GET_LABEL_LTTAG_SUCCESS,
      labelLtTagMap,
    };
  }

  function successComplexity(labelComplexityMap) {
    return {
      type: actionTypes.GET_LABEL_COMPLEXITY_SUCCESS,
      labelComplexityMap,
    };
  }
};

const mockGetLabelsOnboardingTopics = async () => {
  const labels = await fetch('/data/getOnboardingTopics.json');
  const labelsJson = await labels.json();
  return { data: labelsJson };
};

export const getLabelsOnboardingTopics = lang => {
  return (dispatch, getState) => {
    const mock = false;

    const labelOnboardingTopicsMap = getState().utils.labelOnboardingTopicsMap;
    if (labelOnboardingTopicsMap) {
      const labelOnboardingTopicsList = Object.values(labelOnboardingTopicsMap);
      if (labelOnboardingTopicsList?.length > 0 && lang === labelOnboardingTopicsList[0].language) {
        return;
      }
    }

    let url = urlList.GET_LABEL_ONBOARDING_TOPICS_URL.replace('{language}', lang);

    (mock ? mockGetLabelsOnboardingTopics() : axios.get(url, { headers: getHeaders() }))
      .then(response => {
        let labelsOnboardingTopicsMapRes = response.data;

        if (Array.isArray(labelsOnboardingTopicsMapRes)) {
          labelsOnboardingTopicsMapRes = convertArrayToMap(labelsOnboardingTopicsMapRes, 'key');
        }

        dispatch(success(labelsOnboardingTopicsMapRes));
      })
      .catch(err => {
        dispatch(showError(err));
      });
  };

  function success(labelOnboardingTopicsMap) {
    return {
      type: actionTypes.GET_LABEL_ONBOARDING_TOPICS_SUCCESS,
      labelOnboardingTopicsMap,
    };
  }
};

export const getBrands = () => {
  return dispatch => {
    let url = urlList.GET_BRANDS_URL;

    axios
      .get(url, {
        headers: getHeaders(),
      })
      .then(response => {
        let brandsList = {
          houseBrands: [],
          licensedBrands: [],
          thirdPartyBrands: [],
          lensTechnologies: [],
          map: {},
        };

        response.data.forEach(brand => {
          switch (brand.type) {
            case BRAND_TYPE.HOUSE_BRAND:
              brandsList.houseBrands.push(brand);
              break;
            case BRAND_TYPE.LICENSED_BRAND:
              brandsList.licensedBrands.push(brand);
              break;
            case BRAND_TYPE.THIRD_PARTY_BRAND:
              brandsList.thirdPartyBrands.push(brand);
              break;
            case BRAND_TYPE.LENS_TECH:
              brandsList.lensTechnologies.push(brand);
              break;
          }

          //create a map to find fastly a brand by its id
          brandsList.map[brand.brand] = brand;
          for (const child of brand.children) {
            if (!brandsList.map[child]) {
              brandsList.map[child] = brand;
            }
          }
        });

        dispatch(success(brandsList));
      })
      .catch(err => {
        dispatch(showError(err));
      });
  };

  function success(brandsList) {
    return {
      type: actionTypes.GET_BRANDS_SUCCESS,
      brandsList,
    };
  }
};

//CATALOG LIGHT
const mockGetUserRelevantSessions = async () => {
  const userRelevantSessions = await fetch('/data/UserRelevantSessionsMock.json');
  const userRelevantSessionsJson = await userRelevantSessions.json();
  return { data: userRelevantSessionsJson };
};

export const getUserRelevantSessions = () => {
  let joiningCourses = [];
  return (dispatch, getState) => {
    dispatch(request());
    let url = urlList.GET_USER_RELEVANT_SESSIONS_URL;
    let coursesUpdatedWithRelevantSessions = [];
    const mock = false;

    (mock ? mockGetUserRelevantSessions() : axios.get(url, { headers: getHeaders() }))
      .then(response => {
        // console.log("MOCK CREATED", response);
        let coursesMap = cloneDeep(getState().course.coursesMap);
        if (coursesMap && response) {
          response.data.forEach(sessionDetail => {
            //   console.log("FOR EACH IN PROGRESS", sessionDetail);
            //get courseIdMaster if present, otherwise courseId
            let courseId = sessionDetail.courseIdMaster;
            if (!courseId) {
              courseId = sessionDetail.courseId;
            }
            let course = coursesMap[courseId];
            if (course) {
              if (sessionDetail.courseStates) {
                course.courseStates = sessionDetail.courseStates;
              }
              if (sessionDetail.userRelevantSessions) {
                course.userRelevantSessions = sessionDetail.userRelevantSessions;
              }
              if (sessionDetail.courseSessions) {
                course.courseSessions = sessionDetail.courseSessions;
              }
              if (sessionDetail.mandatory) {
                course.mandatory = sessionDetail.mandatory;
              }
              if (sessionDetail.userCourseStatus) {
                course.userCourseStatus = sessionDetail.userCourseStatus;
              }

              if (checkCourseState(course.courseStates, courseStatesConst.SCHEDULED)
              ) {
                joiningCourses.push(course);
              }
              //  console.log("PRINTING USER RELEVANT SESSIONS", course);
              coursesUpdatedWithRelevantSessions.push(course);
            } else {
              console.log('Err: course not found');
            }
          });
          dispatch(setJoiningCourses(joiningCourses));
          dispatch(setTimerJoin());
          dispatch(
            updateCoursesInCatalog(
              coursesUpdatedWithRelevantSessions,
              coursesUpdatedWithRelevantSessions.map(course => course?.courseIdMaster),
              '',
              true
            )
          );
          //   console.log("COURSES MAP UPDATE", coursesMap);
          dispatch(success());
        }
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return {
      type: actionTypes.GET_USER_RELEVANT_SESSION_REQUEST,
    };
  }

  function success() {
    return {
      type: actionTypes.GET_USER_RELEVANT_SESSION_COMPLETED,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_USER_RELEVANT_SESSION_FAILED
    };
  }
};

const mockGetUserRelevantSessionsById = async () => {
  const userRelevantSessions = await fetch('/data/UserRelevantSessionsMockById.json');
  const userRelevantSessionsJson = await userRelevantSessions.json();
  return { data: userRelevantSessionsJson };
};

export const getUserRelevantSessionsById = id => {
  // console.log("****CALLING ID WITH ", id);
  let joiningCourses = [];
  return (dispatch, getState) => {
    dispatch(request());
    let url;
    if (id) {
      url = urlList.GET_USER_RELEVANT_SESSIONS_URL + "?courseId=" + id;
    }
    else {
      url = urlList.GET_USER_RELEVANT_SESSIONS_URL;
    }
    let coursesUpdatedWithRelevantSessions = [];
    const mock = false;
    (mock
      ? mockGetUserRelevantSessionsById()
      : axios.get(url, { headers: getHeaders() }, { params: { courseId: id } })
    )
      .then(response => {
        // console.log("MOCK CREATED", response);
        let coursesMap = cloneDeep(getState().course.coursesMap);
        if (coursesMap && response) {
          //  console.log("******ENTERED IN THE RESPONSE")
          response.data.forEach(sessionDetail => {
            //  console.log("****FOR EACH IN PROGRESS", sessionDetail);
            let courseId = sessionDetail.courseIdMaster;
            if (!courseId) {
              courseId = sessionDetail.courseId;
            }
            let course = coursesMap[courseId];
            if (course) {
              if (sessionDetail.courseStates) {
                course.courseStates = sessionDetail.courseStates;
              }
              if (sessionDetail.userRelevantSessions) {
                course.userRelevantSessions = sessionDetail.userRelevantSessions;
              }
              if (sessionDetail.courseSessions) {
                course.courseSessions = sessionDetail.courseSessions;
              }
              if (sessionDetail.mandatory) {
                course.mandatory = sessionDetail.mandatory;
              }

              if (checkCourseState(course.courseStates, courseStatesConst.SCHEDULED)
              ) {
                joiningCourses.push(course);
              }
              coursesUpdatedWithRelevantSessions.push(course);
              //  console.log("***** PRINTING USER RELEVANT SESSIONS", course);
            } else {
              console.log('Err: course not found');
            }
          });
          dispatch(setJoiningCourses(joiningCourses));
          dispatch(setTimerJoin());
          dispatch(
            updateCoursesInCatalog(
              coursesUpdatedWithRelevantSessions,
              coursesUpdatedWithRelevantSessions.map(course => course?.courseIdMaster),
              '',
              true
            )
          );
          // console.log("**** COURSES MAP UPDATE", coursesMap);
        }

        dispatch(success());
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };


  function request() {
    return {
      type: actionTypes.GET_USER_RELEVANT_SESSION_BY_ID_REQUEST,
    };
  }

  function success() {
    return {
      type: actionTypes.GET_USER_RELEVANT_SESSION_BY_ID_COMPLETED,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_USER_RELEVANT_SESSION_BY_ID_FAILED
    };
  }

  function request() {
    return {
      type: actionTypes.USER_RELEVANT_SESSION_BY_ID_REQUEST_LOADED,
    };
  }

  function success() {
    return {
      type: actionTypes.USER_RELEVANT_SESSION_BY_ID_COMPLETED_LOADED,
    };
  }

  function failure() {
    return {
      type: actionTypes.USER_RELEVANT_SESSION_BY_ID_FAILED_LOADED
    };
  }
};

/*export const mockGetLeaderboardCy = async () => {
  const leaderboardCy = await fetch('/data/getLeaderboardCy.json');
  const leaderboardCyjson = await leaderboardCy.json();
  return { data: leaderboardCyjson };
};*/

export const getBrandNameById = (brandsList, brandId) => {
  if (brandId && brandId.toUpperCase() === 'ALL') {
    return brandId;
  }

  if (brandsList && brandsList.map && brandsList.map[brandId] && brandsList.map[brandId].name) {
    return brandsList.map[brandId].name;
  } else {
    return '';
  }
};

export const getTimezones = () => {
  return dispatch => {
    let url = urlList.GET_TIMEZONE_URL;
    dispatch(request());

    axios
      .get(url, {
        headers: getHeaders(),
      })
      .then(response => {
        let timezones = formatOptions(response.data, 'timezone');

        dispatch(success(timezones));
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return {
      type: actionTypes.GET_TIMEZONE_REQUEST,
    };
  }

  function success(timezones) {
    return {
      type: actionTypes.GET_TIMEZONE_SUCCESS,
      timezones,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_TIMEZONE_FAILURE,
    };
  }
};

export const getLanguages = () => {
  return dispatch => {
    let url = urlList.GET_ALL_LANGUAGES;
    dispatch(request());

    axios
      .get(url, {
        headers: getHeaders(),
      })
      .then(response => {
        let languages = formatOptions(response.data, 'description');
        let printLanguagesMap = {};

        // printLanguagesMap = orderBy(printLanguagesMap, ["description"]);
        orderBy(languages, ['description']).map(lang => {
          printLanguagesMap[lang.code] = lang.description !== '' ? lang.description : lang.code;
        });

        dispatch(success(languages, printLanguagesMap));
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return {
      type: actionTypes.GET_LANGUAGES_REQUEST,
    };
  }

  function success(languages, printLanguagesMap) {
    return {
      type: actionTypes.GET_LANGUAGES_SUCCESS,
      languages,
      printLanguagesMap,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_LANGUAGES_FAILURE,
    };
  }
};

export const showNotice = (noticeText, noticeCourse, updateTypeRes = null) => {
  return (dispatch, getState) => {
    let date = new Date().getTime().toString();
    let noticeId = noticeText + date;
    if (noticeCourse) {
      noticeId = noticeText + noticeCourse.courseId + date;
    }

    let notice = {
      noticeText: noticeText,
      noticeCourse: noticeCourse,
      noticeId: noticeId,
      noticeUpdateType: updateTypeRes,
    };

    let notices = [...getState().utils.notices, notice],
      noticeTimeout = getState().utils.noticeTimeout;

    setTimeout(() => {
      dispatch(hideNotice(notice));
    }, noticeTimeout);

    dispatch({
      type: actionTypes.SHOW_NOTICE,
      notices,
    });
  };
};

export const hideNotice = notice => {
  return {
    type: actionTypes.HIDE_NOTICE,
    notice,
  };
};

export const setHomeSubTab = (courseArray, subTab = 0) => {
  return dispatch => {
    let subTabHome = 0;

    if (courseArray.length === 0) {
      subTabHome = subTab;
    } else {
      subTabHome = courseArray.findIndex(x => x.length > 0);
    }

    dispatch({
      type: actionTypes.SET_SUB_TAB_HOME,
      subTabHome,
    });
  };
};

export const setHomeTab = tabHome => {
  return {
    type: actionTypes.SET_TAB_HOME,
    tabHome,
  };
};

export const clearTimeout = () => {
  return {
    type: actionTypes.CLEAR_SUBSCRIPTION_TIME_OUT,
  };
};

export const toggleIframePopup = (
  value,
  course,
  url = null,
  urlToCheck = null,
  isVideo = false,
  urlsLivestream = null,
  activityId = '',
  subtitleCode = '',
  autoManageHistory = true
) => {
  return {
    type: actionTypes.TOGGLE_IFRAME_POPUP,
    value,
    url: urlsLivestream ? urlsLivestream.liveUrl : url,
    urlToCheck,
    isVideo,
    course,
    urlsLivestream,
    activityIdIframe: activityId,
    subtitleCode: subtitleCode,
    autoManageHistory: autoManageHistory
  };
};

export const getSortByRelevance = () => {
  return dispatch => {
    let url = urlList.GET_ORDER_BY_RELEVANCE_URL;
    dispatch(request());

    axios
      .get(url, {
        headers: getHeaders(),
      })
      .then(response => {
        if (response && response.data) {
          const sortByRelevanceCriteria = response.data;
          dispatch(success(sortByRelevanceCriteria));
        } else {
          dispatch(showError(err));
          dispatch(failure());
        }
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return {
      type: actionTypes.GET_SORT_BY_RELEVANCE_REQUEST,
    };
  }

  function success(sortByRelevanceCriteria) {
    return {
      type: actionTypes.GET_SORT_BY_RELEVANCE_SUCCESS,
      sortByRelevanceCriteria,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_SORT_BY_RELEVANCE_FAILURE,
    };
  }
};

export const setUrlRedirectAfterLogin = url => {
  return {
    type: actionTypes.SET_URL_REDIRECT_AFTER_LOGIN,
    url,
  };
};

export const handleRedirectAfterLogin = () => {
  return (dispatch, getState) => {
    if (window.location.pathname != '/login') {
      let search = window.location.search;
      if (window.location.search.includes(queryParams.REDIRECT_AFTER_LOGIN)) {
        search = removeURLParameter(search, queryParams.REDIRECT_AFTER_LOGIN);
      }

      dispatch(setUrlRedirectAfterLogin(window.location.pathname + window.location.hash + search));
    }
    setIsCourseDetailOpenedByUrl(false);
  };
};

export const openVideo = (courseDetails, course) => {
  return dispatch => {
    if (courseDetails && courseDetails.length > 0) {
      let url = '',
        urlToCheck = null;

      if (
        courseDetails[0].content[0].tags.length > 0 &&
        courseDetails[0].content[0].tags.includes(TAGS.publishDirect)
      ) {
        url = courseDetails[0].content[0].directUrl;
        urlToCheck = courseDetails[0].content[0].url;
      } else {
        url = courseDetails[0].content[0].url;
      }

      //prevent relative path
      if (!isPathAbsolute(url)) {
        url = '';
      }

      dispatch(
        toggleIframePopup(
          true,
          course,
          url,
          urlToCheck,
          true,
          null,
          courseDetails[0]?.content?.[0]?.moduleId
        )
      );
    }
  };
};

export const setColorPicker = (colorPickerValue, updateInfoUser = false) => {
  return (dispatch, getState) => {
    if (
      isNaN(colorPickerValue) ||
      colorPickerValue < GRADIENT_YELLOW_POSITION.value ||
      GRADIENT_ORANGE_POSITION.value > 7
    ) {
      colorPickerValue = GRADIENT_LIGHT_BLUE_POSITION.value;
    }

    dispatch(setGradientStyle(colorPickerValue));

    if (updateInfoUser && getState().user.isUserCompleted) {
      dispatch(updateUser(colorPickerValue.toString(), 'preferredColorTheme'));
    }

    dispatch({
      type: actionTypes.SET_COLOR_PICKER,
      colorPickerValue: colorPickerValue,
    });
  };
};

export const setGradientStyle = (
  colorPickerValuePrm,
  gradientEnablePrm = null,
  brandPageInfoPrm = null
) => {
  return (dispatch, getState) => {
    const colorPickerValue =
      colorPickerValuePrm || colorPickerValuePrm === 0
        ? colorPickerValuePrm
        : getState().utils.colorPickerValue;
    const gradientEnable =
      gradientEnablePrm === null || gradientEnablePrm === undefined
        ? getState().utils.gradientEnable
        : gradientEnablePrm;
    let gradientStyle = new GradientStyle(colorPickerValue, gradientEnable, brandPageInfoPrm);
    // console.log('gradientStyle reducer', gradientStyle);

    //upate css variable
    if (gradientStyle?.mainColor?.color) {
      document.documentElement.style.setProperty('--theme-color', gradientStyle.mainColor.color);
    }

    dispatch({
      type: actionTypes.SET_GRADIENT_STYLE,
      gradientStyle: gradientStyle,
    });
  };
};

export const setLeaderboardPresence = (leaderboardPresence, updateInfoUser = false) => {
  return (dispatch, getState) => {
    const preferredLeaderBoardPresence = leaderboardPresence
      ? LEADERBOARD_PRESENCE.ENABLED
      : LEADERBOARD_PRESENCE.DISABLED;

    if (updateInfoUser && getState().user.isUserCompleted) {
      dispatch(updateUser(preferredLeaderBoardPresence, 'preferredLeaderBoardPresence'));
    }

    dispatch({
      type: actionTypes.SET_LEADERBOARD_PRESENCE,
      leaderboardPresence,
    });
  };
};

export const setNotificationChannels = (notificationChannels, updateInfoUser = false) => {
  return (dispatch, getState) => {
    const preferredNotificationChannels = notificationChannels
      ? NOTIFICATION_CHANNELS.ENABLED
      : NOTIFICATION_CHANNELS.DISABLED;

    if (updateInfoUser && getState().user.isUserCompleted) {
      dispatch(updateUser(preferredNotificationChannels, 'preferredNotificationChannels'));
    }

    dispatch({
      type: actionTypes.SET_NOTIFICATION_CHANNELS,
      notificationChannels,
    });
  };
};

export const setCatalogueDocTitle = catalogueDocTitle => {
  return {
    type: actionTypes.SET_CATALOGUE_DOC_TITLE,
    catalogueDocTitle: catalogueDocTitle,
  };
};

// PERSONALIZATION POPUP
export const showPersonalization = value => {
  return {
    type: actionTypes.SHOW_PERSONALIZATION,
    value,
  };
};

const GET_SURVEY_QUESTIONS_REQ_ACTIONS = {
  [SURVEY_TYPE.PERSONALIZATION]: actionTypes.GET_PERSONALIZATION_REQ,
  [SURVEY_TYPE.RATING]: actionTypes.GET_RATING_REQ,
  [SURVEY_TYPE.RATING_LP]: actionTypes.GET_RATING_REQ,
  [SURVEY_TYPE.RATING_LIVESTREAM]: actionTypes.GET_RATING_REQ,
  [SURVEY_TYPE.REVIEW]: actionTypes.GET_REVIEW_REQ,
  [SURVEY_TYPE.REVIEW_LP]: actionTypes.GET_REVIEW_REQ,
  [SURVEY_TYPE.REVIEW_LIVESTREAM]: actionTypes.GET_REVIEW_REQ,
};
const GET_SURVEY_QUESTIONS_SUCCESS_ACTIONS = {
  [SURVEY_TYPE.PERSONALIZATION]: actionTypes.GET_PERSONALIZATION_SUCCESS,
  [SURVEY_TYPE.RATING]: actionTypes.GET_RATING_SUCCESS,
  [SURVEY_TYPE.RATING_LP]: actionTypes.GET_RATING_SUCCESS,
  [SURVEY_TYPE.RATING_LIVESTREAM]: actionTypes.GET_RATING_SUCCESS,
  [SURVEY_TYPE.REVIEW]: actionTypes.GET_REVIEW_SUCCESS,
  [SURVEY_TYPE.REVIEW_LP]: actionTypes.GET_REVIEW_SUCCESS,
  [SURVEY_TYPE.REVIEW_LIVESTREAM]: actionTypes.GET_REVIEW_SUCCESS,
};
const GET_SURVEY_QUESTIONS_FAILURE_ACTIONS = {
  [SURVEY_TYPE.PERSONALIZATION]: actionTypes.GET_PERSONALIZATION_FAILURE,
  [SURVEY_TYPE.RATING]: actionTypes.GET_RATING_FAILURE,
  [SURVEY_TYPE.RATING_LP]: actionTypes.GET_RATING_FAILURE,
  [SURVEY_TYPE.RATING_LIVESTREAM]: actionTypes.GET_RATING_FAILURE,
  [SURVEY_TYPE.REVIEW]: actionTypes.GET_REVIEW_FAILURE,
  [SURVEY_TYPE.REVIEW_LP]: actionTypes.GET_REVIEW_FAILURE,
  [SURVEY_TYPE.REVIEW_LIVESTREAM]: actionTypes.GET_REVIEW_FAILURE,
};
export const getSurveyQuestions = (language, surveyType) => {
  return dispatch => {
    if (!language || !surveyType) {
      console.error('getSurveyQuestions - language or surveyType not specified', {
        language,
        surveyType,
      });
      return;
    }

    let url = urlList.GET_SURVEY_BY_TYPE + '?lingua=' + language;
    url = url.replace('{surveyType}', surveyType);

    let headers = getHeaders();
    //headers.token = getCookie(COOKIE.USER_TOKEN);

    dispatch(request());

    axios
      .get(url, { headers: headers })
      .then(response => {
        if (!response.data?.survey) {
          console.error('getSurveyQuestions has not returned a correct format', surveyType);
          dispatch(showError());
          dispatch(failure());
          dispatch(showPersonalization(false));
        }

        dispatch(success(response.data));
      })
      .catch(err => {
        console.error('getSurveyQuestions error', surveyType, err);
        dispatch(showError(err));
        dispatch(failure());
        dispatch(showPersonalization(false));
      });
  };

  function request() {
    return !GET_SURVEY_QUESTIONS_REQ_ACTIONS[surveyType]
      ? {}
      : {
        type: GET_SURVEY_QUESTIONS_REQ_ACTIONS[surveyType],
      };
  }

  function success(questions) {
    return !GET_SURVEY_QUESTIONS_SUCCESS_ACTIONS[surveyType]
      ? {}
      : {
        type: GET_SURVEY_QUESTIONS_SUCCESS_ACTIONS[surveyType],
        questions,
      };
  }

  function failure() {
    return !GET_SURVEY_QUESTIONS_FAILURE_ACTIONS[surveyType]
      ? {}
      : {
        type: GET_SURVEY_QUESTIONS_FAILURE_ACTIONS[surveyType],
      };
  }
};

const SEND_SURVEY_ANSWERS_REQ_ACTIONS = {
  [SURVEY_TYPE.PERSONALIZATION]: actionTypes.SEND_PERSONALIZATION_REQ,
  [SURVEY_TYPE.RATING]: actionTypes.SEND_RATING_REQ,
  [SURVEY_TYPE.RATING_LP]: actionTypes.SEND_RATING_REQ,
  [SURVEY_TYPE.RATING_LIVESTREAM]: actionTypes.SEND_RATING_REQ,
  [SURVEY_TYPE.REVIEW]: actionTypes.SEND_REVIEW_REQ,
  [SURVEY_TYPE.REVIEW_LP]: actionTypes.SEND_REVIEW_REQ,
  [SURVEY_TYPE.REVIEW_LIVESTREAM]: actionTypes.SEND_REVIEW_REQ,
};
const SEND_SURVEY_ANSWERS_SUCCESS_ACTIONS = {
  [SURVEY_TYPE.PERSONALIZATION]: actionTypes.SEND_PERSONALIZATION_SUCCESS,
  [SURVEY_TYPE.RATING]: actionTypes.SEND_RATING_SUCCESS,
  [SURVEY_TYPE.RATING_LP]: actionTypes.SEND_RATING_SUCCESS,
  [SURVEY_TYPE.RATING_LIVESTREAM]: actionTypes.SEND_RATING_SUCCESS,
  [SURVEY_TYPE.REVIEW]: actionTypes.SEND_REVIEW_SUCCESS,
  [SURVEY_TYPE.REVIEW_LP]: actionTypes.SEND_REVIEW_SUCCESS,
  [SURVEY_TYPE.REVIEW_LIVESTREAM]: actionTypes.SEND_REVIEW_SUCCESS,
};
const SEND_SURVEY_ANSWERS_FAILURE_ACTIONS = {
  [SURVEY_TYPE.PERSONALIZATION]: actionTypes.SEND_PERSONALIZATION_FAILURE,
  [SURVEY_TYPE.RATING]: actionTypes.SEND_RATING_FAILURE,
  [SURVEY_TYPE.RATING_LP]: actionTypes.SEND_RATING_FAILURE,
  [SURVEY_TYPE.RATING_LIVESTREAM]: actionTypes.SEND_RATING_FAILURE,
  [SURVEY_TYPE.REVIEW]: actionTypes.SEND_REVIEW_FAILURE,
  [SURVEY_TYPE.REVIEW_LP]: actionTypes.SEND_REVIEW_FAILURE,
  [SURVEY_TYPE.REVIEW_LIVESTREAM]: actionTypes.SEND_REVIEW_FAILURE,
};
export const sendSurveyAnswers = (answers, surveyType) => {
  return dispatch => {
    if (!answers || !surveyType) {
      console.error('sendSurveyAnswers - parameters are not correct', { answers, surveyType });
      return;
    }

    let url = urlList.SEND_SURVEY_RESPONSE;
    dispatch(request());

    axios
      .post(url, answers, { headers: getHeaders() })
      .then(response => {
        dispatch(success());

        switch (surveyType) {
          case SURVEY_TYPE.PERSONALIZATION:
            //update user profile
            dispatch(getUserProfile());
            dispatch(getCourses());
            break;
        }
      })
      .catch(err => {
        console.error('sendSurveyAnswers error', surveyType, err);
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return !SEND_SURVEY_ANSWERS_REQ_ACTIONS[surveyType]
      ? {}
      : {
        type: SEND_SURVEY_ANSWERS_REQ_ACTIONS[surveyType],
      };
  }

  function success() {
    return !SEND_SURVEY_ANSWERS_SUCCESS_ACTIONS[surveyType]
      ? {}
      : {
        type: SEND_SURVEY_ANSWERS_SUCCESS_ACTIONS[surveyType],
      };
  }

  function failure() {
    return !SEND_SURVEY_ANSWERS_FAILURE_ACTIONS[surveyType]
      ? {}
      : {
        type: SEND_SURVEY_ANSWERS_FAILURE_ACTIONS[surveyType],
      };
  }
};

export const resetRatingModal = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.RESET_RATING_SURVEY,
    });
  };
};

export const resetReviewModal = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.RESET_REVIEW_SURVEY,
    });
  };
};

export const setOpenMenu = open => {
  return {
    type: actionTypes.SET_OPEN_MENU,
    open,
  };
};

export const getHotL1 = (callL1Images = true) => {
  return dispatch => {
    dispatch(request());

    const limit = 12;

    let url = urlList.GET_HOT_CHANNELS;
    // + '?limit=' + limit;
    //+ '&followType=brand|channel'

    axios({
      url,
      method: 'GET',
      headers: getHeaders(),
    })
      .then(response => {
        if (!response?.data || !response.data[0]) {
          console.log(url + ' returned empty response', response?.data);
          return;
        }

        const hotL1 = response.data.sort((a, b) => b.followers - a.followers);
        const hotChannels = response.data
          .filter(a => a.followType === 'channel' || a.followType === 'brand')
          .map(a => a.followData);

        //get associated images
        dispatch(getL1Images(hotChannels));

        dispatch(success(hotL1));
      })
      .catch(err => {
        dispatch(error());
        dispatch(showError(err));
      });
  };

  function request() {
    return {
      type: actionTypes.GET_HOT_L1_REQUEST,
    };
  }

  function success(hotL1) {
    return {
      type: actionTypes.GET_HOT_L1_SUCCESS,
      hotL1,
    };
  }

  function error() {
    return {
      type: actionTypes.GET_HOT_L1_FAILURE,
    };
  }
};

export const getL1Map = (coursesMap = null, labelL1Map = null, labelL0Map = null) => {
  return (dispatch, getState) => {
    if (!coursesMap) {
      coursesMap = getState().course.coursesMap;
    }
    if (!labelL1Map) {
      labelL1Map = getState().utils.labelL1Map;
    }
    if (!labelL0Map) {
      labelL0Map = getState().utils.labelL0Map;
    }

    if (!coursesMap || !labelL1Map || Object.keys(labelL1Map)?.length < 1) {
      return;
    }

    let l1Map = {};

    Object.values(coursesMap).forEach(course => {
      course.catalogTypes?.forEach(l1 => {
        if (l1 && l1 !== 'null' && l1 !== 'nochannel') {
          l1Map[l1] = labelL1Map[l1];
          if (l1Map[l1]) {
            l1Map[l1].l0 = labelL0Map?.[course?.level0];
          }
        }
      });
    });

    console.log('l1Map', l1Map);

    dispatch({
      type: actionTypes.GET_L1_MAP,
      l1Map,
    });
  };
};

export const setShowLabelPreviewBanner = value => {
  return (dispatch, getState) => {
    let showLabelPreviewBanner = getState().utils.showLabelPreviewBanner;

    const isAuthenticated = getState().authentication.isAuthenticated;
    if (!isAuthenticated) {
      //if user is not authenticated --> no label preview
      value = false;
    }

    //if the value is changed
    if ((value && !showLabelPreviewBanner) || (!value && showLabelPreviewBanner)) {
      dispatch({
        type: actionTypes.SET_SHOW_LABEL_PREVIEW,
        value,
      });
    }
  };
};

export const setShowCMSPreviewBanner = value => {
  return (dispatch, getState) => {
    let showCMSPreviewBanner = getState().utils.showCMSPreviewBanner;

    //since CMS NEW PAGES preview should be available for public pages as well
    //const isAuthenticated = getState().authentication.isAuthenticated;
    // if (!isAuthenticated) {
    //   //if user is not authenticated --> no CMS preview
    //   value = false;
    // }

    //if the value is changed
    if ((value && !showCMSPreviewBanner) || (!value && showCMSPreviewBanner)) {
      dispatch({
        type: actionTypes.SET_SHOW_CMS_PREVIEW,
        value,
      });
    }
  };
};

export const setShowCollectionPreviewBanner = value => {
  return (dispatch, getState) => {
    let showCollectionPreviewBanner = getState().utils.showCollectionPreviewBanner;

    const isAuthenticated = getState().authentication.isAuthenticated;
    if (!isAuthenticated) {
      //if user is not authenticated --> no collection preview
      value = false;
    }

    //if the value is changed
    if ((value && !showCollectionPreviewBanner) || (!value && showCollectionPreviewBanner)) {
      dispatch({
        type: actionTypes.SET_COLLECTION_PREVIEW,
        value,
      });
    }
  };
};

export const setPromptMethod = promptMethod => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_PROMPT_METHOD,
      promptMethod: promptMethod,
    });
  };
};

export const setShowCelebrationModal = (show, courseId, username = '') => {
  return (dispatch, getState) => {
    if (!username) {
      username = getState().user.userProfile?.username;
    }

    if (!show && courseId && username) {
      //update celebration modal
      handleCloseCelebrationInLocalStorage(courseId, username);
    }

    dispatch({
      type: actionTypes.SET_SHOW_CELEBRATION_MODAL,
      show,
      courseId,
    });
  };
};

export const downloadOnboardingCertificatePdf = (courseId, courseFullName, finallyCallback) => {
  return (dispatch, getState) => {
    if (!courseId) {
      console.error('downloadOnboardingCertificatePdf - param courseId not present');
      return;
    }

    dispatch(request());

    let url = urlList.DOWNLOAD_ONBOARDING_CERTIFICATE.replace('{courseId}', courseId);

    axios
      .get(url, {
        headers: getHeaders('text/plain'),
      })
      .then(response => {
        let lang = getState().utils.lang;

        //create filename
        let filename = (lang.ONBOARDING_CELEBRATION_PDF_FILENAME || '')?.replace(
          '{filename}',
          courseFullName || ''
        );
        filename = filename.endsWith('.pdf') ? filename : filename + '.pdf';

        //download base 64 response with specified filename
        downloadBase64(response.data, filename, 'application/pdf');
      })
      .catch(err => {
        dispatch(showError(err));
      })
      .finally(() => {
        dispatch(end());
        if (finallyCallback) {
          finallyCallback();
        }
      });
  };

  function request() {
    return {
      type: actionTypes.GET_ONBOARDING_CERTIFICATE_PDF_REQUEST,
    };
  }

  function end() {
    return {
      type: actionTypes.GET_ONBOARDING_CERTIFICATE_PDF_END,
    };
  }
};

export const setShowAreaOfExpertiseModal = show => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_SHOW_AREA_OF_EXPERTISE,
      show: show,
    });
  };
};

export const setVisionCareMenuModal = show => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_SHOW_VISIONE_CARE_MENU,
      show: show,
    });
  };
};


export const setShowCollectionsMenuModal = show => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_SHOW_COLLECTIONS_MENU_MODAL,
      show: show,
    });
  };
};

export const setShowEdPathMenuModal = show => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_SHOW_ED_PATH_MENU_MODAL,
      show: show,
    });
  };
};

/**
 * If set to true enables the IframePopup checking the history delta after 750ms and manage. (default behaviour)
 * Viceversa, to false, disable that behaviour.
 *
 * <b>IMPORTANT</b>: in coursesActions (handleCloseIframe) each time the IframePopup close this check is resetted to true,
 *  so "default behaviour" will occur. Need to set again this flag to false each time if needed to manage the history.
 *
 * @see IframePopup (handleCloseWrapper)
 * */
export const setAutoManageHistoryIframeOnClose = (auto = true) => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_AUTO_MANAGE_HISTORY_IFRAME_ON_CLOSE,
      autoManageHistoryIframeOnClose: auto
    });
  };
}

export const setShowModalByType = (type, l0Id = '') => {

  return dispatch => {
    dispatch({
      type: actionTypes.SET_SHOW_MODAL_BY_TYPE,
      value: type,
      areaOfExpertiseL1MenuL0Id: l0Id,
    });
  };
};

export const setTeacherUsername = value => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_TEACHER_USERNAME,
      value,
    });
  };
};

export const setShowModalContactCustomerService = show => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_SHOW_MODAL_CONTACT_CUSTOMER_SERVICE,
      show: show,
    });
  };
};

export const setShowModalRequestJoin = show => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_SHOW_MODAL_REQUEST_JOIN,
      show: show,
    });
  };
};

/*
export const setShowLabelPersonificationBanner = value => {
  setCookie(
      PERSONIFICATION_COOKIE_KEYS.showUserGroupBanner,
      value,
      new Date().getTime() + 1000 * 60 * 60 * 24
  );

  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_SHOW_LABEL_PERSONIFICATION,
      value,
    });
  };
};
*/

// export const setAlreadyInSimulation = value => {
//   return dispatch => {
//     dispatch({
//       type: actionTypes.SET_ALREADY_IN_SIMULATION,
//       value,
//     });
//   };
// };
export const setUserSimulated = value => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_USER_SIMULATED,
      value,
    });
  };
};
export const setShowLabelPersonificationBanner = value => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_SHOW_LABEL_PERSONIFICATION_BANNER,
      value,
    });
  };
};
export const setShowDialogUserGroupSimulationPage = value => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_SHOW_DIALOG_USERGROUPSIMULATION_PAGE,
      value,
    });
  };
};
export const setHideBannerWasClicked = value => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_HIDE_BANNER_WAS_CLICKED,
      value,
    });
  };
};
export const setExitBannerWasClicked = value => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_EXIT_BANNER_WAS_CLICKED,
      value,
    });
  };
};
export const setShowDialogUserGroupSimulationHide = value => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_SHOW_DIALOG_USERGROUPSIMULATION_HIDE,
      value,
    });
  };
};

export const getHeaderMenuViaCMSRow = links => {
  return dispatch => {
    dispatch({
      type: actionTypes.GET_LINKS_HEADER_FROM_CMS,
      links
    });
  };
  }

export const setLPInSavingProgress = (courseId, lpId) => {
  // debugger
  return dispatch => {
    dispatch({
      type: actionTypes.SET_LP_IN_SAVING_PROGRESS,
      value: {courseId: courseId,
             lpId: lpId},
    });
  };
};

export const unsetLPInSavingProgress = courseId => {
  return dispatch => {
    dispatch({
      type: actionTypes.UNSET_LP_IN_SAVING_PROGRESS,
      value: {courseId: courseId},
    });
  };
};

export const getButtonHeaderFromCMS = buttonsFromCMS => {
  return dispatch => {
    dispatch({
      type: actionTypes.GET_BUTTON_HEADER_FROM_CMS,
      buttonsFromCMS
    });
  };
  }