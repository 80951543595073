import React, { useEffect, useState } from 'react';
import { Course, LangMap } from '@model/CoursesClass';
import { useDispatch } from 'react-redux';
import ButtonV2 from '@components/UI/ButtonV2';
import { canCourseBeAccessed, isInPlan, isPurchasable } from '@utility/ecommerceUtility';
import * as actions from '@redux-actions/';
import Counter from '@components/UI/Counter';
import { formatCurrency, isCourseToBeStarted, printHTML } from '@utility/Api';
import { ProductCart } from '@model/CartModel';
import { ADD_TO_CART_STEPS, COOKIE } from '@utility/const';
import { USER_URLS } from '@components/link-utils';
import { useHistory } from 'react-router-dom';
import { useSelector } from '@hooks/redux';
import useCookie from '@hooks/useCookie';
import { PlanModel } from '@model/PlanModel';
import { UserProfile } from '@model/User';
import RefundAndCancellationPolicy from '@components/UI/RefundAndCancellationPolicy';
import selectPlanStates, {
  getPlanCTALabel,
  isExcludedFromPlan,
} from '@utility/ecommercePlanUtility';
import PlanExpiredMessage from './PlanExpiredMessage';
import LazySVG from '@components/LazySvg';
import {
  canMasterShowWaitingListRequest,
  getMasterAvailableSeats,
  isCourseMaster,
  isCourseSoldout,
  isLessonInsideMaster,
  isUserInWaitingListMaster,
} from '@utility/onBoardingCourseUtility';
import clsx from '@utility/clsx';
import { useBreakpoint, useBreakpointBoolean } from '@hooks/createBreakpoint';

class Props {
  variant?: 'card-overview' | 'plan' | 'free-plan';
  hideLicensePolicy?: boolean;
  course?: Course;
  showPreview?: boolean;
  handleShowPreview?: () => void;
  labelPreview?: string;
  initialValue?: number;
  showRefundCancellationPolicy?: boolean;
  hideQuantity?: boolean;
  priceLeft?: boolean;
  lineThroughPrice?: boolean;
  labelCTA?: string;
  isStartedCourse?: boolean;
  loading?: boolean;
  secondaryButton?: { label: string; action: () => void };
  buyNowVariant?: string;
  isBuyMoreLicenses?: boolean;
}

class MasterInfoClass {
  soldOut: boolean = false;
  firstLabel: string = '';
  secondLabel: string = '';
  hidePrice: boolean = false;
  showWaitingList: boolean = false;
  isUserInWaitingList: boolean = false;
  showContactCustomerService: boolean = false;
}

const BuyLicenseSection = (props: Props) => {
  const {
    variant = '',
    hideLicensePolicy = false,
    showPreview = false,
    handleShowPreview = () => { },
    labelPreview = '',
    initialValue = 1,
    secondaryButton,
    buyNowVariant = 'primary',
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const plan: PlanModel = useSelector(state => state.ecommerce.plan);
  const userProfile: UserProfile = useSelector(state => state.user.userProfile);
  const breakpoint = useBreakpointBoolean();

  //Retrieve info from CMS
  const courseIdMaster = props.course?.courseIdMaster;
  const onboardingOverviewInfo = useSelector(
    state => state.cms.onboardingOverview
  );
  
  const [row2Items, setRow2Items] = useState({});

  useEffect(() => {
    if (!!onboardingOverviewInfo && !!courseIdMaster){
      const tempRow2Items = onboardingOverviewInfo[courseIdMaster]?.data?.placements["row2"][0]?.items[0];

      if (typeof tempRow2Items !== "undefined" && tempRow2Items !== null){
        if (Object.keys(tempRow2Items).length > 0){
          setRow2Items(tempRow2Items);
        }
      }
    }
  }, [onboardingOverviewInfo, courseIdMaster]);
  

  const openLink = (e, link) => {
    e.stopPropagation();
    window.open(link);
  };

  const isPlanFree = (): boolean => {
    return !!plan && variant === 'free-plan';
  };

  const lang: LangMap = useSelector(state => state.utils.lang);
  const isLoadingCart = useSelector(state => state.ecommerce.isLoadingCart);
  const isLoading = useSelector(
    state => state.ecommerce.isLoadingCart || state.ecommerce.isSubscriptionListLoading
  );

  const [counter, setCounter] = useState<number>(initialValue);
  const [maxCounterPurchase, setMaxCounterPurchase] = useState<number>(undefined);
  const [masterInfo, setMasterInfo] = useState(new MasterInfoClass());

  useEffect(() => {
    let maxCounterTemp = undefined;
    if (isCourseMaster(props.course)) {
      //calculate remaining master tokens
      maxCounterTemp = getMasterAvailableSeats(props.course);
    }
    setMaxCounterPurchase(maxCounterTemp);
  }, [props.course]);

  useEffect(() => {
    if (isCourseMaster(props.course)) {
      const showWaitingListTemp = canMasterShowWaitingListRequest(props.course);
      const isUserInWaitingListMasterTemp = isUserInWaitingListMaster(props.course, userProfile);
      const showContactCustomerServiceTemp = true;

      let firstLabel = '';
      if (isUserInWaitingListMasterTemp) {
        firstLabel = lang?.WAITING_LIST_REQUEST_SENT_TITLE;
      } else if (isCourseSoldout(props.course)) {
        firstLabel = lang?.MASTER_SOLDOUT_FIRST_LABEL;
      }

      setMasterInfo({
        soldOut: isCourseSoldout(props.course) || showWaitingListTemp,
        firstLabel: firstLabel,
        secondLabel: '', //WAITING LIST IS OOO // isUserInWaitingListMasterTemp ? lang?.WAITING_LIST_REQUEST_SENT_DESCR : lang?.MASTER_SOLDOUT_SECOND_LABEL,
        hidePrice: isCourseSoldout(props.course), //WAITING LIST IS OOO
        showWaitingList: showWaitingListTemp,
        isUserInWaitingList: isUserInWaitingListMasterTemp,
        showContactCustomerService: showContactCustomerServiceTemp,
      });
    }
  }, [props.course, lang, userProfile]);

  const handleClickSeePlanDetails = () => {
    dispatch(actions.setShowModalPlan(true));
  };

  const handleBuy = () => {
    if (variant === 'plan' || isPlanFree()) {
      dispatch(
        actions.insertProductInCart(
          new ProductCart(plan.id.toString(), counter),
          ADD_TO_CART_STEPS.PLAN
        )
      );
    } else if (props.course) {
      dispatch(
        actions.insertProductInCart(
          new ProductCart(props.course.courseIdMaster, counter),
          ADD_TO_CART_STEPS.NO_MODAL
        )
      );
    }
  };

  const handleRequestToJoin = () => {
    dispatch(actions.setShowModalRequestJoin(true));
  };

  const handleContactCustomerService = () => {
    dispatch(actions.setShowModalContactCustomerService(true));
  };

  const getPrice = (): string => {
    if (variant === 'plan' || isPlanFree()) {
      return plan.price;
    }
    return props.course?.price;
  };

  const getUnit = () => {
    if (variant === 'plan' || variant === 'free-plan') {
      return lang?.YEARLY;
      // return lang?.LICENSES_NUM?.replace("{NUM}", plan?.tokenNum?.toString());
    }
    return lang.UNIT_PRICE;
  };

  const planStates = useSelector(selectPlanStates);

  const [planPurchasedCookie] = useCookie(COOKIE.PLAN_PURCHASED);

  return (
    <section
      className={clsx('buy-license-section', {
        [variant]: variant,
        'buy-license-section__sold-out': masterInfo.soldOut,
      })}
      aria-label={getPlanCTALabel(planStates, lang)}
    >
      <div className='price-number-container'>
        <div className={'price-row ' + (props.priceLeft ? 'price-left' : '')}>
          {!props.hideQuantity && !masterInfo.soldOut && !masterInfo.isUserInWaitingList && (
            <div className="number-licenses-container">
              <span className="number-licenses-label">{`${lang.QUANTITY_LONG}: `}</span>
              <Counter
                counter={counter}
                setCounter={setCounter}
                variant={variant}
                id={plan?.id}
                max={maxCounterPurchase}
                canSeeTooltip={!isNaN(maxCounterPurchase)}
              />
            </div>
          )}
          {(masterInfo.soldOut || masterInfo.isUserInWaitingList) && (
            <div
              className={
                'buy-license-section__sold-out-container ' +
                (masterInfo.isUserInWaitingList ? 'request-sent' : '')
              }
            >
              {masterInfo.firstLabel && (
                <span className="buy-license-section__sold-out-first-label">
                  {masterInfo.firstLabel}
                </span>
              )}
              {masterInfo.secondLabel && (
                <span className="buy-license-section__sold-out-second-label">
                  {masterInfo.secondLabel}
                </span>
              )}
            </div>
          )}

          {
            //!masterInfo.hidePrice &&
            <div className="price-container">
              <span
                className={
                  'price ' + (props.lineThroughPrice && !planPurchasedCookie ? 'strike' : '')
                }
              >
                {formatCurrency(getPrice(), userProfile?.ecommerceInfo?.currency, userProfile?.preferredLang, variant === "free-plan" ? true : false)}
              </span>
              {!isCourseMaster(props.course) && (
              <span
                className="single-license-label"
                dangerouslySetInnerHTML={printHTML(getUnit())}
              />
            )}
            </div>
          }
        </div>
        <div className="buy-button-container">
          {showPreview && (
            <div className="card-v3-overview__button-preview-icon-container">
              <ButtonV2
                variant="icon-btn"
                small
                className="card-v3-overview__button-preview-icon"
                handleClick={handleShowPreview}
              >
                <LazySVG src="/images/svg-icons/play-no-circle.svg" alt={labelPreview} />
              </ButtonV2>
              <span className="card-v3-overview__button-preview-text">{labelPreview}</span>
            </div>
          )}

          {variant !== 'card-overview' &&
            variant &&
            ((planStates.showCta && !planPurchasedCookie) || isLoading) && (
              <ButtonV2
                variant="primary"
                small
                className="buy-button"
                handleClick={handleBuy}
                loading={isLoading}
              >
                {props.labelCTA || getPlanCTALabel(planStates, lang)}
              </ButtonV2>
            )}

          {secondaryButton && (
            <ButtonV2
              variant="secondary"
              small
              className="secondary-button"
              handleClick={secondaryButton.action}
            >
              {secondaryButton.label}
            </ButtonV2>
          )}
          {variant !== 'card-overview' &&
            variant &&
            planStates.showActivatePlan &&
            !planStates.showCta &&
            !isLoading && (
              <ButtonV2
                variant="primary"
                small
                className="buy-button"
                handleClick={() => {
                  history.push(
                    USER_URLS.PROFILE_ORDER_HISTORY_DETAILS.NORMAL_URL + '/' + planStates?.orderId
                  );
                }}
              >
                {lang.ACTIVATE_PLAN}
              </ButtonV2>
            )}

          {(variant === 'card-overview' || !variant) && (
            <>
              {(lang.BUY_NOW || isLoadingCart) && !masterInfo.soldOut && (
                <ButtonV2
                  variant={buyNowVariant}
                  className="buy-button"
                  handleClick={handleBuy}
                  loading={isLoadingCart || props?.loading}
                >
                  {lang.BUY_NOW}
                </ButtonV2>
              )}
              {masterInfo.showWaitingList && !masterInfo.isUserInWaitingList && (
                <ButtonV2
                  variant={buyNowVariant}
                  small
                  className="buy-button"
                  handleClick={handleRequestToJoin}
                  loading={props?.loading}
                >
                  {lang.REQUEST_TO_JOIN}
                </ButtonV2>
              )}
            </>
          )}
        </div>
      </div>
      {(isInPlan(props.course) || masterInfo.showContactCustomerService || props.showRefundCancellationPolicy) &&
        <div className="course-in-plan">
          {isCourseMaster(props.course) && 
            (isPurchasable(props.course) || props.isBuyMoreLicenses) && 
            !!onboardingOverviewInfo && (
              <div className="download-brochure-button">
                <ButtonV2
                  className="download-brochure"
                  variant="text-btn-no-arrow"
                  ariaLabel={row2Items?.text3 + ' ' + props.course.courseFullName}
                  handleClick={e => openLink(e, row2Items?.extendedFields?.content2[0]?.path)}
                  data-element-id={'main_brochure_download'}
                >
                  <LazySVG
                    className="area-of-expertise__l1-channel-arrow"
                    src={'/images/svg-icons/download-certificate.svg'}
                    alt='row2Items.text3'
                  />
                  {row2Items?.text3}
                </ButtonV2>
              </div>
            )}
          {masterInfo.showContactCustomerService && (
            <ButtonV2
              className={
                'buy-license-section__contact-customer-service ' +
                (props.isBuyMoreLicenses ? 'desktop' : '')
              }
              variant="text-btn-no-arrow"
              small
              handleClick={handleContactCustomerService}
            >
              {lang.CONTACT_CUSTOMER_SERVICE}
            </ButtonV2>
          )}
          {isInPlan(props.course) && (
            <>
              <span className="course-in-plan-text">{lang.COURSE_IS_PART_PLAN + ' '}</span>
              <ButtonV2
                variant="text-btn-no-arrow"
                small
                className="course-in-plan-text course-in-plan-text-link"
                handleClick={handleClickSeePlanDetails}
              >
                {lang.DISCOVER_MORE}
              </ButtonV2>
            </>
          )}

          {props.showRefundCancellationPolicy && (
            <div className="license-policy">
              <RefundAndCancellationPolicy />
            </div>
          )}
          {(variant === 'card-overview' || !variant) &&
            !canCourseBeAccessed(props.course) &&
            !isCourseToBeStarted(props.course) &&
            !isExcludedFromPlan(props.course) && <PlanExpiredMessage />}
        </div>
      }


    </section>
  );
};

export default BuyLicenseSection;
