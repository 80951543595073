import { titles as PageTitles } from '@utility/pageTitles';
import { RootState } from 'src/store/store';
import { UserProfile } from './User';
import { isCurrentPublicPage, isMobile } from '@utility/Api';
import i18n from 'i18next';
import { isPersonificationOn } from '../pages_v3/UserGroupSimulation/UserGroupSimulation_PersonificateLogic';

export enum PageTypes {
  login = 'login',
  home = 'Home',
  landing = 'Landing',
  register = 'login',
  clp = 'Clp',
  category = 'category',
  search = 'Search',
  search_results = 'search_results',
  product = 'product',
  favourite = 'favourite',
  notifications = 'notifications',
  static = 'Static',
  not_found = '404',
  maintenance = 'maintenance',
  compatibility = 'compatibility',
  pill = 'pill',
  plan = 'plan',
  commercial_offer = 'commercial_offer',
  cart = 'CartPage',
  billing = 'billing',
  delivery = 'Delivery',
  payment = 'Payment',
  purchase = 'purchase',
  licence = 'licence',
  history = 'history',
  token = 'token',
  onboardingStart = 'start',
  onboardingOverview = 'overview',
  onboardingLessons = 'lessons',
  onboardingTeachers = 'teachers',
  managerTeam = 'manager_team',
  managerUser = 'manager_user',
  educationalPaths = 'educational_paths',
  buildYourCareer = 'build_your_career',
  welcomeLogin = 'login',
  welcomeHome = 'home',
  plp = 'Plp',
  pdp = 'Pdp',
  thankyou = 'Thankyou',
  error = 'Error'
}

export enum UserTypes {
  guest = 'guest',
  essilor = 'essilor',
  luxottica = 'luxottica',
  registered = 'registered',
  personification = 'personification'
}

export enum PageSections {
  catalog = 'Catalog',
  account = 'Account',
  checkout = 'Checkout',
  onboarding = 'onboarding',
  welcome = 'welcome',
  landing = '',
  register = 'register',
  home = 'Home',
  highlightscollections = 'highlightscollections',
  login = "Login",
  editorial = 'Editorial',
  other = "Other",
  commercialoffer = "CommercialOffer",
  plan = "Plan",
  cart = "CartPage",
  subcatalog = "Subcatalogue",
  notfound = '404'
}

export enum TealiumEventsDataLayer {
  page_view = 'VirtualPage-View',
  category_view = 'category_view',
  product_view = 'product_view',
  search = 'search',
  error = 'error',
  cart_empty = 'cart_empty',
  cart_view = 'cart_view',
  checkout = 'checkout',
  purchase = 'purchase',
  mouseover = 'mouseover',
}

export enum ErrorTypes {
  user = 'User',
  server = 'Server',
  frontend = 'frontend',
  catalog = 'catalog',
  product = 'product',
}

export type DataLayerTracking = {
  // site ////////////////////
  Page_Country?: UserProfile['country']; // iso
  Page_Language?: UserProfile['preferredLang']; // iso
  ///////////////////////////
  Page_Section2?: PageTitles | string;
  Page_Type?: PageTypes | string;
  Page_Section1?: PageSections;
  Page_Section3?: string;
  page_subcategory?: string;
  User_LoginStatus?: UserTypes | string;
  User_Id?: UserProfile['username'];
  user_timeontraining?: string;
  id?: TealiumEventsDataLayer | TealiumEvents; // event
  browse_refine_type?: Array<string>;
  browse_refine_value?: Array<string>;
  browse_refine_sorting?: string;
  Search_Keyword?: string;
  Search_ResultItemsQnt?: string;
  Search_FacetValues_String?: string; 
  Search_Type?: string;
  Products_Category_Array?: Array<string>;
  Products_Domain_Array?: Array<string>;
  product_subcategory?: string;
  Products_Id_Array?: Array<string>;
  Products_ModelName_Array?: Array<string>;
  Products_ContextId_Array?: Array<string>;
  Products_Language_Array?: Array<string>
  product_format?: Array<string>;
  Products_Mandatory_Array?: Array<string>;
  error_type?: ErrorTypes;
  error_description?: string;
  error_message?: string;
  Page_DeviceType?: string;
  Products_Units_Array?: Array<string>;
  Products_PriceFull_Array?: Array<string>;
  Products_RevenuePerUnit_Array?: Array<string>;
  product_status?: Array<'available' | 'expired'>;
  licence_destination?: Array<'me' | 'other'>;
  licence_status?: Array<'valid' | 'renewal' | 'expired'>;
  Order_CartId?: string;
  order_promo_code?: string;
  Order_Currency?: string;
  order_discount_amount?: string;
  order_shipping_amount?: string;
  order_shipping_type?: string;
  order_subtotal?: string;
  order_tax_amount?: string;
  order_total?: string;
  Order_Id?: string;
  order_payment_type?: string;
  Order_SubscriptionFrequency?: '1' | '0';
  User_MoodleID?: string;
  "data-description"?: string;
  "data-element-id"?: string;
};

export type tealiumEventsOnAction = {
  Events_CartAdd?: '1',
  Events_UserLogin?: '1',
  Events_UserAccountNew?: '1',
  Events_CourseStart?: '1',
  Events_ProdFavAdd?: '1',
  Events_ActionContactUs?: '1',
  Events_LicenceActivation?: '1',
  Events_BookingEvent?: '1',
  Events_SearchFiltering?: '1',
};

export enum TealiumEvents {
  user_login = 'user_login',
  user_logout = 'user_logout',
  user_register = 'user_register',
  start = 'start',
  continue = 'continue',
  download = 'download',
  book = 'book',
  fav_add = 'fav_add',
  email_signup = 'email_signup',
  error = 'error',
  cart_add = 'cart_add',
  promo_add = 'promo_add',
  activate_license = 'activate_license',
  activate_token = 'activate_token',
  renewal_on = 'renewal_on',
  renewal_off = 'renewal_off',
  hero_banner_view = 'hero_banner_view',
  hero_banner_click = 'hero_banner_click',
  carousel_click_content = 'carousel_click_content',
  carousel_click_arrow = 'carousel_click_arrow',
  carousel_collections_click_card = 'carousel_collections_click_card',
  carousel_collections_click_subelement = 'carousel_collections_click_subelement',
  search_panel_click_subelement = ' search_panel_click_subelement',
  notification_click_card = 'notification_click_card',
  impression = 'Impression',
  mouseover = 'mouseover',
}

export type ErrorTracking = {
  Error_Source: ErrorTypes;
  Error_Code?: string,
  Error_Detail?: string,
  Error_Message?: string,
  Events_Error?: string
}

export type EventsTracking = {
  id: TealiumEvents; // event
  Products_Category_Array?: Array<string>;
  product_subcategory?: Array<string>;
  Products_Id_Array?: Array<string>;
  Products_ModelName_Array?: Array<string>;
  product_format?: Array<string>;
  Products_Mandatory_Array?: Array<string>;
  error_type?: ErrorTypes;
  error_description?: string;
  error_message?: string;
  Page_DeviceType: 'web' | 'app';
  Products_Units_Array?: Array<string>;
  Products_PriceFull_Array?: Array<string>;
  Products_RevenuePerUnit_Array?: Array<string>;
  licence_destination?: Array<'me' | 'other'>;
  Order_CartId?: string;
  order_promo_code?: string;
  Order_Currency?: string;
  "data-description"?: string;
  "data-element-id"?: string;
};

export const selectTrackingDataLayerBase = (state: RootState) : DataLayerTracking => {
  const userProfile: UserProfile = state?.user?.userProfile;
  const isUserCompletedAnalytics = state?.user?.isUserCompletedAnalytics;
  //const { i18n } = useTranslation();
  const isPublicPage = isCurrentPublicPage();

  //TODO: dopo aver aggiunto isPublicPage bisognerebbe provare a
  //sostituire questo stato con isFullUserCompleted visto che non ci serve nelll'if-else sotto

  if (!!userProfile && isUserCompletedAnalytics) { 
    return {
      Page_Language: userProfile?.preferredLang?.toUpperCase(),
      Page_Country: userProfile?.country?.toUpperCase(),
      User_LoginStatus: isPersonificationOn() ? UserTypes.personification : UserTypes.registered,
      User_Id: userProfile?.moodleId?.toLowerCase(),
      //user_timeontraining: timeOnTraining,
      Page_DeviceType: 'all',
      Order_Currency: userProfile?.ecommerceInfo.currency,
      //User_MoodleID: userProfile?.moodleId, 
    };
  }

  //not authenticated user
  if(!userProfile && isPublicPage) {
    return {
      Page_DeviceType: 'all',
      User_LoginStatus: UserTypes.guest,
      Page_Language: i18n.language?.toUpperCase(),
    }
  }

  return null;
};
