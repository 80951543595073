import React, { useState, useEffect } from 'react';
import { useSelector } from '@hooks/redux';
import ButtonV2 from '@components/UI/ButtonV2';
import LinkExternalIcon from '@svg-icons/linkexternal.svg';
import clsx from '@utility/clsx';
import DetailsSingleActivityButton from '@components/DetailsSingleActivityButton';
import TooltipV2 from '../CardV3/TooltipV2';
import { Course } from '@model/CoursesClass';
import Arrow from '@svg-icons/arrowhead-up.svg';
import InfoIcon from '@svg-icons/info2.svg';
import { goToUrl, printHTML, removeHTMLTags } from '@utility/Api';
import { AKAMAI_IMAGE_LEONARDO_URL } from '@utility/const';
import CardCover from '@components/CardV3/CardCover';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';
import { groupCSS } from 'react-select/src/components/Group';
import { purifyItemDataElementIdCamelCase } from '@utility/analytics-utils';
import { cmMetaData } from '@utility/previewUtils';
import LazySVG from '@components/LazySvg';

enum HarvardType {
  mentor = 'mentor',
  spark = 'spark',
}

const getURLAkamaiHarvard = (type: HarvardType): string => {
  return `${AKAMAI_IMAGE_LEONARDO_URL}/course_logos/harvard_${type}.svg`;
};

type CoverHarvardType = {
  type: string;
};

type ButtonHarvardType = {
  textCTA: string;
  labelActionContentId?: number;
  linkBtnExternal: string;
  tooltip?: string;
  itemContentId?: string;
  tooltipToLeft?: boolean;
  handleClickCTA?: () => void;
  isExternalAction?: boolean;
};

type BannerHarvardType = {
  // handleClickExternal: (string) => void,
  image: string;
  contentText: JSX.Element;
  learningAreas: JSX.Element;
  imageDesktopContentId?: number;
  imageMobile?: string;
  imageMobileContentId?: number;
};

type LinksCourseExternal = {
  url: string;
  title: string;
  titleContentId?: number;
};

type Props = {
  course?: Course;
  typeHarvard: string /*   HarvardType.mentors | HarvardType.spark, */;
  title: string;
  description: string;
  linksCourseExternal?: Array<LinksCourseExternal>;
  image: string;
  imageMobile: string;
  variant: 'big' | 'card-image';
  action: () => void;
  text1: string;
  mainContentId?: number;
  itemContentId?: number;
} & ButtonHarvardType;

const CoverHarvard = (props: { type: string }) => {
  switch (props.type?.toLowerCase()) {
    case HarvardType.mentor: {
      return (
        //<CardCover className="cover_harvard" image={getURLAkamaiHarvard(HarvardType.mentor)} courseFullName='Harvard Mentor' hideShadow/>
        // <img
        //   className="cover_harvard"
        //   src={getURLAkamaiHarvard(HarvardType.mentor)}
        //   alt="Harvard Mentor"
        // />
        <LazySVG className="cover_harvard" src={getURLAkamaiHarvard(HarvardType.mentor)} alt={"Harvard Mentor"} />
      );
    }
    case HarvardType.spark: {
      return (
        //<CardCover className="cover_harvard" image={getURLAkamaiHarvard(HarvardType.spark)} courseFullName='Harvard Spark' hideShadow/>
        // <img
        //   className="cover_harvard"
        //   src={getURLAkamaiHarvard(HarvardType.spark)}
        //   alt="Harvard Spark"
        // />
        <LazySVG className="cover_harvard" src={getURLAkamaiHarvard(HarvardType.spark)} alt={"Harvard Spark"} />
      );
    }
    default:
      return null;
  }
};

const ButtonHarvard = (props: ButtonHarvardType & { course?: Course }) => {
  const lang = useSelector(state => state.utils.lang);
  const [tooltipShow, setTooltipShow] = useState<boolean>(false);

  const openHandleToggleTooltip = () => {
    if(tooltipShow) return
    setTooltipShow(true);
  };
  const closeHandleToggleTooltip = () => {
    if(!tooltipShow) return
    setTooltipShow(false);
  };

   const toggleHandleToogleTooltip = ()=>{
      if(tooltipShow) return closeHandleToggleTooltip()
      openHandleToggleTooltip()
  }
  const isByCourse: boolean = !!props.course;

  const contentButton = (
    <>
      <span aria-label={props.textCTA} {...props?.labelActionContentId && cmMetaData(props?.labelActionContentId)}>{props.textCTA}</span>
      {props.isExternalAction && (
        <LinkExternalIcon height={16} className="banner_harvard_btn_icon" />
      )}
    </>
  );
//“home_banner_$BannerName$_$LinkName$”
const dataElementId =   props.typeHarvard == HarvardType.spark ? 'home_banner_Harvard_HarvardSpark' : `home_banner_${purifyItemDataElementIdCamelCase((removeHTMLTags(props.title))?.toLowerCase())}_${purifyItemDataElementIdCamelCase(props.textCTA)}`
return (
    <div
      className={clsx(
        'banner_harvard_wrapper_btn',
        props.tooltipToLeft && 'banner_harvard_wrapper_btn--tooltip-left'
      )}
    >
      {isByCourse ? (
        <>
          <DetailsSingleActivityButton
            // className='banner_harvard_btn'
            lang={lang}
            course={props.course || null}
            buttonVariant="primary"
            subtitle={null}
            isLoading={false}
            harvardCTA={contentButton}
          />
        </>
      ) : ( props.textCTA !== null &&
        <>
        
          <ButtonV2
            handleClick={props.handleClickCTA}
            variant="primary"
            className="banner_harvard_btn"
            data-element-id={dataElementId}
          >
            {contentButton}
          </ButtonV2>
        </>
      )}

      {!!props.tooltip && (
        <div
          className="banner_harvard_tooltip"
          tabIndex={0}
          onMouseEnter={openHandleToggleTooltip}
          onMouseLeave={closeHandleToggleTooltip}
          onFocus={openHandleToggleTooltip}
          onBlur={closeHandleToggleTooltip}
          onMouseDown={toggleHandleToogleTooltip}
          {...props?.itemContentId && cmMetaData(props?.itemContentId)}
        >
          <InfoIcon/>
          <TooltipV2 label={props.tooltip} show={tooltipShow} />
        </div>
      )}
    </div>
  );
};

const BannerHarvardText = (props: Props) => {
  const breakpoint = useBreakpointBoolean();

  return (
    <div className="banner_harvard_content_text">
      {props.typeHarvard && (
        <div className="banner_harvard_cover">
          <CoverHarvard type={props.typeHarvard} />
        </div>
      )}
      <p
        className="banner_harvard_title"
        dangerouslySetInnerHTML={printHTML(props.title)}
        role='region'
        aria-label={removeHTMLTags(props.title)}
        {...props?.itemContentId ? cmMetaData(props?.itemContentId): null}
      />
      <CardCover
        className="banner_harvard_image--intext"
        image={
          breakpoint.isDesktopOrTabletLandscape
            ? props.image || props.imageMobile
            : props.imageMobile || props.image
        }
        courseFullName="Cover Harvard"
        hideShadow
        hideSpinner
        hideFallback
      />
      <p
        className="banner_harvard_description"
        dangerouslySetInnerHTML={printHTML(props.description)}
        role='region'
        aria-label={removeHTMLTags(props.description)}
        {...props?.itemContentId? cmMetaData(props?.itemContentId): null}
      />
      <ButtonHarvard {...props} />
    </div>
  );
};

const BannerHarvardLearningAreas = (props: Props, handleClickExternal) => {
  const lang = useSelector(state => state.utils.lang);
  return (
    <div className="banner_harvard_learning_areas">
      {props.linksCourseExternal && (
        <>
          {props.text1 && <p className="banner_harvard_learning_areas_label" {...props?.itemContentId? cmMetaData(props?.itemContentId): null}>{props.text1}</p>}
          {props.linksCourseExternal.map(linkExternal => {
            return (
              <div className="banner_harvard_learning_areas_lnk" key={linkExternal?.url} {...linkExternal?.titleContentId && cmMetaData(linkExternal?.titleContentId)}>
                <ButtonV2
                  variant="text-btn-no-arrow"
                  handleClick={() => {
                    handleClickExternal(linkExternal.url);
                  }}
                  data-element-id={`home_banner_${purifyItemDataElementIdCamelCase((removeHTMLTags(props.title))?.toLowerCase())}_${purifyItemDataElementIdCamelCase(linkExternal.title)}`}
                >
                  {linkExternal.title}
                  <Arrow className="banner_harvard_lnk_icon" />
                </ButtonV2>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

const BannerHarvardBig = (props: BannerHarvardType) => {
  const CLASS = 'banner_harvard--big';
  const breakpoint = useBreakpointBoolean();

  return (
    <>
    <CardCover className='banner_harvard_image' image={breakpoint.isPhone ? props?.imageMobile : props?.image} courseFullName={"Banner Harvard"} contentId={breakpoint.isPhone ? props?.imageMobileContentId : props?.imageDesktopContentId} hideShadow/>
      {/* <img src={props.image} className="banner_harvard_image" alt="Banner Harvard" {...props?.imageDesktopContentId && cmMetaData(props?.imageDesktopContentId)} /> */}
      <div className={`${CLASS}_wrapper`}>
        <div className={`${CLASS}_content--left`}>{props.contentText}</div>
        <div className={`${CLASS}_content--right`}>{props.learningAreas}</div>
      </div>
    </>
  );
};

const BannerHarvardCardImage = (props: BannerHarvardType) => {
  const CLASS = 'banner_harvard--card-image';

  return (
    <>
      <div className={`${CLASS}_wrapper`}>
        <div className={`${CLASS}_content--left`}>
          <img src={props.image} className="banner_harvard_image" alt="Banner Harvard" {...props?.imageDesktopContentId && cmMetaData(props?.imageDesktopContentId)}/>
        </div>
        <div className={`${CLASS}_content--right`}>{props.contentText}</div>
      </div>
    </>
  );
};

const BannerHarvard = (props: Props) => {
  const lang = useSelector(state => state.utils.lang);

  const handleClickCTA = () => {
    if (props.action) {
      props.action();
    }
  };

  const handleClickExternal = src => {
    goToUrl(src);
  };

  const labels = {
    title:      lang[removeHTMLTags(props.title)] || props.title,
    description:lang[removeHTMLTags(props.description)] || props.description,
    textCTA:    lang[props.textCTA] || props.textCTA,
    tooltip:    lang[removeHTMLTags(props.tooltip)] || props.tooltip,
  }

  const contentText = BannerHarvardText({ ...props, ...labels, handleClickCTA });
  const learningAreas = BannerHarvardLearningAreas(props, handleClickExternal);

  let banner = null;

  const propsHarvard = {
    ...props,
    handleClickCTA,
    handleClickExternal,
    contentText,
    learningAreas,
  };


  switch (props.variant) {
    case 'big': {
      banner = BannerHarvardBig(propsHarvard);
      break;
    }
    case 'card-image': {
      banner = BannerHarvardCardImage(propsHarvard);
      break;
    }
  }

  return (
    <>
      <div
        className={clsx('banner_harvard', {
          'banner_harvard--big': props.variant == 'big',
          'banner_harvard--card-image': props.variant == 'card-image',
          'banner_harvard--spark': props.typeHarvard == HarvardType.spark,
          'banner_harvard--mentor': props.typeHarvard == HarvardType.mentor || props.typeHarvard == null,
        })}
        {...(props?.mainContentId || props?.itemContentId) && cmMetaData(props?.mainContentId ? props?.mainContentId : props?.itemContentId)}
      >
        {banner}
      </div>
    </>
  );
};

const isCourseHarvard = (course: Course) => {
  return !!course.typeHarvard;
};

const L1PageHArvard = () => {};

export {
  Props as HarvardBannerProps,
  BannerHarvard,
  L1PageHArvard,
  CoverHarvard,
  ButtonHarvardType,
  ButtonHarvard,
  LinksCourseExternal,
  isCourseHarvard,
  HarvardType
};
