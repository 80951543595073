import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from '@hooks/redux';
import ChevronLeft from '@images/svg-icons/chevron-left-header.svg';
import useModalTypes from '@hooks/useModalByType';
import { USER_URLS } from '../link-utils';
import { selectHighlightedCourses } from '../../store/selectors/coursesSelectors';
import { setCatalogueCourses } from '@redux-actions/';
import { motion } from 'framer-motion';
import LazySVG from '@components/LazySvg';
import ButtonV2 from '@components/UI/ButtonV2';
import useL1List from '@hooks/useL1List';
import { useIsEnabledForCollections } from '@hooks/collections/useIsEnabledForCollections';
import useTortonaInfo from '@hooks/useTortonaInfo';

const variants = {
  initial: {
    x: '90vw',
  },
  enter: {
    x: '0',
  },
  exit: {
    x: '100vw',
  },
};

const MenuCollectionsMobile = ({ show, isLight }: { show: boolean, isLight?: boolean }) => {
  const lang = useSelector(state => state.utils.lang);
  const modalTypes = useModalTypes();
  const highlightedCourses = useSelector(selectHighlightedCourses);
  const history = useHistory();
  const dispatch = useDispatch();
  const isUserEnabledForCollections = useIsEnabledForCollections();
  const { resultTortona } = useTortonaInfo();
  const showCollectionsDropdown = useSelector(
    state => !!state.trainingPills?.latestCollectionsCarouselInfo?.collection?.noveltiesByBrand
  );
  const [l1List, l0list, _, __, loadingL1List] = useL1List('', false);
  const showCollectionsLink = useSelector(
    state =>
      isUserEnabledForCollections &&
      (!state.trainingPills?.latestCollectionsCarouselInfo?.isCompleted || showCollectionsDropdown)
  ); //UU-2149

  const handleClickL0 = (l0Id: string) => {
    modalTypes.areaOfExpertiseL1Menu.open(l0Id);
  };

  const closeMenu = () => {
    modalTypes.close();
  };
  const closeModal = () => {
    modalTypes.close();
  };

  const handleShowCurrentPage = (event, page: keyof typeof USER_URLS) => {
    if (location.pathname === USER_URLS[page].URL && show) {
      closeMenu();
    }
  };

  const handleClickBackButton = () => {
    modalTypes.menuRightModal.open();
  };

  const handleClickHighlights = () => {
    history.push(USER_URLS.SUBCATALOGUE.URL);
    dispatch(
      setCatalogueCourses(highlightedCourses, lang.HIGHLIGHTS_LABEL, lang.HIGHLIGHTS_LABEL_DESCR)
    );
    handleShowCurrentPage(null, 'SUBCATALOGUE');
  };

  return (
    <motion.div
      className={"menu-collections-mobile menu-aoe-mobile" + (isLight? "" : " dark-variant-mobile")}
      initial="initial"
      animate="enter"
      exit="exit"
      transition={{ ease: 'easeInOut', duration: 0.3 }}
      variants={variants}
    >
      <header id="mainnav" onClick={handleClickBackButton}>
        <button
          className="icon-button back-button header-v3__back-button focus-outline mobile"
          title={lang?.GO_BACK}
          aria-label={lang?.GO_BACK}
          onClick={handleClickBackButton}
          //autoFocus
        >
          <ChevronLeft />
        </button>
        {lang.COLLECTIONS_LABEL}
      </header>
      <div className="menu-aoe-mobile__l0-list__collections">
        {l0list?.map(l0 => (
          <>
            <ButtonV2
              className={'menu-aoe-mobile__l1-list'}
              handleClick={() => handleClickL0(l0.code)}
              key={l0.code}
            >
              {l0.label}
              <LazySVG
                className="area-of-expertise__l1-channel-arrow"
                src={'/images/svg-icons/arrow-right5.svg'}
                alt={l0.label}
              />
            </ButtonV2>
          </>
        ))}
      </div>
      {showCollectionsLink && (
        <ButtonV2
          className={'menu-aoe-mobile__l1-list'}
          to={USER_URLS.LATEST_COLLECTIONS.URL}
          handleClick={closeModal}
        >
          {lang[USER_URLS.LATEST_COLLECTIONS.NAME]}
        </ButtonV2>
      )}
      {showCollectionsLink && highlightedCourses.length > 0 && (
        <ButtonV2
          className={'menu-aoe-mobile__l1-list'}
          to={USER_URLS.HIGHLIGHTS_COLLECTIONS.URL}
          handleClick={closeModal}
        >
          {lang.HIGHLIGHTS_LABEL}
        </ButtonV2>
      )}
      {isUserEnabledForCollections && !!resultTortona && (
        <ButtonV2 className={'menu-aoe-mobile__l1-list'} handleClick={resultTortona.linkAction}>
          {lang.TORTONA_TITLE}
        </ButtonV2>
      )}
    </motion.div>
  );
};

export default MenuCollectionsMobile;
