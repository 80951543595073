import React, { useState, useEffect } from 'react';
import CheckBoxIcon from '@svg-icons/checkbox.svg';
import CheckBoxEmptyIcon from '@svg-icons/checkbox_empty.svg';
import SearchIcon from '@svg-icons/header/search.svg';
import CloseIcon from '@svg-icons/x-icon2.svg';
import clsx from '@utility/clsx';
import { ResponseGetProductsFilter } from '@model/ProductClass';
import ButtonV2 from './ButtonV2';
import LazySVG from '@components/LazySvg';
import camelCase from 'lodash/camelCase';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';

type OptionProps = {
  value: any;
  label: string;
  isSelectable?: boolean;
  isDisabled?: boolean,
  isSelected?: boolean;
  showCheckbox?: boolean;
  onSelect?: (string) => void;
  onDeselect?: (string) => void;
  dataElementId?: string;
  dataDescription?: string;
};

const Option = (props: OptionProps) => {
  // if there is no checked button, just call onClick
  const [isSelectable, setisSelectable] = useState<boolean>(props.isSelectable ?? true);
  const [showCheckbox, setShowCheckbox] = useState<boolean>(props.showCheckbox ?? true);
  const [isSelected, setIsSelected] = useState<boolean>(props.isSelected ?? false);

  const onChange = (e) => {
    e.stopPropagation()
    if(props.isDisabled) return
    if (isSelected) props.onDeselect ? props.onDeselect(props.value) : null;
    else props.onSelect ? props.onSelect(props.value) : null;

    setIsSelected(!isSelected);
  };

  useEffect(() => {
    setIsSelected(props.isSelected);
  }, [props.isSelected]);

  const onClick = (e) => {
    if (!props.isDisabled) {
      if (!showCheckbox) {
        props.onSelect ? props.onSelect(props.value) : null;
      } else {
        onChange(e);
      }
    }
  };

  return (
    <>
      {props.dataElementId &&
        <button
          className={clsx('option', {
            'option--selected': isSelected,
            'option--notselectable': !showCheckbox,
            'option--disabled': props.isDisabled,
          })}
          onClick={onClick}
          data-element-id={props.dataElementId ?? undefined}
          data-description={props.dataDescription ?? undefined}
        >
          {isSelectable &&
            showCheckbox &&
            (!!isSelected ? (
              <CheckBoxIcon className="option_checkbox" onClick={onChange} />
            ) : (
              <CheckBoxEmptyIcon className={`option_checkbox ${props.isDisabled ? 'disabled-checkbox' : ''}`} onClick={onChange} />
            ))}
          <span className="option_label">{props.label}</span>
        </button>
      }
      {!props.dataElementId &&
        <div
          className={clsx('option', {
            'option--selected': isSelected,
            'option--notselectable': !showCheckbox,
            'option--disabled': props.isDisabled,
          })}
          onClick={onClick}
          data-element-id={props.dataElementId ?? undefined}
        >
          {isSelectable &&
            showCheckbox &&
            (!!isSelected ? (
              <CheckBoxIcon className="option_checkbox" onClick={onChange} />
            ) : (
              <CheckBoxEmptyIcon className={`option_checkbox ${props.isDisabled ? 'disabled-checkbox' : ''}`} onClick={onChange} />
            ))}
          <span className="option_label">{props.label}</span>
        </div>
      }
    </>
    
  );
};

type DropDownProps = {
  className?: string;
  options: Array<string>;
  optionsSelected: Array<string>;
  optionsMap: { [key: string]: ResponseGetProductsFilter };
  show?: boolean;
  isQueryble?: boolean;
  placeholderSearchBox?: string;
  isMultiselect?: boolean;
  filterCallback?: (query: string, opt: string) => {};
  onSelection?: (any) => void;
  isDisabled?: boolean;
  allSelection?: string;
  sortOptionsAlphabetically?: boolean;
  useId?: boolean,
  setOptionsSelectedInOrder?: React.Dispatch<React.SetStateAction<string[]>>;
  isBrandCampaignDropdown?: boolean;
  dataDescription?: string;
};

const DropDown = (props: DropDownProps) => {
  const initialOptions = props.options;
  if (props.allSelection && !initialOptions?.includes(props.allSelection)) {
    initialOptions.unshift(props.allSelection);
  }
  const [isMultiselect, setIsMultiselect] = useState<boolean>(props.isMultiselect ?? true);
  const [optionsFiltered, setOptionsFiltered] = useState<Set<string>>(new Set(initialOptions));
  const [optionsSelected, setOptionsSelected] = useState<Set<string>>(new Set());
  const [query, setQuery] = useState<string>('');
  const [isQueryble, setisQueryble] = useState<boolean>(props.isQueryble ?? true);
  const [placeholderSearchBox, setPlaceholderSearchBox] = useState<string>(
    props.placeholderSearchBox ?? 'Search'
  );

  const { isDesktopOrTabletLandscape } = useBreakpointBoolean();

  useEffect(() => {
    //if initial options change, reset and update filters
    if (initialOptions?.length > 0) {
      setOptionsFiltered(new Set(initialOptions));
      setOptionsSelected(new Set());
      setQuery('');
    }
  }, [JSON.stringify(initialOptions || [])]);

  useEffect(() => {
    setOptionsSelected(new Set(props.options.filter(option => props.optionsMap?.[option]?.checked)));
  }, [JSON.stringify(props.optionsSelected) || {}]);

  const filterCallback = props.filterCallback
    ? props.filterCallback
    : (query, opt) => {
      if(props?.useId) {
        return props.optionsMap[opt]?.id.toLowerCase().includes(query.toLowerCase())
      }
      if(props.optionsMap[opt]?.title.toLowerCase()) {
        return props.optionsMap[opt]?.title.toLowerCase().includes(query.toLowerCase());
      }
      return opt?.toLowerCase().includes(query.toLowerCase());
    }; // default filter (startsWith)

  const onSelectOption = (opt: string) => {
    if(props?.setOptionsSelectedInOrder) {
      props?.setOptionsSelectedInOrder(prevOpts => [...prevOpts, opt])
    }

    if (!props.isMultiselect) {
      if (optionsSelected.size > 0) {
        onDeselectOption(Array.from(optionsSelected)[0]);
      }
    }

    const tempOptionsSelected = optionsSelected;
    if (opt === props.allSelection) {
      //select all options expect allSelection
      optionsFiltered.forEach(a => {
        if (a !== props.allSelection) {
          tempOptionsSelected.add(a);
        }
      })
    } else {
      //select option
      tempOptionsSelected.add(opt);
    }
    setOptionsSelected(tempOptionsSelected);

    if (props.onSelection) props.onSelection(Array.from(tempOptionsSelected));
  };

  const onDeselectOption = (opt: string) => {
    if(props?.setOptionsSelectedInOrder) {
      props?.setOptionsSelectedInOrder(prevOpts =>
        prevOpts.filter(prevOpt => prevOpt !== opt)
      );
    }

    const tempOptionsDeselected = optionsSelected;

    if (opt === props.allSelection) {
      //remove all selections
      tempOptionsDeselected.clear();
    } else {
      //remove selected option
      tempOptionsDeselected.delete(opt);
    }
    setOptionsSelected(tempOptionsDeselected);

    if (props.onSelection) props.onSelection(Array.from(tempOptionsDeselected));
  };

  const filter = e => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    if (!props.show && query !== "") {
      setQuery("");
    }
  }, [props.show]);

  useEffect(() => {
    // if(query == "")
    if (query) {
      const tempOptionsFitered = new Set(
        props.options.filter(opt => {
          return filterCallback(query, opt);
        })
      );
      setOptionsFiltered(tempOptionsFitered);
    } else {
      setOptionsFiltered(new Set(initialOptions));
    }
  }, [query]);

  const sortedOptions = Array.from(optionsFiltered).sort()

  const getDataElementId = (optLabel?: string) => {
    if(props.isBrandCampaignDropdown && optLabel) {
      return `chips_${camelCase(optLabel)}${isDesktopOrTabletLandscape ? '_otherCampaigns' : ''}`
    }
    return undefined
  }

  return (
    <div className={clsx('dropdown', !props.show && 'dropdown--hidden', !!props.className && props.className)}>
      {isQueryble && (
        <div className="dropdown_searchbox">
          <input type="text" value={query} onChange={filter} placeholder={placeholderSearchBox} spellCheck={false} autoComplete='off' autoCorrect='off' />
          {
            query !== "" ?
              <ButtonV2 small variant='text-btn-no-arrow' handleClick={() => setQuery("")}>
                <LazySVG src="/images/svg-icons/x-icon2.svg" alt="" className='dropdown_close_icon' />
              </ButtonV2>
              : <SearchIcon className="dropdown_searchbox_icon" />
          }
        </div>
      )}
      <div className="dropdown_options">
        {props?.sortOptionsAlphabetically &&
          Array.from(optionsFiltered)?.sort().map(opt => {
            const optLabel = props?.useId ? props.optionsMap[opt]?.id : props.optionsMap[opt]?.title || opt;
            return (
              <Option
                key={opt}
                value={opt}
                label={optLabel}
                isSelected={opt === props.allSelection
                  ? optionsSelected.size === (props.options.length - 1)
                  : optionsSelected.has(opt)
                }
                isDisabled={props.optionsMap[opt]?.disabled || props.isDisabled}
                onSelect={onSelectOption}
                onDeselect={onDeselectOption}
                showCheckbox={isMultiselect}
                dataElementId={getDataElementId(optLabel)}
                dataDescription={props.dataDescription ?? undefined}
              />
            );
          })
        }
        {!props?.sortOptionsAlphabetically &&
          Array.from(optionsFiltered).map(opt => {
            const optLabel = props?.useId ? props.optionsMap[opt]?.id : props.optionsMap[opt]?.title || opt;
            return (
              <Option
                key={opt}
                value={opt}
                label={optLabel}
                isSelected={opt === props.allSelection
                  ? optionsSelected.size === (props.options.length - 1)
                  : optionsSelected.has(opt)
                }
                isDisabled={props.optionsMap[opt]?.disabled || props.isDisabled}
                onSelect={onSelectOption}
                onDeselect={onDeselectOption}
                showCheckbox={isMultiselect}
                dataElementId={getDataElementId(optLabel)}
                dataDescription={props.dataDescription ?? undefined}
              />
            );
          })
        }

      </div>
    </div>
  );
};

export { Option, OptionProps, DropDown, DropDownProps };
