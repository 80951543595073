import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSelector } from '@hooks/redux';
import ButtonV2 from '@components/UI/ButtonV2';
import { getNovelties, setCatalogueCourses } from '@redux-actions/';
import {
  getBrandPageUrl,
  handleOnEnterKeyPress,
  isCourseVisible,
  isHighlighted,
} from '@utility/Api';
import CardCover from '@components/CardV3/CardCover';
import { goToCollectionPage } from '@utility/CollectionsUtility';
import { USER_URLS } from '@components/link-utils';
import CardController from '@components/CardV3';
import { NoveltyByBrand } from '@model/collections/CollectionModel';
import useModalTypes from '@hooks/useModalByType';
import { selectHighlightedCourses } from '../../store/selectors/coursesSelectors';
import { Course, LangMap } from '@model/CoursesClass';
import HeaderMenu from '@components/UI/HeaderMenu';
import { useIsEnabledForCollections } from '@hooks/collections/useIsEnabledForCollections';
import LazySVG from '@components/LazySvg';
import useL1List from '@hooks/useL1List';
import { multipleSortCourses } from '@utility/sortUtility';
import { SORTBY } from '@utility/const';
import { purifyItemDataElementId, purifyItemDataElementIdCamelCase } from '@utility/analytics-utils';
import useTortonaInfo from '@hooks/useTortonaInfo';
import { cmMetaData } from '@utility/previewUtils';

export enum pickedSelection {
  collections = 'collections',
  highlights = 'highlights',
  tortona = 'tortona',
}

class CollectionsMenuProps {
  show: boolean;
  selection: string;
  setSelection: (selection: string) => void;
}

const CollectionsMenu = ({ show, selection, setSelection }: CollectionsMenuProps) => {
  const lang = useSelector(state => state.utils.lang);

  const modalTypes = useModalTypes();

  const [l1List, l0list, y, x, loading] = useL1List(null, false);
  const [coursesToShow, setCoursesToShow] = useState([]);
  const coursesMap = useSelector(state => state.course.coursesMap);
  const isUserEnabledForCollections = useIsEnabledForCollections();

  useEffect(() => {
    //if(!selection && !loading) {
    if (l0list?.length > 0) {
      setSelection(l0list?.[0]?.code);
    } else {
      setSelection(pickedSelection.collections);
    }
    // }
  }, [l0list?.length]);

  useEffect(() => {
    let found = l0list?.find(a => a.code === selection);
    let coursesToShowTemp = [];

    if (coursesMap) {
      coursesToShowTemp = Object.values(coursesMap).filter(
        a => isCourseVisible(a) && isHighlighted(a)
      );

      if (found?.code) {
        //filter by L0 if selected
        coursesToShowTemp = coursesToShowTemp.filter(a => a.level0 === found?.code);
      }

      //sort by start date, if same start date --> sort by title
      coursesToShowTemp = multipleSortCourses(coursesToShowTemp, [
        SORTBY.TITLE_AZ,
        SORTBY.START_DATE,
      ]).slice(0, 3);
    }

    setCoursesToShow(coursesToShowTemp);
  }, [selection, coursesMap]);

  const collections = useSelector(
    state => state?.trainingPills?.latestCollectionsCarouselInfo?.collection?.noveltiesByBrand
  );
  const highlightedCourses = useSelector(selectHighlightedCourses);

  const closeModal = () => {
    modalTypes.close();
  };

  return (
    <HeaderMenu
      // MOVED ON MenuModalCollection.tsx
      // className={"collections-menu " + selection}
      className={selection}
      show={show}
      closeModal={closeModal}
      firstColumn={
        <FirstColumn
          lang={lang}
          collections={collections}
          setSelection={setSelection}
          selection={selection}
          l0list={l0list}
          highlightedCourses={highlightedCourses}
        />
      }
      secondColumn={
        <>
          {selection === pickedSelection.collections && (
            <LatestCollectionSecondColumn closeModal={closeModal} />
          )}
          {selection === pickedSelection.highlights && (
            <HighlightsSecondColumn courses={highlightedCourses} closeModal={closeModal} />
          )}
          {selection === pickedSelection.tortona && <TortonaSecondColumn />}
          {selection != pickedSelection.highlights &&
            selection !== pickedSelection.collections &&
            selection !== pickedSelection.tortona && (
              <H0SecondColum
                l0list={l0list}
                selection={selection}
                l1List={l1List}
                closeModal={closeModal}
              />
            )}
        </>
      }
      secondColumnProps={{ key: 'second_' + selection }}
      thirdColumn={
        <>
          {selection === pickedSelection.collections && <LatestCollectionThirdColumn />}
          {selection === pickedSelection.highlights && (
            <HighlightsThirdColumn courses={highlightedCourses} closeModal={closeModal} />
          )}
          {selection === pickedSelection.tortona && <TortonaThirdColumn />}
          {selection !== pickedSelection.collections &&
            selection !== pickedSelection.highlights &&
            selection !== pickedSelection.tortona && (
              <L0ThirdColumn courses={coursesToShow} closeModal={closeModal} />
            )}
        </>
      }
      thirdColumnProps={{ key: 'third_' + selection }}
    />
  );
};

export default CollectionsMenu;

const LatestCollectionSecondColumn = ({ closeModal }: { closeModal: () => void }) => {
  const dispatch = useDispatch();
  const lang = useSelector(state => state.utils.lang);
  const userProfile = useSelector(state => state.user.userProfile);

  const isUserEnabledForCollections = useIsEnabledForCollections();
  const latestCollectionCarouselInfo = useSelector(
    state => state.trainingPills.latestCollectionsCarouselInfo
  );

  const labelL1Map = useSelector(state => state.utils.labelL1Map);

  React.useEffect(() => {
    //download the latest colletions carousel if not already done or the user language has changed
    if (
      userProfile &&
      latestCollectionCarouselInfo?.language !== userProfile?.preferredLang &&
      !latestCollectionCarouselInfo?.isLoading &&
      Object.keys(labelL1Map)?.length > 0
    ) {
      dispatch(
        getNovelties({
          language: userProfile.preferredLang,
          variant: 'latest-collections-page',
        })
      );
    }
  }, [isUserEnabledForCollections, labelL1Map, userProfile?.preferredLang]);

  const history = useHistory();
  const handleClickViewAll = () => {
    closeModal();
    history.push(USER_URLS.LATEST_COLLECTIONS.URL);
  };

  return (
    <div className="collections-menu__section-2__content">
      <div className="collections-menu__section-2__title">{lang.LATEST_COLLECTIONS_TITLE}</div>
      <div className="collections-menu__section-2__description">
        {lang.LATEST_COLLECTIONS_DESCR}
      </div>
      <ButtonV2
        className={
          'area-of-expertise__l1-button area-of-expertise__l1-channel collections-menu__section-2__cta'
        }
        handleClick={handleClickViewAll}
        data-element-id={`mainNav_brands_${purifyItemDataElementIdCamelCase(lang.LATEST_COLLECTIONS_TITLE)}_${purifyItemDataElementIdCamelCase(lang.LATEST_COLLECTIONS_MENU_CTA)}`}
      >
        {lang.LATEST_COLLECTIONS_MENU_CTA}
        <LazySVG
          className="area-of-expertise__l1-channel-arrow"
          src={'/images/svg-icons/arrow-right5.svg'}
          alt={lang.LATEST_COLLECTIONS_MENU_CTA}
        />
      </ButtonV2>
    </div>
  );
};

const LatestCollectionThirdColumn = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector(state => state.user.userProfile);

  const isUserEnabledForCollections = useIsEnabledForCollections();
  const latestCollectionCarouselInfo = useSelector(
    state => state.trainingPills.latestCollectionsCarouselInfo
  );
  const labelL1Map = useSelector(state => state.utils.labelL1Map);

  React.useEffect(() => {
    //download the latest colletions carousel if not already done or the user language has changed
    if (
      userProfile &&
      latestCollectionCarouselInfo?.language !== userProfile?.preferredLang &&
      !latestCollectionCarouselInfo?.isLoading &&
      Object.keys(labelL1Map)?.length > 0
    ) {
      dispatch(
        getNovelties({
          language: userProfile.preferredLang,
          variant: 'latest-collections-page',
        })
      );
    }
  }, [isUserEnabledForCollections, labelL1Map, userProfile?.preferredLang]);

  if (!isUserEnabledForCollections) {
    return null;
  }

  return (
    <>
      {latestCollectionCarouselInfo.collection?.noveltiesByBrand
        ?.slice(0, 3)
        .map(brandCollection => (
          <CardCollectionMenu key={brandCollection.brand} collectionInfo={brandCollection} />
        ))}
    </>
  );
};

type CardCollectionProps = {
  className?: string;
  collectionInfo: NoveltyByBrand;
};

const CardCollectionMenu = ({ className = '', collectionInfo }: CardCollectionProps) => {
  const handleClickCard = () => {
    goToCollectionPage(collectionInfo.release, collectionInfo.brand);
  };
  return (
    <a
      className={'card-collection-menu focus-outline ' + className}
      onClick={handleClickCard}
      onKeyDown={event => {
        handleOnEnterKeyPress(event, handleClickCard, true);
      }}
      tabIndex={0}
      data-element-id={`mainNav_brands_latestCollections_${purifyItemDataElementIdCamelCase(collectionInfo.brandName)}_${purifyItemDataElementIdCamelCase(collectionInfo.shortName)}`}    >
      <div className="card-collection-menu__image">
        <CardCover
          image={collectionInfo.urlMenu}
          courseFullName={collectionInfo.brandCollectionLongName}
          contentId={collectionInfo?.urlMenuContentId}
        />
      </div>

      <div className="card-collection-menu__info">
        <span className="card-collection-menu__brand">
          {collectionInfo.brandName?.toUpperCase()}
        </span>
        <span className="card-collection-menu__collection">
          {collectionInfo.shortName?.toUpperCase()}
        </span>
      </div>
    </a>
  );
};

const HighlightsSecondColumn = ({
  closeModal,
  courses,
}: {
  closeModal: () => void;
  courses: Course[];
}) => {
  const lang = useSelector(state => state.utils.lang);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClickViewAll = () => {
    closeModal();
    dispatch(setCatalogueCourses(courses, lang.HIGHLIGHTS_LABEL, lang.HIGHLIGHTS_LABEL_DESCR));
    history.push(USER_URLS.HIGHLIGHTS_COLLECTIONS.URL);
  };

  return (
    <>
      <div className="collections-menu__section-2__content">
        <div className="collections-menu__section-2__title">{lang.HIGHLIGHTS_LABEL}</div>
        <div className="collections-menu__section-2__description">
          {lang.HIGHLIGHTS_LABEL_DESCR}
        </div>
        <ButtonV2
          className={
            'area-of-expertise__l1-button area-of-expertise__l1-channel collections-menu__section-2__cta'
          }
          handleClick={handleClickViewAll}
          data-element-id={`mainNav_brands_${purifyItemDataElementIdCamelCase(lang.HIGHLIGHTS_LABEL)}_${purifyItemDataElementIdCamelCase(lang.HIGHLIGHTS_MENU_CTA)}`}


        >
          {lang.HIGHLIGHTS_MENU_CTA}
          <LazySVG
            className="area-of-expertise__l1-channel-arrow"
            src={'/images/svg-icons/arrow-right5.svg'}
            alt={lang.HIGHLIGHTS_MENU_CTA}
          />
        </ButtonV2>
      </div>
    </>
  );
};
export const HighlightsThirdColumn = ({
  courses,
  closeModal,
}: {
  courses: Course[];
  closeModal: () => void;
}) => {
  return (
    <div className="collections-menu__section-3 collections-menu__a cards-highlighted">
      {courses?.slice(0, 3)?.map(course => (
        <CardController
          key={course.courseIdMaster}
          variant="card-areaofexpertise"
          course={course}
          onClick={closeModal}
        />
      ))}
    </div>
  );
};

const TortonaSecondColumn = ({ }: {}) => {
  const lang = useSelector(state => state.utils.lang);
  const history = useHistory();
  const dispatch = useDispatch();
  const { resultTortona } = useTortonaInfo();

  return (
    <>
      {!!resultTortona && (
        <div className="collections-menu__section-2__content">
          <div className="collections-menu__section-2__title">{lang.TORTONA_TITLE}</div>
          <div className="collections-menu__section-2__description">{lang.TORTONA_DESCRIPTION}</div>
          <ButtonV2
            className={
              'area-of-expertise__l1-button area-of-expertise__l1-channel collections-menu__section-2__cta'
            }
            handleClick={resultTortona.linkAction}
            data-element-id={`mainNav_brands_${purifyItemDataElementIdCamelCase(lang?.TORTONA_TITLE)}_${purifyItemDataElementIdCamelCase(lang?.TORTONA_CTA)}`}
          >
            {lang.TORTONA_CTA}
            <LazySVG
              className="area-of-expertise__l1-channel-arrow"
              src={'/images/svg-icons/arrow-right5.svg'}
              alt={lang.TORTONA_CTA}
            />
          </ButtonV2>
        </div>
      )}
    </>
  );
};

export const TortonaThirdColumn = ({ }: {}) => {
  const { resultTortona } = useTortonaInfo();
  return (
    <div className="collections-menu__section-3 collections-menu__a tortona-image">
      {!!resultTortona && <img src={resultTortona.image} {...resultTortona?.imageDesktopContentId && cmMetaData(resultTortona?.imageDesktopContentId)} />}
    </div>
  );
};

export const L0ThirdColumn = ({
  courses,
  closeModal,
}: {
  courses: Course[];
  closeModal: () => void;
}) => {
  return (
    <div className="collections-menu__section-3 collections-menu__a cards-highlighted">
      {courses?.slice(0, 3)?.map(course => (
        <CardController
          key={course.courseIdMaster}
          variant="card-areaofexpertise"
          course={course}
          onClick={closeModal}
        />
      ))}
    </div>
  );
};

type SecondColumnProps = {
  lang: LangMap;
  l1List: any;
  closeModal: () => void;
};

/*const SecondColumn = ({
  lang,
  l1List,
  closeModal,
}: SecondColumnProps) =>
    l1list &&
    (selection === "" ? (
      <span className="area-of-expertise__l1-description">
        {lang?.AREAS_OF_EXPERTISE_DESCR}
      </span>
    ) : (
      <>
        <span className="area-of-expertise__l1-title">
          {selectedL0Full.label}
        </span>
        <div className="area-of-expertise__l1-list">
          <span className="area-of-expertise__l1-description">
            {selectedL0Full.longDescription}
          </span>

          {l1List[selectedL0Full.code]?.map((channel) =>
            <ButtonV2
              key={channel.value}
              className={
                "area-of-expertise__l1-button area-of-expertise__l1-channel"
              }
              to={getBrandPageUrl(channel.type, channel.value)}
              handleClick={closeModal}
              data-element-id={`mainnav_areasofexpertise_${purifyItemDataElementId(channel.code)}_link`}
            >
              {channel.label}
              <LazySVG
                className="area-of-expertise__l1-channel-arrow"
                src={"/images/svg-icons/arrow-right5.svg"}
                alt={channel.label}
              />
            </ButtonV2>
          )}
        </div>
      </>
    )
  );
*/

type FirstColumnProps = {
  lang: LangMap;
  collections: any;
  setSelection: any;
  selection: string;
  l0list: any;
  highlightedCourses: any;
};
const FirstColumn = ({
  lang,
  collections,
  setSelection,
  selection,
  l0list,
  highlightedCourses,
}: FirstColumnProps) => {
  const isUserEnabledForCollections = useIsEnabledForCollections();
  const { resultTortona } = useTortonaInfo();

  return (
    <div className="area-of-expertise__l0-list">
      <h1 className="collections-menu__section-1__title">{lang.COLLECTIONS_LABEL}</h1>
      <div className="area-of-expertise__separator" />
      {l0list?.map(l0 => (
        <ButtonV2
          variant="text-btn-dotted"
          className={
            'area-of-expertise__l0-button ' + (selection === l0.code ? 'l0-button--selected' : '')
          }
          handleClick={() => setSelection(l0.code)}
          variantLongArrow
          key={'l0_button_' + l0?.code}
          data-element-id={`mainNav_brands_${purifyItemDataElementIdCamelCase(l0.label)}`}
        >
          {l0.label}
        </ButtonV2>
      ))}
      {collections?.length > 0 && (
        <ButtonV2
          variant="text-btn-dotted"
          className={
            'area-of-expertise__l0-button ' +
            (selection === pickedSelection.collections ? 'l0-button--selected' : '')
          }
          handleClick={() => setSelection(pickedSelection.collections)}
          variantLongArrow
          data-element-id={`mainNav_brands_${purifyItemDataElementIdCamelCase(lang.LATEST_COLLECTIONS_TITLE)}`}
        >
          {lang.LATEST_COLLECTIONS_TITLE}
        </ButtonV2>
      )}
      {highlightedCourses.length > 0 && (
        <ButtonV2
          variant="text-btn-dotted"
          className={
            'area-of-expertise__l0-button ' +
            (selection === pickedSelection.highlights ? 'l0-button--selected' : '')
          }
          handleClick={() => setSelection(pickedSelection.highlights)}
          variantLongArrow
          data-element-id={`mainNav_brands_${purifyItemDataElementIdCamelCase(lang.HIGHLIGHTS_LABEL)}`}
        >
          {lang.HIGHLIGHTS_LABEL}
        </ButtonV2>
      )}
      {isUserEnabledForCollections && !!resultTortona && (
        <ButtonV2
          variant="text-btn-dotted"
          className={
            'area-of-expertise__l0-button tortona-title ' +
            (selection === pickedSelection.tortona ? 'l0-button--selected' : '')
          }
          handleClick={() => setSelection(pickedSelection.tortona)}
          variantLongArrow
          data-element-id={`mainNav_brands_${purifyItemDataElementIdCamelCase(lang.TORTONA_TITLE)}`}
        >
          {lang.TORTONA_TITLE}
        </ButtonV2>
      )}
    </div>
  );
};

type H0SecondColumnProps = {
  l0list: any;
  selection: string;
  l1List: any;
  closeModal: () => void;
};
const H0SecondColum = ({ l0list, selection, l1List, closeModal }: H0SecondColumnProps) => (
  <>
    <span className="area-of-expertise__l1-title">
      {l0list?.find(a => a.code === selection)?.label}
    </span>
    <div className="area-of-expertise__l1-list">
      <span className="area-of-expertise__l1-description">
        {l0list?.find(a => a.code === selection)?.longDescription}
      </span>

      {l1List[selection]?.map(channel => (
        <ButtonV2
          key={channel.value}
          className={'area-of-expertise__l1-button area-of-expertise__l1-channel'}
          to={getBrandPageUrl(channel.type, channel.value)}
          handleClick={closeModal}
          data-element-id={`mainNav_brands_${purifyItemDataElementIdCamelCase(selection?.replace('_', ' '))}_${purifyItemDataElementIdCamelCase(channel.label)}`}
        >
          {channel.label}
          <LazySVG
            className="area-of-expertise__l1-channel-arrow"
            src={'/images/svg-icons/arrow-right5.svg'}
            alt={channel.label}
          />
        </ButtonV2>
      ))}
    </div>
  </>
);
