import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from '@hooks/redux';
import CardCover from '@components/CardV3/CardCover';
import OnboardingIconsList from '@components/onboarding/OnboardingIconsList';
import { handleOnEnterKeyPress, printHTML } from '@utility/Api';
import { USER_URLS } from '@components/link-utils';
import LockIconSmall from '@images/svg-icons/lock-icon-small.svg';
import RibbonCourseEvolution from '@components/UI/RibbonCourseEvolution';
import { CardDisplayProps } from '../CardV3/CardDisplayUtils';
import useGetOnboardingLabels from '@hooks/onboarding/useGetOnboardingLabels';
import EducationalPathsPrice from '@components/EducationalPathsPrice';
import EducationalPathsComplexity from '@components/EducationalPathsComplexity';
import ButtonV2 from '@components/UI/ButtonV2';
import { isCourseOnBoarding } from '@utility/onBoardingCourseUtility';
import { cmMetaData } from '@utility/previewUtils';
import TextSkeleton from '@components/skeletons/TextSkeleton';
import { getBlockingPrograms, isProgramBlocked } from '@utility/EducationalPathsUtility';
import { CoursesMap } from '@model/CoursesClass';

const CardEducationalPathsSection = (props: CardDisplayProps & { isMaster?: boolean, isCareer?: boolean }) => {
  const {
    course,
    badgeLabel,
    badgeColor,
    badgePriority,
    image,
    courseFullName,
    ctypeName,
    courseIdMaster,
    priceLabel,
    fallbackImage,
    openCourseModal,
    cardRef
  } = props;

  const { courseSummary } = course;

  const history = useHistory();
  const lang = useSelector(state => state.utils.lang);
  const isLoadingCourseSummary = useSelector(state => state.course.isLoadingCourseSummary);
  const onboardingLabels = useGetOnboardingLabels(course, true);
  const [isHistoryPushed, setIsHistoryAlreadyPushed] = useState<boolean>(false);
  const coursesMap: CoursesMap = useSelector(state => state.course.coursesMap);

  useEffect(() => {
    if (!isHistoryPushed) {
      return;
    }

    openCourseModal();
  }, [isHistoryPushed])

  const handleClickCard = () => {
    setIsHistoryAlreadyPushed(true);
  };
  const handleKeyDown = event => {
    handleOnEnterKeyPress(event, handleClickCard, true);
  };

  return (
    <a
      className="card-educational-paths-section__card focus-outline"
      onClick={handleClickCard}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      role="region"
      data-id={courseIdMaster}
      data-element-id={props.dataElementId}
      aria-label={lang?.SHOW_OVERVIEW_ITEM?.replace('{name}', courseFullName)}
      ref={cardRef}
      {...course?.contentId && cmMetaData(course?.contentId)}
    >
      <div className="card-educational-paths-section__card-header">
        <CardCover
          image={image}
          courseFullName={courseFullName}
          fallbackImage={fallbackImage}
          variant="onboarding-hp-section-img"
          hideShadow
          typeHarvard={props?.typeHarvard}
        />
      </div>
      <div className="card-educational-paths-section__card-body">
        <div>
          <EducationalPathsComplexity label={ctypeName} />
          <div className="card-educational-paths-section__card-heading">
            <p className="card-educational-paths-section__card-title">{courseFullName}</p>
            {/*LEON-5965 RIMOZIONE VISIBILITA' DATE MASTER
            {(course?.masterAttributes?.mcStartDate || course?.masterAttributes?.mcEndDate) && (
              <p className="card-educational-paths-section__card-range-date">
                {onboardingLabels.mcActiveMasterStartEndDate}
              </p>
            )}*/}
          </div>
          {!props.isMaster && onboardingLabels.icons?.length > 0 && (
            <OnboardingIconsList
              className="card-educational-paths-section__card-badges"
              onboardingIcons={onboardingLabels.icons}
              isTagBadge={true}
            />
          )}
          {props.isMaster && props.isCareer && onboardingLabels.icons?.length > 0 && (
            <OnboardingIconsList
              className="card-educational-paths-section__card-badges"
              onboardingIcons={onboardingLabels.icons}
              isTagBadge={true}
            />
          )}
          {isLoadingCourseSummary?.[course?.courseId] ?
            <div className="card-educational-paths-section__card-description">
              <TextSkeleton className="phone-and-tablet-portrait" width={250} height={15} borderRadius={10} />
              <TextSkeleton className="phone-and-tablet-portrait" width={200} height={15} borderRadius={10} />
              <TextSkeleton className="phone-and-tablet-portrait" width={100} height={15} borderRadius={10} />
              <TextSkeleton className="desktop-and-tablet-landscape" width={400} height={15} borderRadius={10} />
              <TextSkeleton className="desktop-and-tablet-landscape" width={350} height={15} borderRadius={10} />
              <TextSkeleton className="desktop-and-tablet-landscape" width={100} height={15} borderRadius={10} />
            </div>
            :
            <p
              className="card-educational-paths-section__card-description"
              dangerouslySetInnerHTML={printHTML(courseSummary)}
            />
          }
        </div>
        {!props.isMaster && (
          <div className="card-educational-paths-section__footer">
            {!isProgramBlocked(course, coursesMap) &&
              <>
                {badgeLabel && (
                  <RibbonCourseEvolution
                    course={course}
                    className="card-educational-paths-section__tag"
                    useBadgeLabelLive={false}
                    ribbonInfo={{
                      label: badgeLabel,
                      color: badgeColor,
                      priority: badgePriority,
                    }}
                  />
                )}
                {priceLabel && (
                  <EducationalPathsPrice
                    label={priceLabel}
                    className="card-educational-paths-section__price"
                  />
                )}
              </>
            }
            
            {isProgramBlocked(course, coursesMap) && (
              <>
                <div className='blocked-program'>
                  <LockIconSmall />
                  <p>{lang?.BLOCKED_PROGRAM_INFO_CARD}. {lang?.BLOCKED_PROGRAM_INFO}</p>
                </div>
              </>
            )}
          </div>
        )}
        {props.isMaster && !props.isCareer && (
          <div className="card-educational-paths-section__footer flex align-center justify-between">
            <ButtonV2
              variant="text-btn"
              className="card-educational-paths-section__footer__cta"
              handleClick={handleClickCard}
            >
              {lang?.VIEW_DETAILS_EP}
            </ButtonV2>
            {!badgeLabel && priceLabel && (
              <EducationalPathsPrice
                label={priceLabel}
                className="card-educational-paths-section__price"
              />
            )}
            {badgeLabel && (
              <RibbonCourseEvolution
                course={course}
                className="card-educational-paths-section__tag isMaster"
                useBadgeLabelLive={false}
                ribbonInfo={{
                  label: badgeLabel,
                  color: badgeColor,
                  priority: badgePriority,
                }}
              />
            )}
          </div>
        )}
        {/* CAREER MASTER */}
        {props.isMaster && props.isCareer && (
          <div className="card-educational-paths-section__footer flex align-center justify-between">
            {badgeLabel && (
              <RibbonCourseEvolution
                course={course}
                className="card-educational-paths-section__tag isMaster"
                useBadgeLabelLive={false}
                ribbonInfo={{
                  label: badgeLabel,
                  color: badgeColor,
                  priority: badgePriority,
                }}
              />
            )}
            {!badgeLabel && priceLabel && (
              <EducationalPathsPrice
                label={priceLabel}
                className="card-educational-paths-section__price"
              />
            )}
          </div>
        )}
      </div>
    </a>
  );
};

export default CardEducationalPathsSection;
