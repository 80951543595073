import Modal from "@components/UI/Modal";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "@hooks/redux";
import { PlanModel } from "@model/PlanModel";
import ButtonV2 from "@components/UI/ButtonV2";
import { useForm } from "react-hook-form";
import * as actions from "@redux-actions/";
import { PaymentInstructionRequest } from "@model/CartModel";
import ChevronLeft from "@images/svg-icons/chevron-left.svg";

import { getDefaultContact, isCreditCardExpired } from "@utility/ecommerceUtility";
import { CARD_VALIDATION, CARD_ZERO } from "@utility/const";
import { AxiosError } from "axios";
import FormContentPayment, {
  FormContentPaymentWrapper,
  FormValues,
} from "@components/UI/FormContentPayment";
import useFormContentPayment from "@hooks/useFormContentPayment";
import ErrorMessage from "@components/UI/ErrorMessage";
import ReCAPTCHA from "react-google-recaptcha";
import { configReCAPTCHA } from "../../../src/config_local";
import ModalRenewalActivated from "./ModalRenewalActivated";
import { ShippingAddress } from "@model/ShippingInfo";
import { WalletItem, WalletRequestBody } from "@model/WalletModel";
import { showError } from "@redux-actions/";
import ModalEvolution from "./ModalEvolution";
import { selectUserPlan } from "@utility/ecommercePlanUtility";

type ModalChoosePaymentProps = {
  show: boolean;
  close: () => void;
  plan: PlanModel;
  data: { orderId: string, subscriptionId: string };
};

const ModalChoosePayment = ({ show, close, plan, data }: ModalChoosePaymentProps) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.utils.lang);
  const [showActivated, setShowActivated] = useState(false);
  const isLoadingPaymentInfo = useSelector(
    (state) => state.ecommerce.isLoadingPaymentInfo
  );



  const useFormValues = useForm<FormValues>({
    mode: "onSubmit",
  });

  const { reset, trigger, handleSubmit, clearErrors } = useFormValues;
  const formContentPaymentProps = useFormContentPayment();
  const {
    onChangeCNum,
    loading,
    setLoading,
    showGenericError,
    isAdding,
    setShowGenericError,
    selectedAccount,
    setSelectedAccount,
    setIsAdding,
    errorGetPayments,
    setErrorGetPayments,
    onSuccessGetPayments,
    onFailureGetPayments,
  } = formContentPaymentProps;

  const sitekey = configReCAPTCHA.public_site_key;
  const reRef = React.useRef<ReCAPTCHA>();

  const paymentInfo = useSelector(
    (state) => state.ecommerce.paymentInfo.find(
      (paymentInstruction) => paymentInstruction.identifier === selectedAccount)
  );

  const orderPlanSubscription = useSelector(state => state.ecommerce.wcsDetail);
  const boughtTimeEnded = useSelector(selectUserPlan).timeEnded;

  const orderSubscription = orderPlanSubscription;

  const getRecaptchaToken = async (): Promise<string> => {
    const token = await reRef.current.executeAsync();
    reRef.current.reset();

    return token;
  };

  const onSubmitPaySavedAndConfirmOrder = () => {
    console.log('data', data)
    setLoading(true);
    actions.setActiveRenewalPlan(data?.orderId, data?.subscriptionId, true, selectedAccount).then(() => {
      setLoading(false);
      reset({});
      dispatch(actions.getSubscriptionList());
      setShowActivated(true);
    }).catch((error: AxiosError) => {
      setLoading(false);
      dispatch(showError());
    });
  }

  const resetIsAdding = (paymentInstructions: WalletItem[]) => {
    const wallets = paymentInstructions?.filter(
      (paymentInstruction) =>
        !isCreditCardExpired(paymentInstruction.expireDate)
    );
  
    if(wallets?.length === 1 && !selectedAccount) {
      const firstIdentifier = wallets?.[0]?.identifier;
      firstIdentifier && setSelectedAccount(firstIdentifier);
    }
    
    
    if(wallets?.length > 0) {
      setIsAdding(false);
    }
  }

  const onSubmitAddPaymentMethod = async (data: FormValues) => {
    console.log(`data`, data);
    //re-calculate circuit
    const recognizedCircuit = onChangeCNum(data.cardnumber);

    const isCvcValid: boolean = await trigger("cvc")
      .then((isValid) => {
        return isValid;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });

    if (!isCvcValid) {
      console.error("cvc not valid");
      return;
    }

    const token = await getRecaptchaToken();
    const paymentInstruction = new WalletRequestBody();
    paymentInstruction.expire_month = data["cc-exp"].split("/")[0];
    paymentInstruction.expire_year = "20" + data["cc-exp"].split("/")[1];
    paymentInstruction.cc_account = data.cardnumber ? data.cardnumber.replace(/-/g, "") : null;
    paymentInstruction.cc_cvc = data.cvc;
    paymentInstruction.cc_nameoncard = data.ccname;
    paymentInstruction.cc_issuer = data.cardnumber ? recognizedCircuit : null;
    paymentInstruction.payMethodId = data.cardnumber ? CARD_VALIDATION?.[recognizedCircuit]?.payMethodId : null;
    const addressId = getDefaultContact(shippingInfo?.contact, true)
      ?.addressId;
    paymentInstruction.billingAddressId = addressId;
    paymentInstruction.shippingAddressId = paymentInstruction.billingAddressId;

    const isCCDefault = data.makeDefault;

    dispatch(actions.addPaymentDetail(paymentInstruction, isCCDefault, resetIsAdding));
    reset({});
    clearErrors();
  }

  const shippingInfo = useSelector((state) => state.ecommerce.shippingInfo);

  const resetData = () => {
    reset({});
    clearErrors();
    setIsAdding(false);
    close();
  };


  useEffect(() => {
    dispatch(actions.getPaymentDetails(onSuccessGetPayments, onFailureGetPayments));
    if (!shippingInfo) {
      dispatch(actions.getContact());
    }
    () => {
      resetData();
      setErrorGetPayments(null);
    };
  }, []);

  const paymentInstructions = useSelector((state) =>
    state.ecommerce.paymentInfo?.filter(
      (paymentInstruction) =>
        !isCreditCardExpired(paymentInstruction.expireDate)
    )
  );

  const goBack = () => {
    if (isAdding && paymentInstructions?.length > 0) {
      setIsAdding(false);
    } else {
      resetData();
      close();
    }
  };

  return (
    <>
      <ModalEvolution
        close={close}
        show={show && !showActivated}
        hideX
        modalContentClassName="modal__content--small"
        modalClassName="ecommerce-alert-modal modal-choose-payment"
        descriptionElement={
          <>
            <div
              className="modal-choose-payment__wrapper"
              role="region"
              aria-label={lang?.CHOOSE_PAYMENT_METHOD}
            >
              <header className="modal-choose-payment__header">
                <button
                  className="icon-button back-button"
                  onClick={goBack}
                  aria-label={lang?.GO_BACK}
                >
                  <ChevronLeft />
                </button>
              </header>
              <div className="ecommerce-alert-modal__header">
                <h3 className="ecommerce-alert-modal__title">
                  {lang?.CHOOSE_PAYMENT_METHOD}
                </h3>
              </div>
              <div className="ecommerce-alert-modal__body">
                <form
                  className=""
                  onSubmit={handleSubmit(
                    isAdding
                      ? onSubmitAddPaymentMethod
                      : onSubmitPaySavedAndConfirmOrder
                  )}
                >
                  <FormContentPaymentWrapper title={lang?.PAY_WITH_CREDIT_CARD}>
                    <div className="form-payment-details">
                      <FormContentPayment
                        {...useFormValues}
                        {...formContentPaymentProps}
                        hideCvc
                      />
                      {isAdding && !isLoadingPaymentInfo && (
                        <span className="flex">{lang?.CARD_DETAILS_WILL_BE_SAVED}</span>
                      )}
                      {errorGetPayments && (
                        <ErrorMessage
                          error={{
                            type: "generic",
                            message: errorGetPayments,
                          }}
                          className="checkout-page__generic-error flex"
                        />
                      )}
                      {showGenericError && (
                        <ErrorMessage
                          error={{
                            type: "generic",
                            message: lang.CREDIT_CARD_GENERIC_ERROR,
                          }}
                          className="checkout-page__generic-error flex"
                        />
                      )}
                    </div>
                  </FormContentPaymentWrapper>
                  <div className="ecommerce-alert-modal__footer">
                    <ButtonV2
                      variant="primary"
                      small
                      // className="modal-choose-payment__confirm-cta"
                      type="submit"
                      loading={
                        isAdding ? isLoadingPaymentInfo || loading : loading
                      }
                    >
                      {lang?.CONFIRM}
                    </ButtonV2>
                    <ButtonV2
                      variant="secondary"
                      small
                      // className="modal-choose-payment__cancel-cta"
                      handleClick={(e) => {
                        e.preventDefault();
                        if (isAdding && paymentInstructions?.length > 0) {
                          setIsAdding(false);
                        } else {
                          close();
                        }
                      }}
                    >
                      {lang?.CANCEL}
                    </ButtonV2>
                  </div>
                </form>
              </div>
            </div>
            <ReCAPTCHA
              sitekey={sitekey}
              ref={reRef}
              theme="dark"
              size="invisible"
            />
          </>
        }
      />
      <ModalRenewalActivated
        plan={plan}
        subscription={orderSubscription}
        paymentInfo={paymentInfo}
        boughtTimeEnded={boughtTimeEnded}
        close={() => {
          setShowActivated(false);
          goBack();
        }}
        show={showActivated}
      />
    </>
  );
};

export default ModalChoosePayment;
