import React, { useEffect, useState } from 'react';
import { AnimateSharedLayout, motion } from 'framer-motion';
import { Course, LangMap } from '@model/CoursesClass';
import ButtonV2 from '@components/UI/ButtonV2';
import { useDispatch } from 'react-redux';

type Props = {
  lang: LangMap;
  className?: string;
  currentTab: number;
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>;
  setIsTabGreater: React.Dispatch<React.SetStateAction<boolean>>;
  searchCourses: Course[];
  searchTrainingPillsNum: number;
  searchNoveltiesNum: number;
  showCourseQuantity?: boolean;
};

export const searchTabValues = {
  courses: 0,
  novelties: 1,
  trainingPills: 2,
};

const SearchTabs = ({
  lang,
  className,
  currentTab,
  setCurrentTab,
  setIsTabGreater,
  searchCourses,
  searchTrainingPillsNum,
  searchNoveltiesNum,
  showCourseQuantity,
}: Props) => {
  const [showTabs, setShowTabs] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    let counter = 0;

    if (searchCourses?.length > 0) {
      counter++;
    }
    if (searchTrainingPillsNum > 0) {
      counter++;
    }
    if (searchNoveltiesNum > 0) {
      counter++;
    }
    setIsTabGreater(null);
    setShowTabs(counter > 1);
  }, [searchCourses, searchTrainingPillsNum, searchNoveltiesNum]);

  const handleTabChange = (newTab: number) => {
    if (newTab !== currentTab) {
      setCurrentTab(prevTab => {
        setIsTabGreater(newTab > prevTab);
        return newTab;
      });

      const analyticsTabName =
        newTab === 0
          ? lang.COURSES_LESSONS
          : newTab === 1
          ? lang.SEARCH_TAB_LATEST_COLLECTIONS
          : newTab === 2
          ? lang.IN_YOUR_STORE
          : '';
    }
  };

  const hasThreeTabs =
    showTabs && searchCourses?.length > 0 && searchNoveltiesNum > 0 && searchTrainingPillsNum > 0;

  return (
    <section
      className={
        'search__results__tabs ' +
        (className ? className : '') +
        (hasThreeTabs ? ' search__results__tabs--3-tabs' : '')
      }
    >
      {showTabs && (
        <div className="search__tabs__header">
          <AnimateSharedLayout>
            <div className="search__results__tabs__header-links">
              <div className="search__results__tabs__header-link">
                {searchCourses?.length > 0 && (
                  <HeaderButtonExpandable
                    handleClick={() => handleTabChange(searchTabValues.courses)}
                    label={`${lang.COURSES_LESSONS?.toUpperCase()} ${
                      showCourseQuantity ? '(' + searchCourses.length + ')' : ''
                    }`}
                    isOpen={currentTab === searchTabValues.courses}
                    dataElementId="SearchPanel_CoursesAndLessons"
                  />
                )}
              </div>
              {searchNoveltiesNum > 0 && (
                <div className="search__results__tabs__header-link">
                  <HeaderButtonExpandable
                    handleClick={() => handleTabChange(searchTabValues.novelties)}
                    label={`${lang.SEARCH_TAB_LATEST_COLLECTIONS?.toUpperCase()} ${
                      showCourseQuantity ? '(' + searchNoveltiesNum + ')' : ''
                    }`}
                    isOpen={currentTab === searchTabValues.novelties}
                    dataElementId="SearchPanel_LatestCollections"
                  />
                </div>
              )}
              {searchTrainingPillsNum > 0 && (
                <div className="search__results__tabs__header-link">
                  <HeaderButtonExpandable
                    handleClick={() => handleTabChange(searchTabValues.trainingPills)}
                    label={`${lang.IN_YOUR_STORE?.toUpperCase()} ${
                      showCourseQuantity ? '(' + searchTrainingPillsNum + ')' : ''
                    }`}
                    isOpen={currentTab === searchTabValues.trainingPills}
                    dataElementId="SearchPanel_InYourStore"
                  />
                </div>
              )}
            </div>
          </AnimateSharedLayout>
        </div>
      )}
    </section>
  );
};

export default SearchTabs;

const HeaderButtonExpandable = ({ label, handleClick, isOpen, className = '', dataElementId }) => {
  return (
    <ButtonV2
      className={
        'header-v3__button-expandable ' + (isOpen ? 'open' : '') + (className ? className : '')
      }
      variant="text-btn-no-arrow"
      small
      handleClick={handleClick}
      ariaLabel={label}
      data-element-id={dataElementId}
    >
      <span>{label}</span>
      {isOpen && <motion.div layoutId="tabSelector" className="header-v3__slider" />}
    </ButtonV2>
  );
};
