import React, { ReactElement } from 'react';
import { useSelector } from "@hooks/redux";
import { LangMap } from '@model/CoursesClass';
import { handleOnEnterKeyPress } from '@utility/Api';
interface TabHandlerProps {
    tab: any;
    setTab: (tab: any) => void;
    tabsList: Array<{ labelKey: string, id: string, dataElementId?: string, dataDescription?: string }>;
    className?: string;
}

export const TabHandler = (props: TabHandlerProps): ReactElement => {
    const lang: LangMap = useSelector((state) => state.utils.lang);

    return (
        <section
            className={"tab-handler max-width-1440 " + props.className}
            aria-label={lang?.SELECT_OPTION}
        >
            {props.tabsList?.map(singleTab =>
                (singleTab.dataElementId && singleTab.dataDescription ) ? (
                    <a
                        key={singleTab.id}
                        role="button"
                        tabIndex={0}
                        onClick={() => props.setTab(singleTab.id)}
                        onKeyDown={(event) => {
                            handleOnEnterKeyPress(event, () => props.setTab(singleTab.id))
                        }}
                        className={`focus-outline ${props.tab === singleTab.id ? "tab-active" : ""}`}
                        data-element-id={singleTab?.dataElementId}
                        data-description={singleTab?.dataDescription}
                    >
                        {lang[singleTab.labelKey]}
                    </a>
                    ) : (
                    <span
                        key={singleTab.id}
                        role="button"
                        tabIndex={0}
                        onClick={() => props.setTab(singleTab.id)}
                        onKeyDown={(event) => {
                            handleOnEnterKeyPress(event, () => props.setTab(singleTab.id))
                        }}
                        className={`focus-outline ${props.tab === singleTab.id ? "tab-active" : ""}`}
                    >
                        {lang[singleTab.labelKey]}
                    </span>
                    )
            )}
        </section>
    );
}

export default TabHandler;